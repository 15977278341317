<!-- 需要定制头部的Cell -->
<template>
    <el-row
        type="flex"
        align="middle"
        justify="center"
        v-if="column.prop === 'nppStatus'"
    >
        <span style="margin-right: 4px">
            {{ column.label }}
        </span>
        <el-tooltip
            class="item"
            effect="dark"
            placement="top"
        >
            <template slot="content">
                <span>
                    如有查验会在此处显示，如需了解查验流程，请
                </span>
                <a
                    :href="boxCusmovDoc"
                    download
                    style="color: #409eff"
                >
                    点击下载
                </a>
            </template>
            <i
                class="el-icon-info"
                style="
                    font-size: 14px;
                    color: #e6a23c;
                    cursor: pointer;
                "
            ></i>
        </el-tooltip>
    </el-row>
    <el-row
        v-else-if="column.prop === 'lastestStatus'"
        type="flex"
        align="middle"
        justify="center"
    >
        <span style="margin-right: 4px">
            {{ column.label }}
        </span>
        <el-tooltip
            content="海外运踪目前支持船公司：MSK(马士基)、MSC(地中海)、COSCO(中远海运)、ZIM(以星航运)、ONE(海洋网联)、PIL(太平船务)、SITC(海丰)、YML(阳明)、KMTC(高丽海运)、CMA(达飞)"
            placement="top"
        >
            <i
                class="el-icon-info"
                style="
                    font-size: 14px;
                    color: #e6a23c;
                    cursor: pointer;
                "
            ></i>
        </el-tooltip>
    </el-row>
    <el-row
        v-else-if="column.prop === 'changeVessels'"
        type="flex"
        align="middle"
        justify="center"
    >
        <span style="margin-right: 4px">{{ column.label }}</span>
        <el-tooltip
            content="如果您订阅的提单发生过换船，请在此处标记，以便我们及时更新您的订阅信息"
            placement="top"
        >
            <i
                class="el-icon-info"
                style="
                    font-size: 14px;
                    color: #e6a23c;
                    cursor: pointer;
                "
            ></i>
        </el-tooltip>
    </el-row>
    <el-row
        v-else-if="column.prop === 'speedType'"
        type="flex"
        align="middle"
        justify="center"
    >
        <span style="margin-right: 4px">
            {{ column.label }}
        </span>
        <el-tooltip
            content="开启极速模式后，我们将优先为您进行查询(限额：免费20票/天，开启条件：码放状态为未放行)"
            placement="top"
        >
            <i
                class="el-icon-info"
                style="
                    font-size: 14px;
                    color: #e6a23c;
                    cursor: pointer;
                "
            ></i>
        </el-tooltip>
    </el-row>
    <el-row v-else-if="column.prop === 'nodeGroup'" type="flex" align="middle" justify="space-between">
        <template v-for="(node, index) in column.label.split('-')">
            <span class="node-group-header-span-item">{{ node }}</span>
            <span
            v-if="index !== column.label.split('-').length - 1" class="node-group-header-span-item">-</span>
        </template>
    </el-row>
</template>

<script>
export default {
    props: {
        column: {
            type: Object,
            default: () => {}
        }
    },
    data(){
        return {
            boxCusmovDoc:
                "https://web.nbhuojian.com/static/fangcangwang/%E6%9F%A5%E9%AA%8C%E7%A7%8D%E7%B1%BB%E8%AF%B4%E6%98%8E.doc",
        }
    }
}
</script>