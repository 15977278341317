<template>
    <div class="table-remark-cell-wrap">
        <el-link
            v-if="!rowData.remark"
            type="primary"
            @click="addRemark(rowData)"
            class="table-cell-link"
            style="margin-left: 4px;"
        >
            添加备注
        </el-link>
        <div
            v-else
            :class="{
                'logistics-remark-wrap': true,
                multiLine:
                    rowData.remark.length >
                    14,
            }"
        >
            <span>{{ rowData.remark }}</span>
            <i
                @click="addRemark(rowData)"
                class="el-icon-edit"
            ></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RemarkCell',
    emits:['edit'],
    props: {
        rowData: {
            type: Object
        },
    },
    methods: {
        addRemark(data) {
            this.$emit('edit',data)
        }
    }
}
</script>

<style lang="stylus" scoped>
.table-remark-cell-wrap{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .logistics-remark-wrap{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span{
            display: inline-block;
            flex-grow: 1;
            white-space: wrap;
            padding-left: 4px;
        }
        i{
            font-size: 16px;
            color: #409EFF;
            cursor: pointer;
        }
        &.multiLine{
            i{
                font-size: 14px;
            }
            line-height: 18px;
            font-size: 12px;
        }
    }
}
</style>