<template>
    <div class="change-vessels-cell" v-if="!rowData.parentBlno">
        <el-link
            v-if="
                rowData.changeVessels === 'true'
            "
            type="danger"
            @click="handleMarkShip(rowData)"
            class="mark-shipChange-link table-cell-link"
        >
            取消标记
        </el-link>
        <el-link
            v-else
            type="primary"
            class="mark-shipChange-link table-cell-link"
            @click="handleMarkShip(rowData)"
        >
            标记
        </el-link>
    </div>
</template>
<script>
export default {
    name: "ChangeVesselsCell",
    props: {
        rowData: {
            type: Object,
            default: () => {}
        }
    },
    methods:{
        handleMarkShip(data){
            this.$emit('markChangeShip',data)
        }
    }
}
</script>