<template>
<div class="blno-col-wrap">
    <div class="blno-left">
        <el-tooltip content="点击复制提单号" placement="top">
            <span 
                class="blno-text" 
                v-clipboard:copy="
                    rowData.blno
                "
                v-clipboard:success="
                    copySucc
                "
            >
                {{ rowData.blno }}
            </span>
        </el-tooltip>
        <img
            v-if="
                (rowData.psType &&
                    rowData.psType !=
                        '1') ||
                (rowData.sonList &&
                    rowData.sonList
                        .length)
            "
            src="@/assets/img/pin.png"
            class="pin-img"
            alt=""
        />
    </div>
    <div class="blno-right">
        <span
            v-if="
                rowData.sonList &&
                rowData.sonList
                    .length &&
                showList.findIndex(
                    (item) =>
                        item ==
                        rowData.blno,
                ) == -1
            "
            class="addshow_class"
            @click="toggleShowList(rowData.blno,'expand')"
        >
            +
        </span>
        <span
            v-if="
                rowData.sonList &&
                rowData.sonList
                    .length &&
                showList.findIndex(
                    (item) =>
                        item ==
                        rowData.blno,
                ) != -1
            "
            class="reduceshow_class"
            @click="toggleShowList(rowData.blno,'close')"
        >
            -
        </span>
        <span
            v-if="
                rowData.sonCount !=
                    '0' &&
                rowData.sonCount !=
                    '1' &&
                showList.findIndex(
                    (item) =>
                        item ==
                        rowData.blno,
                ) == -1
            "
            class="together_num_class"
        >
            {{ rowData.sonCount }}
        </span>
        <span
            v-if="
                (rowData.psType &&
                    rowData.psType !=
                        '1') ||
                (rowData.sonList &&
                    rowData.sonList
                        .length &&
                    showList.findIndex(
                        (item) =>
                            item ==
                            rowData
                                .blno,
                    ) != -1)
            "
            class="together_num_class"
        >
            {{ mixCount(rowData) }}
        </span>
        <img
            src="@/assets/icon/copy.svg"
            v-clipboard:copy="
                rowData.blno
            "
            v-clipboard:success="
                copySucc
            "
        />
        <!-- <el-tooltip content="异常反馈">
            <MyIcon
                icon="ic:outline-feedback"
                :size="16"
                :clickable="true"
                theme="danger"
                :customId="
                    rowIndex === 0
                        ? 'feedback-icon'
                        : ''
                "
                @click="handleFeedback(rowData)"
            ></MyIcon>
        </el-tooltip> -->
    </div>
</div>
</template>

<script>
import MyIcon from '@/components/common/MyIcon.vue'
export default {
    name: 'BlnoCell',
    components: {
        MyIcon
    },
    props: {
        rowData: {
            type: Object,
            default: () => {}
        },
        rowIndex: {
            type: Number,
            required: true
        },
        showList: {
            type: Array,
            required: true,
            default: () => []
        },
        boxList: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    methods: {
        // 处理拼票当前票数/总票数
        mixCount(row) {
            // 第一票A票
            if (row.sonCount) {
                return `1/${row.sonCount}`;
            }
            // 其他票
            // 主票的index
            const mainIndex = this.boxList.findIndex(
                (item) => item.blno === row.psType,
            );
            // 当前票的index
            const curIndex = this.boxList.findIndex(
                (item) => item.blno === row.blno,
            );
            if (~mainIndex && ~curIndex) {
                // 根据psType得到主票A票的sonCount
                const { sonCount = 0 } = this.boxList[mainIndex];
                // 当前票的index减去主票的index+1
                return `${curIndex - mainIndex + 1}/${sonCount}`;
            }
            return "0/0";
        },
        copySucc() {
            this.$message.success("复制成功");
        },
        toggleShowList(blno,type){
            this.$emit('toggle-show-list',blno,type)
        },
        handleFeedback(rowData){
            this.$emit('feedback',rowData)
        }
    },
}
</script>

<style lang="stylus" scoped>
.blno-col-wrap{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /deep/ .el-tooltip{
        padding: 0 !important;
    }
    .blno-left{
        img{
            margin-left:4px;
            cursor: pointer;
            &:hover{
                opacity: 0.6;
            }
        }
    }
    .blno-right{
        span{
            white-space: nowrap;
            display: inline-block;
            width: fit-content;
        }
    }
    .blno-left,.blno-right{
        display: flex;
        justify-content: center;
        align-items:center;
    }
    .pin-img{
        width: 12px;
        height: 12px;
        margin-left: 4px;
    }
    .blno-text{
        flex-shrink: 0;
        white-space: nowrap;
        margin-left: 4px;
    }
    .addshow_class {
        font-size: 18px;
    }

    .reduceshow_class {
        font-size: 30px;
    }

    .together_num_class {
        font-size: 12px;
        line-height: 14px;
        color: #b87100;
        margin-right: 4px;
    }
    .addshow_class,.reduceshow_class{
        margin-left: 4px;
        margin-right: 4px;
        color: #409EFF;
        cursor: pointer;
    }
}
</style>