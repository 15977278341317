<template>
    <el-steps class="auto-break-step">
        <el-step v-for="(item, index) in showStepItems" :key="index" :class="{'is-finish':getStepIsFinish(index)}">
            <template #icon>
                <i :class="getIconClass(index)"></i>
            </template>
        </el-step>
    </el-steps>
</template>
<script>
export default {
    props: {
        rowData: {
            type: Object,
            default: () => {}
        },
        showTab:{
            type: String,
            default: 'NB'
        }
    },
    data() {
        return {
            stepItems: {
                NB: [
                    {
                        title: '预配',
                        key: 'preFlag'
                    },
                    {
                        title: '清洁',
                        key: 'ifcsumFlag'
                    },
                    {
                        title: '运抵',
                        key: 'cpcodeFlag'
                    },
                    {
                        title: '海放',
                        key: 'customFlag'
                    },
                    {
                        title: '三联单',
                        key: 'sldFlag'
                    }
                ],
                SH:[
                    {
                        title: '预配',
                        key: 'preFlag'
                    },
                    {
                        title: '运抵',
                        key: 'cpcodeFlag'
                    },
                    {
                        title: '海放',
                        key: 'customFlag'
                    }
                ],
                QD:[
                   {
                        title: '预配',
                        key: 'preFlag'
                   },
                   {
                        title: '海放',
                        key: 'customFlag'
                    }
                ],
                TJ:[
                   {
                        title: '预配',
                        key: 'preFlag'
                   },
                   {
                        title: '海放',
                        key: 'customFlag'
                    }
                ],
                XM:[
                   {
                        title: '预配',
                        key: 'preFlag'
                   },
                   {
                        title: '海放',
                        key: 'customFlag'
                    }
                ],
                SZ:[
                    {
                        title: '订舱',
                        key: 'bkDatetimeFlag'
                    },
                    {
                        title: '打单',
                        key: 'firstPrintDatetimeFlag'
                    },
                    {
                        title: '提空',
                        key: 'emptyOutFlag'
                    },
                    {
                        title: '还重',
                        key: 'giNameFlag'
                    },
                    {
                        title: 'VGM',
                        key: 'vgmDatetimeFlag'
                    },
                    {
                        title: '舱单申报',
                        key: 'manifestFlag'
                    },
                    {
                        title: '海放',
                        key: 'customPassFlag'
                    },
                    {
                        title: '装船',
                        key: 'lsDatetimeFlag'
                    },
                    {
                        title: '离岸信息',
                        key: 'hasDepartureInfoFlag'
                    }
                ]
            }
        }
    },
    computed: {
        showStepItems(){
            return this.stepItems[this.showTab]
        }
    },
    methods:{
        getStepIsFinish(index){
            const item = this.showStepItems[index]
            return this.rowData[item.key] === 'Y' && (this.rowData[this.showStepItems[index+1]?.key] === 'Y' || !this.showStepItems[index+1])
        },
        getIconClass(index){
            const item = this.showStepItems[index];
            const rightArr = this.showStepItems.slice(index + 1);
            const rightHasGreen = rightArr.some(item => {
                return this.rowData[item.key] === 'Y'
            })
            const result = {
                'icon-green': this.rowData[item.key] === 'Y' || rightHasGreen,
                'icon-red': this.rowData[item.key] === 'N' && !rightHasGreen,
                'el-icon-circle-check': this.rowData[item.key] === 'Y' || rightHasGreen,
                'el-icon-circle-close': this.rowData[item.key] === 'N' && !rightHasGreen,
                'el-icon-remove-outline': !this.rowData[item.key] && !rightHasGreen,
                'custom-icon': true
            }
            return result;
        }
    }
}
</script>
<style lang="stylus" scoped>
.auto-break-step{
    text-align: left;
    padding: 0 4px;
  width: 100%;
  /deep/ .el-step__head{
    line-height: 22px;
  }
  /deep/ .el-step__icon{
    width: fit-content;
    height: fit-content;
    font-size: 12px;
    border: 0;
  }
  .is-finish{
    /deep/ .el-step__line{
        top: 8px;
        background-color: rgba(103, 194, 58, 0.6);
    }
  }
  /deep/ .el-step__line{
    top: 8px;
  }

  /deep/ .el-step__title{
    font-size: 12px;
    font-weight: normal;
    line-height: 22px;
    &.is-finish, &.is-process{
      color: #67C23A;
    }
    &.is-wait{
      color: #909399;
    }
  }
}
</style>
