<template>
    <el-dialog
        :title="showTitle"
        :visible.sync="dialogVisible"
        width="50%"
        custom-class="add-car-dialog"
        @close="handleClose"
    >
        <el-alert type="warning" show-icon title="订阅功能为收费项目，收费标准为1火币/车牌（添加成功即收费，删除后不退费）"></el-alert>
        <div class="header">
            <el-input v-model="form.carNo" size="small" placeholder="请输入车号" style="width: 200px;margin-right: 10px;"></el-input>
            <el-button type="primary" size="small" :loading="addLoading" @click="handleAdd">添加</el-button>
        </div>
        <el-table :data="tableData">
            <el-table-column v-for="column in tableColumns" :key="column.prop" :label="column.label" :width="column.width || ''">
                <template slot-scope="scope">
                    <span v-if="column.prop === 'serialNumber'">{{ scope.$index + 1 }}</span>
                    <el-popconfirm v-if="column.prop === 'action'" title="确定删除吗？" @confirm="handleDelete(scope.row)">
                        <el-button slot="reference" type="danger" :loading="deleteLoading && curEditCarNo === scope.row.carNo" size="small">删除</el-button>
                    </el-popconfirm>
                    <span v-else>{{ scope.row[column.prop] }}</span>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
</template>

<script>
import { getToken } from "@/utils/auth";
import {
    updateRemarkAndTruck,
} from "@/api/boxQuery";
import { getCarTrajectory } from '@/api/truckSubscribe';
import { apiCallback } from "@/utils";
import { deleteCarSubscribe, getCarSubscribeList } from "@/api/truckSubscribe";

export default {
    data() {
        return {
            dialogVisible: false,
            form: {
                carNo: '',
            },
            tableData: [],
            tableColumns: [
                {
                    prop: 'serialNumber',
                    label: '序号',
                    width: 80,
                },
                {
                    label: '车号',
                    prop: 'carNo',
                },
                {
                    label: '操作',
                    prop: 'action',
                    width: 100,
                },
            ],
            addLoading: false,
            deleteLoading: false,
            curEditCarNo: '',
            curEditRow: {},
            hasChange: false,
        }
    },
    computed: {
        showTitle() {
            return `添加车号（提单号：${this.curEditRow.blno}）`
        }
    },
    methods: {
        handleClose() {
            this.dialogVisible = false
            this.tableData = []
            this.curEditRow = {}
            this.form.carNo = ''
            this.hasChange = false
            if(this.hasChange) {
                this.$emit('refresh')
            }
        },
        open(row) {
            this.dialogVisible = true
            this.curEditRow = row;
            if (row.truckLicense) {
                const carNoList = row.truckLicense.split(',').map((item, index) => ({
                    carNo: item,
                }))
                this.tableData = carNoList
            }
        },
        // 订阅集卡车
        subscribeTruck(row) { 
            return new Promise((resolve) => { 
                const reqParam = {
                    blno: row.blno,
                    truckLicense: this.form.carNo
                }
                if (this.$store.state.user.cid) { 
                    reqParam.companyId = this.$store.state.user.cid
                }
                getCarTrajectory(reqParam).then(res => { 
                    resolve(res)
                }).catch(() => { 
                    this.addLoading = false
                })
            })
        },
        handleAdd() {
            const row = this.curEditRow;
            const that = this;
            if (getToken()) {
                const regExp =
                    /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
                if (!regExp.test(this.form.carNo) || !this.form.carNo) {
                    this.$message.error('请输入正确的车号')
                    return
                }
                if (this.tableData.find(item => item.carNo === this.form.carNo)) {
                    this.$message.error('车号已存在')
                    return
                }
                this.addLoading = true;
                const params = {
                    id: row.id,
                    truckLicense: row.truckLicense ? `${row.truckLicense},${this.form.carNo}` : this.form.carNo,
                };
                this.subscribeTruck(row).then(res => {
                    updateRemarkAndTruck(params)
                        .then(function () {
                            that.tableData.push({
                                carNo: that.form.carNo,
                            })
                            that.form.carNo = "";
                            that.$message.success("添加成功");
                            that.hasChange = true;
                            that.$emit('change')
                        }).finally(() => {
                            that.addLoading = false;
                        })
                })
            } else { 
                this.$emit('login')
            }
        },
        // 获取当前提单集卡车订阅列表（为了获取订阅id，删除车牌的时候同步删除车牌订阅）
        deleteCarSubscribe() {
            const row = this.curEditRow;
            return new Promise((resolve, reject) => {
                getCarSubscribeList({
                    blno: row.blno,
                }).then(res => {
                    this.hasChange = true;
                    const data = apiCallback(res)
                    // 筛选当前删除的车牌订阅id
                    const needDelId = data.filter(item => item.truckLicense.includes(this.curEditCarNo)).map(item => item.id)[0] || ''
                    resolve(needDelId)
                }).catch(() => {
                    reject()
                })
            })
        },
        handleDelete({ carNo}) {
            const row = this.curEditRow;
            this.curEditCarNo = carNo;
            this.deleteLoading = true;
            var that = this;
            if (getToken()) {
                const truckLicense = this.tableData.filter(item => item.carNo !== carNo).map(item => item.carNo).join(',')
                const params = {
                    id: row.id,
                    truckLicense,
                }
                updateRemarkAndTruck(params)
                    .then(function () {
                        that.tableData = truckLicense ? truckLicense.split(',').map((item, index) => ({
                            carNo: item,
                        })) : []
                        that.deleteCarSubscribe().then(res => {
                            if (res) {
                                deleteCarSubscribe({id: res})
                            }
                        })
                        that.curEditCarNo = '';
                        that.$message.success("删除成功");
                        that.$emit('change');
                    }).finally(() => {
                        that.deleteLoading = false;
                    })
            } else { 
                this.$emit('login')
            }
        }
    }
}
</script>

<style lang="stylus">
.add-car-dialog {
    .el-dialog__body {
        padding-top: 0;
    }
    .header {
        display: flex;
        margin: 16px 0;
    }
}
</style>