<template>
    <div class="client-company-cell" :id="rowIndex === 0? 'clientCompanyGuideCell' : ''">
        <template v-if="rowData.clientCompanyName">
            <div
                style="
                    max-width: 120px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                "
            >
                {{ rowData.clientCompanyName }}
            </div>
            <el-tooltip content="修改客户公司">
                <MyIcon
                    icon="bx:edit"
                    :size="16"
                    :clickable="true"
                    @click="
                        changeClientCompany(
                            rowData,
                        )
                    "
                    theme="primary"
                ></MyIcon>
            </el-tooltip>
        </template>
        <el-link
            v-else
            type="primary"
            @click="changeClientCompany(rowData)"
            class="table-cell-link"
        >
            添加客户公司
        </el-link>
    </div>
</template>

<script>
import MyIcon from '@/components/common/MyIcon.vue';
export default {
    name: 'ClientCompanyCell',
    components: {
        MyIcon
    },
    props: {
        rowData: {
            type: Object,
            default: () => {}
        },
        rowIndex: { 
            type: Number,
            default: 0
        }
    },
    methods: {
        changeClientCompany(rowData) {
            this.$emit('change-client-company', rowData);
        }
    }
}
</script>

<style lang="stylus" scoped>
.client-company-cell {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 4px;
}
</style>
