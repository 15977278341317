<!-- 国外节点组件 -->
<template>
    <div class="abroad-move-container">
        <div class="abroad-move" v-loading="loading">
            <div class="bill flex-align-center">
                <div class="flex-align-center bill-line">
                    <div class="flex-align-center" style="gap: 5px">
                        <i
                            class="el-icon-tickets icon-primary"
                            style="margin-left: 5px"
                        ></i>
                        {{ curData.blno }}
                    </div>
                </div>
            </div>
            <div class="flex-align-center detail-header-line">
                <div style="gap: 20px" class="flex-align-center">
                    <img
                        v-if="curData.shipCode"
                        :src="getShipComanyLogo(curData.shipCode)"
                        style="width: 50px"
                    />
                    <div style="min-width: 420px;flex:1" class="flex-align-center">
                        <div>
                            {{ curData.shippedFrom }}
                        </div>
                        <div
                            v-if="curData.shippedFrom || curData.shippedTo"
                            style="
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            "
                        >
                            <div>
                                {{ curData.vesselsName }}
                            </div>
                            <div
                                class="arrow-container"
                                style="width: 100%; padding: 5px 10px"
                            >
                                <span class="circle"></span>
                                <!-- <span class="line"> -->
                                <span class="line">
                                    <img
                                        src="@/assets/img/ship/icon-ship.svg"
                                        class="icon"
                                        alt=""
                                    />
                                </span>

                                <span class="triangle"></span>
                            </div>
                            <div>
                                {{ curData.voyage }}
                            </div>
                        </div>
                        <div>
                            {{ curData.shippedTo }}
                        </div>
                    </div>
                    <div
                        class="flex-align-center"
                        style="gap: 5px; min-width: 250px; margin-left: 20px"
                    >
                        <i class="el-icon-location-outline icon-font"></i>
                        <div
                            style="
                                display: flex;
                                flex-direction: column;
                                gap: 5px;
                            "
                        >
                            <div class="desp">最新节点</div>
                            <div class="content">
                                {{
                                    latestMove.nodeLocationCn ||
                                    (
                                        latestMove.nodeLocation || ""
                                    ).toUpperCase() || "-"
                                }}
                                <span
                                    v-if="
                                        latestMove.nodeActionCn ||
                                        latestMove.nodeAction
                                    "
                                >
                                    ({{
                                        latestMove.nodeActionCn ||
                                        latestMove.nodeAction
                                    }})
                                </span>
                            </div>
                            <div class="desp">
                                {{
                                    latestMove.nodeDate
                                        ? formatTime(latestMove.nodeDate)
                                        : ""
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="flex-align-center" style="gap: 5px">
                        <i
                            class="el-icon-box"
                            style="
                                width: 50px;
                                height: 50px;
                                font-size: 50px;
                                color: #4b4b4b;
                            "
                        ></i>
                        <div style="display: flex; flex-direction: column">
                            <div class="desp">
                                集装箱信息
                                <img
                                    style="width:12px"
                                    v-if="
                                        curData.returnEmptyCtns &&
                                        curData.returnEmptyCtns == curData.ctns
                                    "
                                    :src="icon_green"
                                ></img>
                            </div>
                            <div class="desp content">
                                已还空:{{ curData.returnEmptyCtns }}
                            </div>
                            <div class="desp content">
                                共计:{{ curData.ctns }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 20px">
            <el-tabs
                type="border-card"
                v-if="curData.ctnsList && curData.ctnsList.length > 0"
                v-model="activeName"
            >
                <el-tab-pane
                    v-for="item in curData.ctnsList"
                    :key="item.id"
                    :label="`${item.ctnNo}`"
                    :name="item.ctnNo"
                >
                    <div class="timeline" v-loading="loading">
                        <ul class="el-timeline" v-if="item.nodes && item.nodes.length > 0">
                            <li
                                class="el-timeline-item"
                                :id="citem.nodeId"
                                v-for="(citem, index) in [...item.nodes]"
                                :key="citem.nodeId"
                            >
                                <div class="port-name-line">
                                    <span
                                        v-if="needShowPlacePort[index]"
                                        class="port-name"
                                        :class="
                                            citem.nodeStatus === 1
                                                ? ''
                                                : 'color-gray'
                                        "
                                    >
                                        {{
                                            showLocation(citem)
                                        }}
                                    </span>
                                </div>
                                <div
                                    class="el-timeline-item__tail"
                                    :class="
                                        citem.nodeStatus == 0
                                            ? 'border-dashed'
                                            : 'border-solid'
                                    "
                                ></div>
                                <div
                                    v-if="needShowPlacePort[index]"
                                    class="location-line"
                                >
                                    <i
                                        class="el-icon-location-outline location-icon"
                                        :class="
                                            citem.nodeStatus == 1
                                                ? 'color-primary'
                                                : ''
                                        "
                                    ></i>
                                </div>
                                <div
                                    v-else="!needShowPlacePort[index]"
                                    :class="
                                        citem.nodeStatus == 1
                                            ? 'el-timeline-item__node--primary'
                                            : ''
                                    "
                                    class="el-timeline-item__node el-timeline-item__node--normal"
                                ></div>
                                <div class="el-timeline-item__wrapper">
                                    <div
                                        class="el-timeline-item__content"
                                        style="
                                            font-weight: bold;
                                            font-size: 13px;
                                        "
                                        :class="
                                            citem.nodeStatus === 1
                                                ? ''
                                                : 'color-gray'
                                        "
                                    >
                                        {{
                                            showAction(citem)
                                        }}
                                    </div>
                                    <div
                                        class="el-timeline-item__timestamp is-bottom"
                                        :class="
                                            citem.nodeStatus === 1
                                                ? 'color-normal'
                                                : 'color-gray'
                                        "
                                    >
                                        {{ formatTime(citem.nodeDate) }}
                                    </div>
                                    <div
                                        class="el-timeline-item__timestamp is-bottom"
                                        :class="
                                            citem.nodeStatus === 1
                                                ? ''
                                                : 'color-gray'
                                        "
                                    >
                                        {{ showNodeDesc(citem) }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <el-empty v-else description="暂无节点数据"></el-empty>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import { getBillNoInfoAbroad } from "@/api/abroad";
import { apiCallback, getShipComanyLogo } from "@/utils";
import Monent from "moment";
import { nanoid } from "nanoid";
export default {
    data() {
        return {
            curData: {},
            getShipComanyLogo,
            loading: false,
            activeName: "",
            icon_green: require("@/assets/img/green.png"),
            timer: null,
        };
    },
    computed: {
        needShowPlacePort() {
            const cacheData = this.curData;
            if (cacheData && cacheData.ctnsList && cacheData.ctnsList.length > 0) {
                // const portShow = new Array(props.data.length).fill(false);
                const curCtn = cacheData.ctnsList.find(
                    (item) => item.ctnNo == this.activeName,
                );
                const portShow = new Array(curCtn.nodes.length).fill(false);
                const reverseData = [...curCtn.nodes];
                let curPort = "";
                reverseData.forEach((item, index) => {
                    const port = (
                        item.nodeLocationCn || item.nodeLocation
                    ).toUpperCase();
                    if (index === 0) {
                        portShow[index] = true;
                        curPort = port;
                    } else {
                        if (port !== curPort) {
                            portShow[index] = true;
                            curPort = port;
                        }
                    }
                });
                return portShow;
            } else {
                return [];
            }
        },
        // 最新节点信息
        latestMove() {
            // 找到最新的status为1的节点
            const cacheData = this.curData;
            if (cacheData && cacheData.ctnsList && cacheData.ctnsList.length > 0) {
                const curCtn = cacheData.ctnsList.find(
                    (item) => item.ctnNo == this.activeName,
                );
                if (curCtn && curCtn.nodes && curCtn.nodes.length > 0) {
                    const latestNode = curCtn.nodes.find(
                        (item) => item.nodeStatus === 1,
                    );
                    return latestNode || {};
                } else {
                    return {};
                }
            } else {
                return {};
            }
        },
    },
    watch: {
        latestMove: {
            handler(newVal){
                this.scrollToNode();
            },
        }
    },
    methods: {
        showLocation(citem){
            return citem.nodeLocationCn||citem.nodeLocation.toUpperCase()
        },
        showAction(citem){
            return citem.nodeActionCn?citem.nodeAction+`  (`+citem.nodeActionCn+`)`:
                                            citem.nodeAction
        },
        // 显示节点描述
        showNodeDesc(citem) {
            const { nodeDesc, nodeHandlingCn, nodeHandling } = citem;
            // 判断描述中是否包含nodeHandling
            const descIncludeHandling = nodeDesc.includes(nodeHandling) && nodeHandling &&nodeDesc;
            if (descIncludeHandling) {
                // 描述中包含nodeHandling，不再重复展示
                return nodeHandlingCn?`${nodeDesc.replace(nodeHandling, "")}  (${nodeHandling}【${nodeHandlingCn}】)`: nodeDesc;
            }
            // 描述中不包含nodeHandling，需要展示nodeHandling
            if (nodeHandling && nodeHandlingCn) {
                // 中英文都有
                return nodeDesc ? `${nodeDesc}  (${nodeHandling}【${nodeHandlingCn}】)` : `- (${nodeHandling}【${nodeHandlingCn}】)`;
            }else if(nodeHandling && !nodeHandlingCn){
                // 只有英文
                return nodeDesc ? `${nodeDesc}  (${nodeHandling})` : `- (${nodeHandling})`;
            }else if(!nodeHandling && nodeHandlingCn){
                // 只有中文
                return nodeDesc ? `${nodeDesc}  (${nodeHandlingCn})` : `- (${nodeHandlingCn})`;
            }else{
                return nodeDesc ? nodeDesc : "-";
            }
        },
        // 格式化时间
        formatTime(time) {
            return Monent(time).format("YYYY-MM-DD HH:mm:ss");
        },
        // 以#号分隔，取第一个#前面的内容
        formatNodeVal(nodeDesc){
            return nodeDesc ? nodeDesc.split("#")[0] : ""
        },
        //跟踪国外节点
        async trackAbroadMove(param) {
            const { vesselsName, voyage, billNo } = param;
            this.loading = true;
            getBillNoInfoAbroad({
                billNo,
            })
                .then((res) => {
                    const data = apiCallback(res) || {};
                    const ctnsList = data.ctnsList ? data.ctnsList.map(item=>{
                        const { nodes = [], ...restItem } = item;
                        return {
                            ...restItem,
                            nodes: nodes.map(node=>{
                                const { nodeDesc, nodeLocation,nodeHandling, ...restNode } = node;
                                return {
                                    ...restNode,
                                    nodeDesc: this.formatNodeVal(nodeDesc),
                                    nodeLocation: this.formatNodeVal(nodeLocation),
                                    nodeHandling: this.formatNodeVal(nodeHandling),
                                    nodeId: nanoid(),
                                }
                            })

                        }
                    }) : [];
                    this.curData = {
                        ...data,
                        vesselsName,
                        voyage,
                        ctnsList,
                    };
                    if (data.ctnsList && data.ctnsList.length > 0) {
                        this.activeName = data.ctnsList[0].ctnNo;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 重置
        reset() {
            this.curData = {};
            this.activeName = "";
        },
        scrollToNode(){
            const { nodeId } = this.latestMove;
            let el = null;
            if(nodeId){
                el = document.getElementById(nodeId);
            }
            this.timer = setTimeout(() => {
                if(el){
                    el.scrollIntoView({behavior: 'smooth', block: 'center'});
                    clearTimeout(this.timer);
                }else{
                    this.scrollToNode();
                }
            },100)
        },
    },
};
</script>
<style lang="stylus">
.abroad-move-container{
    margin-top: 20px !important;
    .el-tabs__content{
        max-height: 50vh;
        overflow: auto;
    }
}
</style>
<style scoped lang="stylus">
.flex-align-center {
    display: flex;
    align-items: center;
}

.desp{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
}

.content{
    font-size:14px;
    color:#000;
    max-width:200px;
}

.abroad-move {
    box-shadow: 1px 1px 5px #d7d4d4;
    border-radius: 10px;
    padding: 5px 20px 18px 5px;
    background: linear-gradient(#dde4eb 60%, #f2f3f5, #f2f3f5);
    font-size:14px;
    color:#000;
    .bill{
        color:#000;
        font-size: 14px;
        font-weight:500;
        .bill-line {
            margin-bottom: 5px;
            width: 100%;
            justify-content: space-between;
            .icon-primary{
                color:#0052d9;
                font-size:14px;
            }
        }
    }
    .detail-header-line{
        justify-content:space-between;
        min-height: 62px;
        margin-left: 10px;
        .icon-font{
            width: 50px;
            height: 50px;
            font-size: 50px;
            color: #4b4b4b;
        }
        .arrow-container {
            display: flex;
            align-items: center;
            .circle {
                width: 6px;
                height: 6px;
                border: 1px solid #d3d3d3;
                border-radius: 50%;
              }

              .line {
                min-width: 40px;
                flex: 1;
                display: flex;
                border-top: 0;
                box-sizing: border-box;
                &:before,
                &:after {
                  content: '';
                  width: 50%;
                  border-top: 2px solid #0052d9;
                  transform: translateY(8px);
                }
              }

            .icon {
                width: 18px;
                height: 18px;
            }

            .triangle {
                width: 0;
                height: 0;
                border-left: 8px solid #0052d9;
                border-right: 8px solid transparent;
                border-bottom: 4px solid transparent;
                border-top: 4px solid transparent;
            }
        }
    }
}

.timeline{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .port-name-line{
        width: 260px;
        text-align: right;
        padding: 0 10px;
        position: absolute;
        left: -280px;
        top: -5px;
    }
    .location-line{
        position: absolute;
        left:-5px;
        background:#fff;
        top:-5px;
    }
    .location-icon{
        font-size: 20px;
        color:#d3d3d3;
    }
    .color-primary{
        color:#0052d9;
    }
    .port-name{
        max-width: 100px; 
        font-size:14px;
        color:#000;
        font-weight:bold;
        display: inline-block;
        word-break: keep-all;
    }
    .border-dashed{
        border-left:2px dashed #d3d3d3;
    }
    .border-solid{
        border-left:2px solid #0052d9;
    }
    .color-gray{
        color:#d3d3d3;
    }
    .color-normal{
        color:#000;
    }
}
</style>
