<!-- 详情弹窗船期信息 -->
<template>
    <div v-if="boatInfo" id="logistics-detail-boatInfo">
        <p class="info_title" @click="handleTitleClick">
            船期信息【{{
                boatInfo.vesselEname
                    ? boatInfo.vesselEname
                    : boxInfo.vesselsName
            }}&ensp;/&ensp;{{
                boatInfo.voyage ? boatInfo.voyage : boxInfo.voyage
            }}
            】
            <el-link type="primary" @click="changeVoyage()" v-if="canUpdateShip&&showTab!=='SZ'">
                修改船名航次
            </el-link>
        </p>

        <div class="billNo_info_table_div">
            <el-table
                border
                :data="showShipInfo"
                style="width: 100%"
                :header-cell-style="{
                    background: '#eef1f6',
                    color: '#606266',
                }"
            >
                <el-table-column>
                    <template slot="header">
                        <div class="flex_div">
                            <div class="table-header-item">
                                <span class="label">船舶：</span>
                                <span class="content">{{
                                    boatInfo.vesselEname
                                        ? boatInfo.vesselEname
                                        : boxInfo.vesselsName
                                }}</span>
                            </div>
                            <el-link type="primary" @click="goShipMap(boatInfo)">船舶定位</el-link>
                        </div>
                    </template>

                    <el-table-column
                        label="预计抵港时间"
                        v-if="!['QD', 'TJ','SZ'].includes(showTab)"
                        align="center"
                    >
                        <template slot-scope="scope">
                            {{
                                scope.row.etaArrivalTime,
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="!['QD', 'TJ','SZ'].includes(showTab)"
                        prop="etaDepartureTime"
                        label="预计离港时间"
                        align="center"
                    >
                        <template slot-scope="scope">
                            {{
                                scope.row.etaDepartureTime,

                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="ctnApplyBeginTime"
                        label="收箱开始时间"
                        v-if="showTab === 'QD'"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="ctnApplyEndTime"
                        label="收箱截止时间"
                        v-if="showTab === 'QD'"
                        align="center"
                    ></el-table-column>
                    <el-table-column prop="bkDatetime" label="订舱时间" v-if="['SZ'].includes(showTab)" align="center"></el-table-column>
                    <el-table-column prop="firstPrintDatetime" label="打单时间" v-if="['SZ'].includes(showTab)" align="center"></el-table-column>
                    <el-table-column prop="emptyOutTime" label="提空时间" v-if="['SZ'].includes(showTab)" align="center"></el-table-column>
                </el-table-column>
                <el-table-column>
                    <template slot="header">
                        <div class="table-header-item">
                            <span class="label">出口航次：</span>
                            <span class="content">
                                {{
                                    boatInfo.voyage
                                        ? boatInfo.voyage
                                        : boxInfo.voyage
                                }}
                            </span>
                        </div>
                    </template>
                    <el-table-column
                        prop="ctnApplyBeginTime"
                        label="进箱开始时间"
                        v-if="!['TJ', 'QD','SZ'].includes(showTab)"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="ctnApplyEndTime"
                        label="进箱截止时间"
                        v-if="!['TJ', 'QD','SZ'].includes(showTab)"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="jgApplyBeginTime"
                        label="集港开始(开港)时间"
                        v-if="['QD', 'TJ'].includes(showTab)"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="jgApplyEndTime"
                        label="集港结束(截港)时间"
                        v-if="['QD', 'TJ'].includes(showTab)"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="portCloseTime"
                        label="截单时间"
                        v-if="['NB', 'XM'].includes(showTab)"
                        align="center"
                    >
                        <template slot-scope="scope">
                            {{
                                scope.row.portCloseTime,

                            }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="giDatetime" label="还重时间" v-if="['SZ'].includes(showTab)" align="center"></el-table-column>
                    <el-table-column prop="manifestTime" label="申报时间" v-if="['SZ'].includes(showTab)" align="center"></el-table-column>
                </el-table-column>
                <el-table-column>
                    <template slot="header">
                        <div class="table-header-item">
                            <span class="label">停靠码头：</span>
                            <span class="content">{{showTab === "NB"
                                        ? wharfCodeToStr(boatInfo.wharf)
                                        : boatInfo.wharf
                            }}</span>
                        </div>
                    </template>
                    <el-table-column
                        label="预计抵港时间"
                        v-if="showTab === 'QD'"
                        align="center"
                    >
                        <template slot-scope="scope">
                            {{
                                scope.row.etaArrivalTime,
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="etaDepartureTime"
                        label="预计离港时间"
                        v-if="showTab === 'QD'"
                        align="center"
                    >
                        <template slot-scope="scope">
                            {{
                                scope.row.etaDepartureTime,

                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="!['TJ','SZ'].includes(showTab)"
                        prop="arrivalTime"
                        label="实际靠港时间"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        v-if="!['TJ','SZ'].includes(showTab)"
                        prop="departureTime"
                        label="实际离港时间"
                        align="center"
                    ></el-table-column>
                   
                    <el-table-column prop="customPassTime" label="海关放行" v-if="['SZ'].includes(showTab)" align="center"></el-table-column>
                    <el-table-column prop="lsDatetime" label="装船时间" v-if="['SZ'].includes(showTab)" align="center">

                    </el-table-column>
                </el-table-column>
            </el-table>
        </div>
        <voyage-change-dialog
            v-if="voyageDialogVisible"
            ref="VoyageChangeDialog"
            @refreshDataList="handleRefresh"
            :showTab="showTab"
        ></voyage-change-dialog>
    </div>
</template>
<script>
import VoyageChangeDialog from "../VoyageChangeDialog";
import { wharfCodeToStr } from "@/utils";
export default {
    name: "DetailContentShip",
    props: {
        boatInfo: {
            type: Object,
            default: () => {},
        },
        shipInfo: {
            type: Array,
            default: () => [],
        },
        boxInfo: {
            type: Object,
            default: () => {},
        },
        showTab: {
            type: String,
        },
    },
    data() {
        return {
            voyageDialogVisible: false,
            wharfCodeToStr,
            wharfDialogVisible: false,
            showShipKeyArr: {
                'NB': ['etaArrivalTime','etaDepartureTime','ctnApplyBeginTime','ctnApplyEndTime','portCloseTime','arrivalTime','departureTime'],

                'SH': ['etaArrivalTime','etaDepartureTime','ctnApplyBeginTime','ctnApplyEndTime','arrivalTime','departureTime'],

                'XM': ['etaArrivalTime','etaDepartureTime','ctnApplyBeginTime','ctnApplyEndTime','portCloseTime','arrivalTime','departureTime'],

                'QD': ['ctnApplyBeginTime','ctnApplyEndTime','jgApplyBeginTime','jgApplyEndTime','etaArrivalTime',
                'etaDepartureTime','arrivalTime','departureTime'],

                'TJ': ['jgApplyBeginTime','jgApplyEndTime'],

                'SZ': ['bkDatetime','firstPrintDatetime','emptyOutTime','manifestTime','lsDatetime','giDatetime','customPassTime'],
            } // 不同港口船期信息显示的列
        };
    },
    computed: {
        canUpdateShip() {
            return !Boolean(this.boxInfo?.blnoInfo?.inGateCount);
        },
        showShipInfo(){
            let hasValue = false;
            if(this.shipInfo[0]){
                hasValue = this.showShipKeyArr[this.showTab].some(key => this.shipInfo[0][key]);
            }
            return hasValue ? this.shipInfo : [];
        }
    },
    components: {
        VoyageChangeDialog,
    },
    methods: {
        handleTitleClick() {
            document.querySelector("#logistics-detail-top").scrollIntoView();
        },
        goShipMap(shipInfo) {
            const newpage = this.$router.resolve({
                path: "/shipmap",
                query: {
                    id: shipInfo.id,
                    blno: shipInfo.blno,
                    vesselEname: shipInfo.vesselEname,
                    voyage: shipInfo.voyage,
                },
                params: { item: shipInfo },
            });
            window.open(newpage.href, "_blank");
        },
        //修改船名航次
        changeVoyage() {
            this.voyageDialogVisible = true;
            var dataList = [];
            dataList.push(this.boxInfo);
            this.$nextTick(() => {
                this.$refs.VoyageChangeDialog.init(dataList);
            });
        },
        //修改船名航次后刷新参数和页面
        handleRefresh() {
            this.$emit("confirm");
        },
        handleChangeWharf(params) {
            this.$emit("confirm");
            this.boatInfo.wharf = params.wharfCode;
        },
    },
};
</script>
<style scoped>
#logistics-detail-boatInfo {
    font-size: 14px;
}
.info_title {
    display: flex;
    align-items: center;
    width: fit-content;
    font-weight: 700;
    margin: 0 0 1rem;
    color: #409eff;
    cursor: pointer;
}

.info_title:before {
    content: "";
    height: 2rem;
    margin-right: 1rem;
    border-left: 2px solid #409eff;
}

.p_no_margin2 {
    margin: 0;
    font-weight: 700;
}

.flex_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
        font-weight: normal;
    }
    .value {
        font-weight: bold;
        color: #333333;
    }
}

.table-header-item{
    display: flex;
    align-items: center;
    .label{
        font-weight: normal;
    }
    .content{
        font-weight: bold;
        color: #333333;
    }
}
</style>
