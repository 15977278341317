<template>
    <div id="logistics-detail-destination">
        <p class="info_title">目的港信息</p>
        <AbroadMove ref="abroadMoveRef" />
    </div>
</template>
<script>
import AbroadMove from "@/views/Logistics/components/AbroadMove.vue";
export default {
    props: {
        boxInfo: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        AbroadMove,
    },
    mounted() {
        // console.log(this.boxInfo,'this.boxInfo')
        const { blno, vesselsName, voyage } = this.boxInfo;
        this.$refs.abroadMoveRef.trackAbroadMove({
            billNo: blno,
            vesselsName,
            voyage,
        });
    },
};
</script>
<style lang="stylus" scoped>
#logistics-detail-destination {
    font-size: 14px;
}
.info_title {
    display: block;
    width: fit-content;
    font-weight: 700;
    margin: 0 1rem;
    color: #409eff;
    cursor: pointer;
    margin-bottom: 20px;
}

.info_title:before {
    content: "";
    margin-top: 12px;
    margin-right: 1rem;
    border-left: 2px solid #409eff;
}
</style>
