var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"npp-status-cell-container"},[(!_vm.rowData.boxCusmov || (_vm.rowData.boxCusmov && _vm.rowData.nppStatus === 'Y'))?[(['NB', 'XM'].includes(_vm.showTab))?_c('el-row',{staticClass:"npp-status-cell",attrs:{"type":"flex","align":"middle","justify":"center"}},[_c('el-tooltip',{attrs:{"effect":"dark","disabled":!_vm.rowData.wharfRemark,"content":_vm.rowData.wharfRemark,"placement":"left"}},[_c('span',{class:[
                        {
                            p_green:
                                _vm.rowData.nppStatus ===
                                'Y',
                        },
                        {
                            p_red:
                                _vm.rowData.nppStatus ===
                                'N',
                        },
                    ]},[_vm._v(" "+_vm._s(_vm.passFlag2String( _vm.rowData.nppStatus, _vm.showTab === "XM", ))+" ")])]),(
                    _vm.showTab === 'NB' &&
                    _vm.rowData.nppStatus !== 'Y'
                )?_c('el-tooltip',{attrs:{"content":"手动刷新","placement":"right","effect":"dark"}},[(!_vm.manualRefreshLoading)?_c('MyIcon',{attrs:{"icon":"mingcute:refresh-3-fill","size":16,"clickable":true,"theme":"primary"},on:{"click":function($event){return _vm.handleManualRefresh(_vm.rowData)}}}):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.manualRefreshLoading),expression:"manualRefreshLoading"}],staticClass:"loading-placeholder",attrs:{"element-loading-spinner":"el-icon-loading","element-loading-background":"transparent"}})],1):_vm._e()],1):(['SH'].includes(_vm.showTab))?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","disabled":!_vm.rowData.changeLogs ||
                _vm.rowData.changeLogs.length <= 1}},[_c('el-badge',{staticClass:"is-dot",attrs:{"hidden":!_vm.rowData.changeLogs ||
                    _vm.rowData.changeLogs.length <= 1,"value":_vm.rowData.changeLogs &&
                    _vm.rowData.changeLogs.length > 1
                        ? _vm.rowData.changeLogs.length
                        : 0}},[_c('span',{class:[
                        {
                            p_green:
                                _vm.rowData.nppStatus ===
                                    'Y' &&
                                (!_vm.rowData.changeLogs ||
                                    _vm.rowData.changeLogs
                                        .length <= 1),
                        },
                        {
                            p_blue:
                                _vm.rowData.nppStatus ===
                                    'Y' &&
                                _vm.rowData.changeLogs &&
                                _vm.rowData.changeLogs
                                    .length > 1,
                        },
                    ]},[_vm._v(" "+_vm._s(_vm.passFlag2String( _vm.rowData.nppStatus, true, ))+" ")])]),_c('template',{slot:"content"},[_c('div',{staticStyle:{"padding":"0 20px"}},[_c('h3',[_vm._v("码放变更记录：")]),_c('el-timeline',_vm._l((_vm.rowData.changeLogs),function(activity,index){return _c('el-timeline-item',{key:index,attrs:{"timestamp":activity.createTime}},[_c('div',[_c('div',{staticClass:"changeLog-item-value"},[_vm._v(" "+_vm._s(_vm.passFlag2String( activity.nppStatus, true, ))+" ")]),(
                                        activity.nppStatus &&
                                        activity.nppStatus !==
                                            'Y' &&
                                        activity.descs
                                    )?_c('div',{staticClass:"changeLog-item-desc"},[_vm._v(" ("+_vm._s(activity.descs)+") ")]):_vm._e()])])}),1)],1)])],2):_vm._e()]:(_vm.rowData.boxCusmov)?[_c('el-row',{attrs:{"type":"flex","align":"middle","justify":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.rowData.boxCusmov)+" ")]),_c('el-image',{staticClass:"tIcon_style",attrs:{"src":_vm.tootipIcon},on:{"click":function($event){return _vm.openWindow(_vm.boxCusmovDoc)}}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }