import { render, staticRenderFns } from "./YGTWharfEdit.vue?vue&type=template&id=a54ff8d0&scoped=true"
import script from "./YGTWharfEdit.vue?vue&type=script&lang=js"
export * from "./YGTWharfEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a54ff8d0",
  null
  
)

export default component.exports