<template>
    <ShipDialog
        :visible.sync="visible"
        class="assemble_dialog"
        size="small"
        title="请输入拼票数"
        width="400px"
        @close="close"
    >
        <el-input
            placeholder="请输入拼票数（2-99）"
            v-model="putTogetherNum"
        ></el-input>
        <div class="model_opt_left_div">
            <el-popover placement="top" trigger="manual">
                <div slot="reference" class="model_choose_div">
                    <el-dropdown trigger="click" placement="bottom">
                        <span class="el-dropdown-link model_opt_btn">
                            {{ chooseModelflag == "0" ? "默认" : "自定义" }}
                            <i
                                class="el-icon-arrow-down el-icon--right"
                            ></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <el-radio
                                    label="0"
                                    v-model="chooseModelflag"
                                    @click.native="customizeNum = ''"
                                    class="radio_width"
                                >
                                    默认
                                </el-radio>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-radio
                                    label="1"
                                    v-model="chooseModelflag"
                                    @click.native="customizeNum = ''"
                                    class="radio_width"
                                >
                                    自定义
                                </el-radio>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <span
                        v-if="chooseModelflag === '0'"
                        class="span-class"
                        style="flex-grow: 1"
                    >
                        默认从A开始自动排序
                    </span>
                    <span v-if="chooseModelflag === '1'">
                        <el-input
                            placeholder="示例: B-D, G, O-Q"
                            v-model="customizeNum"
                            @input="checkInputAlpha"
                            class="input-together-choose"
                            style="flex-grow: 1"
                        ></el-input>
                    </span>
                </div>
            </el-popover>
        </div>
        <DialogFooter
            @confirm="checkPutTogetherNum"
            @cancel="close"
            :top="30"
        ></DialogFooter>
    </ShipDialog>
</template>

<script>
import { checkRightFormat } from "@/utils/validate";
import ShipDialog from "@/components/layout/ShipDialog";
import DialogFooter from "@/components/layout/DialogFooter.vue";
export default {
    name: 'BillAssembleDialog',
    props: {
        showTab: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            visible: false, // 是否显示
            putTogetherNum: '', // 拼票数
            chooseModelflag: "0", // 拼票方式 0：默认 1：自定义
            customizeNum: '', // 自定义拼票规则
            customizeRes: [], // 自定义拼票规则结果
        }
    },
    components: {
        ShipDialog,
        DialogFooter
    },
    methods: {
        checkInputAlpha() {
            this.customizeNum = checkRightFormat(this.customizeNum);
        },
        checktype(val) {
            var temp = val.split(",");
            var res = [];
            temp.forEach((item) => {
                var data = item.split("-");
                res.push(data);
            });
            var str = [];
            res.forEach((item) => {
                if (item.length == 2) {
                    if (item[1].length == 1) {
                        var len =
                            item[1].charCodeAt() - item[0].charCodeAt() + 1;
                        // console.log(len)
                        for (var i = 0; i < len; i++) {
                            str.push(item[0].charCodeAt() + i);
                        }
                    } else if (item[0].length == 1 && item[1].length == 2) {
                        var len =
                            item[1][1].charCodeAt() -
                            64 +
                            (item[1][0].charCodeAt() -
                                item[0].charCodeAt() +
                                1) *
                                26;

                        for (var i = 0; i < len; i++) {
                            str.push(item[0].charCodeAt() + i);
                        }
                    } else if (item[0].length == 2 && item[1].length == 2) {
                        var len =
                            item[1][1].charCodeAt() -
                            item[0][1].charCodeAt() +
                            1 +
                            (item[1][0].charCodeAt() -
                                item[0][0].charCodeAt()) *
                                26;
                        // console.log(len)
                        for (var i = 0; i < len; i++) {
                            str.push(
                                (item[0][0].charCodeAt() - 64) * 26 +
                                    item[0][1].charCodeAt() +
                                    i,
                            );
                        }
                    }
                    // str += item
                } else if (item.length == 1) {
                    if (item[0].length == 1) {
                        // console.log(item)
                        str.push(item[0].charCodeAt());
                    } else if (item[0].length == 2) {
                        // console.log(item)
                        str.push(
                            (item[0][0].charCodeAt() - 64) * 26 +
                                item[0][1].charCodeAt(),
                        );
                    }
                } else {
                    str = [];
                }
            });
            return str;
        },
        //判断拼单输入是否正确
        checkPutTogetherNum() {
            let numRe = /^\+?[1-9][0-9]*$/;
            if (
                this.putTogetherNum == "" ||
                !numRe.test(this.putTogetherNum) ||
                this.putTogetherNum >= 100 ||
                this.putTogetherNum == 1
            ) {
                this.$message.error("请输入2—99的正整数");
            } else {
                if (this.chooseModelflag === "1") {
                    if (this.customizeNum) {
                        let customAscii = this.checktype(this.customizeNum);
                        if (
                            customAscii.length == parseInt(this.putTogetherNum)
                        ) {
                            this.customizeRes = customAscii;
                            this.visible = false;
                            this.$emit('confirm');
                        } else {
                            if (customAscii.length == 0) {
                                this.$message.error("请输入正确的自定义格式");
                                return;
                            } else {
                                this.$message.error(
                                    "自定义字母总数" +
                                        String(customAscii.length) +
                                        "和拼票数" +
                                        this.putTogetherNum +
                                        "不符",
                                );
                                return;
                            }
                        }
                    } else {
                        this.$message.error("未按要求输入自定义字母");
                        return;
                    }
                } else {
                    this.$emit('confirm');
                    this.visible = false;
                }
            }
        },
        reset() {
            this.putTogetherNum = '';
            this.chooseModelflag = "0";
            this.customizeNum = '';
            this.customizeRes = [];
        },
        // 获取全部拼单提单号
        getTogetherBlno(blon, toNum) {
            var result = [];
            if (toNum) {
                toNum.forEach((item) => {
                    let s = "";
                    item = parseInt(item);
                    while (item > 0) {
                        let m = item % 26;
                        m = m === 0 ? (m = 26) : m;
                        s = String.fromCharCode(64 + parseInt(m)) + s;
                        item = (item - m) / 26;
                    }
                    var singleBlon = blon + s;
                    result.push(singleBlon);
                });
            }
            return result;
        },
        // 获取全部订阅提单号
        getBoxWatchList(blno) {
            if (this.putTogetherNum == "") {
                    this.$message.warning("请输入拼票信息");
                    this.visible = true;
                    return;
            }
            let togetherNum = [];
            if (this.chooseModelflag === "1") {
                togetherNum = this.customizeRes.map((index1) => {
                    return index1 - 64;
                });
            } else {
                togetherNum = [
                    ...new Array(parseInt(this.putTogetherNum)).keys(),
                ].map((index2) => {
                    return index2 + 1;
                });
            }
            return this.getTogetherBlno(blno, togetherNum);
        },
        open(){
            this.visible = true;
        },
        close(){
            this.visible = false;
            this.reset();
        }
    }
}
</script>

<style lang="stylus" scoped>
.model_choose_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 38px;
  margin-top: 20px;
  border: 1px solid #EBEEF5;
  background: #fff;

  /deep/ .el-input-group__append, .el-input-group__prepend {
    border: none;
  }
}
.model_opt_btn {
  display: flex;
  margin: 0 1rem;
  font-size: 15px;
  align-items: center;
  white-space: nowrap;
}
.radio_width {
  width: 100%;

  /deep/ .el-radio__input {
    line-height: 36px;
  }
}
.span-class {
  font-family: Microsoft YaHei UI;
  color: #000;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 248px;
}

.input-together-choose {
  display: flex;
  flex-direction: row;
  width: 268px;
  textarea: 20px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
}
</style>