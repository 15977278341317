<template>
    <div class="data_search_div">
        <el-button
            type="danger"
            @click="handleDelete"
            size="small"
            style="margin-right: 8px;"
        >
            删除
        </el-button>
        <el-popover
            placement="top"
            trigger="manual"
            v-model="popoverVisible"
            :content="popoverContent"
        >
            <div slot="reference" class="search_input_div">
                <el-dropdown trigger="click" size="small">
                    <span
                        style="
                            display: flex;
                            margin-left: 5px;
                            width: 8rem;
                            align-items: center;
                        "
                    >
                        筛选状态
                        <i
                            class="el-icon-arrow-down"
                        ></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <el-radio-group
                                v-model="searchCustomflag"
                                @change="searchList"
                            >
                                <el-radio label="">
                                    海放全部状态
                                </el-radio>
                                <el-radio label="1">
                                    海关已放行
                                </el-radio>
                                <el-radio label="0">
                                    海关未放行
                                </el-radio>
                            </el-radio-group>
                        </el-dropdown-item>
                        <el-dropdown-item
                            divided
                            v-if="['NB', 'SH', 'XM'].includes(showTab)"
                        >
                            <el-radio-group
                                v-model="searchNppstatus"
                                @change="searchList"
                            >
                                <el-radio label="">
                                    码头全部状态
                                </el-radio>
                                <el-radio label="1">
                                    码头已放行
                                </el-radio>
                                <el-radio label="0">
                                    码头未放行
                                </el-radio>
                            </el-radio-group>
                        </el-dropdown-item>
                        <el-dropdown-item
                            divided
                            v-if="
                                ['NB', 'SH', 'QD', 'XM'].includes(
                                    showTab,
                                )
                            "
                        >
                            <el-radio-group
                                v-model="searchCtnapplybeginFlag"
                                @change="searchList"
                            >
                                <el-radio label="">
                                    开港全部状态
                                </el-radio>
                                <el-radio label="1">
                                    有开港时间
                                </el-radio>
                                <el-radio label="0">
                                    无开港时间
                                </el-radio>
                            </el-radio-group>
                        </el-dropdown-item>
                        <el-dropdown-item
                            divided
                            v-if="['NB', 'XM'].includes(showTab)"
                        >
                            <el-radio-group
                                v-model="searchPortcloseFlag"
                                @change="searchList"
                            >
                                <el-radio label="">
                                    截单全部状态
                                </el-radio>
                                <el-radio label="1">
                                    有截单时间
                                </el-radio>
                                <el-radio label="0">
                                    无截单时间
                                </el-radio>
                            </el-radio-group>
                        </el-dropdown-item>
                        <el-dropdown-item
                            divided
                            v-if="['NB', 'XM'].includes(showTab)"
                        >
                            <el-radio-group
                                v-model="searchCtnapplyendFlag"
                                @change="searchList"
                            >
                                <el-radio label="">
                                    截港全部状态
                                </el-radio>
                                <el-radio label="1">
                                    有截港时间
                                </el-radio>
                                <el-radio label="0">
                                    无截港时间
                                </el-radio>
                            </el-radio-group>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown
                    trigger="click"
                    placement="bottom"
                    size="small"
                    v-if="showTab !== 'SZ'"
                >
                    <span
                        style="
                            display: flex;
                            width: 8rem;
                            align-items: center;
                        "
                    >
                        截单/截港
                        <i
                            class="el-icon-arrow-down"
                        ></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <el-radio
                                label="0"
                                v-model="portcloseFlag"
                                @change="searchList"
                            >
                                显示全部
                            </el-radio>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-radio
                                label="1"
                                v-model="portcloseFlag"
                                @change="searchList"
                            >
                                临近截关期(提前{{
                                    $store.state.portclosetime
                                }}小时)
                            </el-radio>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-radio
                                label="2"
                                v-model="portcloseFlag"
                                @change="searchList"
                            >
                                临近截港期(提前{{
                                    $store.state.ctnapplyendtime
                                }}小时)
                            </el-radio>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-divider direction="vertical"></el-divider>
                <el-autocomplete
                    clearable
                    size="small"
                    @clear="blurForBug()"
                    v-model="searchVesselenameVoyage"
                    @input="checkSearchVesselenameVoyage()"
                    :fetch-suggestions="querySearch"
                    placeholder="输入船名/航次"
                    :trigger-on-focus="false"
                    popper-class="vessel-voyage-popper"
                    @select="handleSelect"
                    @change="handleInputClear"
                    @keyup.enter.native="selectInfo()"
                ></el-autocomplete>
                <el-divider direction="vertical"></el-divider>
                <el-autocomplete
                    clearable
                    size="small"
                    @clear="blurForBug()"
                    class="input-with-select"
                    v-model="searchBlno"
                    @input="checkSearchBlno()"
                    :fetch-suggestions="querySearchBlno"
                    placeholder="输入提单号搜索"
                    :trigger-on-focus="false"
                    @change="handleInputClear"
                    @select="handleSelectBlno"
                    @keyup.enter.native="selectInfo()"
                ></el-autocomplete>
                <el-divider direction="vertical"></el-divider>
                <el-input
                    v-model="searchRemark"
                    placeholder="输入备注搜索"
                    size="small"
                    clearable
                    @change="handleInputClear"
                    @keyup.enter.native="searchList"
                ></el-input>
                <el-divider direction="vertical"></el-divider>
                <el-select
                    v-model="searchClientComanyId"
                    placeholder="请选择客户公司"
                    size="small"
                    style="width: 140px"
                    class="input-with-select"
                    id="clientCompany-filter-select"
                    clearable
                    @change="searchList"
                >
                    <el-option
                        v-for="item in curClientCompanyList"
                        :key="item.id"
                        :value="String(item.id)"
                        :label="item.clientName"
                    ></el-option>
                </el-select>
                <el-button
                    size="small"
                    slot="append"
                    icon="el-icon-search"
                    style="margin-left: 2px"
                    @click="selectInfo()"
                ></el-button>
            </div>
        </el-popover>
        <div
            class="search_input_div"
            style="margin-left: 8px"
            v-if="showTab !== 'SZ'"
        >
            <el-select
                v-model="timeType"
                size="small"
                clearable
                placeholder="请选择"
                style="width: 130px"
            >
                <el-option
                    v-for="item in timeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
            </el-select>
            <el-date-picker
                v-model="searchtime"
                size="small"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                @change="handleInputClear"
            ></el-date-picker>
            <el-button
                size="small"
                slot="append"
                icon="el-icon-search"
                @click="searchList"
            ></el-button>
        </div>
        <el-button
            class="refresh_btn"
            size="small"
            type="success"
            plain
            @click="handleRefresh"
        >
            重新载入
        </el-button>
        <el-popover
            placement="top"
            trigger="click"
            v-model="downloadPopVisible"
            v-if="!['SZ'].includes(showTab)"
        >
            <div class="download_btn_div">
                <el-button
                    @click="getExcelModel(1)"
                    class="download_btn"
                    type="primary"
                    plain
                >
                    1、船名航次在同一格
                </el-button>
                <el-button
                    @click="getExcelModel(2)"
                    class="download_btn"
                    type="primary"
                    plain
                >
                    2、船名航次在不同格
                </el-button>
            </div>
            <el-button slot="reference" size="small">下载模板</el-button>
        </el-popover>
        <el-tooltip placement="top">
            <div slot="content">
                <div
                    slot="header"
                    class="clearfix"
                    style="font-size: 14px"
                >
                    <span>
                        出现以下情形之一的会自动挪入到历史记录，不再进行后续的订阅更新。
                        <br />
                        若是挪入有误，可以进行还原操作。
                    </span>
                </div>
                <div
                    v-for="mess in historyMsgList"
                    :key="mess"
                    class="text item"
                    style="font-size: 12px; margin: 6px 0 0 4px"
                >
                    {{ mess }}
                </div>
            </div>
            <el-button class="refresh_btn" size="small" @click="goHistory">
                历史记录
            </el-button>
        </el-tooltip>
        <column-set
            :table-list="tableList"
            page-key="logistics"
            useSystemKey
            :custom-style="{ marginTop: 0 }"
            :reset-data="{
                NB: RESETTABLE.resetTableNB,
                SH: RESETTABLE.sResetTable,
                QD: RESETTABLE.qdResetTable,
                TJ: RESETTABLE.tjResetTable,
                XM: RESETTABLE.xmResetTable,
                SZ: RESETTABLE.szResetTable,
            }"
            :save-mutation="{
                NB: 'table/editTableListNB',
                SH: 'table/editTableListSH',
                QD: 'table/editTableListQD',
                TJ: 'table/editTableListTJ',
                XM: 'table/editTableListXM',
                SZ: 'table/editTableListSZ',
            }"
            :reset-mutation="{
                NB: 'table/resetTableListNB',
                SH: 'table/resetTableListSH',
                QD: 'table/resetTableListQD',
                TJ: 'table/resetTableListTJ',
                XM: 'table/resetTableListXM',
                SZ: 'table/resetTableListSZ',
            }"
            :is-experience="isExperience"
        ></column-set>
        <el-button
            class="refresh_btn"
            size="small"
            style="margin-right: 0"
            @click="handleWxPushSetting"
        >
            微信推送设置
        </el-button>
        <el-button
            class="refresh_btn"
            size="small"
            style="margin-right: 0"
            @click="handleChangeFixHeader"
        >
            {{ fixTableHeader ? "关闭固定表头" : "开启固定表头" }}
        </el-button>
    </div>
</template>

<script>
import ColumnSet from '@/components/common/ColumnSet.vue';
import { msToDate,apiCallback } from '@/utils';
import { setRightForrmatSpace3, setRightForrmat } from '@/utils/validate';
import { SHORT_CUTS } from "@/utils/dataList";
import { logisticsRequest } from '../logistics';
import { logisticsTableList } from "../tableList";
import { blurForBug } from '../utils';

export default {
    name: 'TableSearchHeader',
    props: {
        showTab: {
            type: String,
            default: () => 'NB',
        },
        tableList: {
            type: Array,
            default: () => [],
        },
        fixTableHeader: {
            type: Boolean,
            default: () => false,
        },
        isExperience: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        ColumnSet
    },
    data() {
        return {
            totalVoyageList: [], //存放已订阅船名航次用于筛选
            totalBlnoList: [], //存放已订阅提单号用于筛选
            popoverVisible: false,
            popoverContent: '',
            searchVesselenameVoyage: '',
            searchCtnapplybeginFlag: '', // 筛选框开港
            searchCtnapplyendFlag: '', // 筛选框截港
            searchPortcloseFlag: '', // 筛选框截关
            searchRemark: '',
            searchBlno: '', //提单号搜索
            searchNppstatus: '', // 筛选框码头放行
            searchCustomflag: '', //筛选框海放
            portcloseFlag: "", //截关截港筛选，0：全部，1：截关，2：截港
            searchClientComanyId: '', //客户公司搜索
            searchtime: '', //日期搜索
            timeType: '', //日期类型搜索
            msToDate,
            historyMsgList: [
                "1.主动删除",
                "2.离港后1个月",
                "3.添加订阅后1个月内无任何数据更新",
                "4.多方原因未完结60天",
                "5.未输入船名,并且7日内查不出任何数据",
            ],
            pickerOptions: {
                shortcuts: [...SHORT_CUTS],
            },
            downloadPopVisible: false,
            blurForBug,
        }
    },
    computed: {
        timeOptions() {
            const result = logisticsTableList({
                tab: this.showTab,
                type: 4,
            });
            return result;
        },
        curClientCompanyList() {
            return this.$store.state.user.clientUseComapnyList || [];
        },
    },
    methods: {
        // 处理船名航次
        formatVesselenameVoyage(list) {
            var vesselsName = "";
            list.forEach((item, index) => {
                if (index == list.length - 1) {
                    return;
                } else if (index == list.length - 2) {
                    vesselsName = vesselsName + item;
                } else {
                    vesselsName = vesselsName + item + "/";
                }
            });
            return list.length === 2
                ? {
                      vesselsName,
                      voyage: list[list.length - 1],
                  }
                : {};
        },
        // 获取当前全部晒选项数据
        getCurSearchParams() {
            const { vesselsName, voyage } = this.formatVesselenameVoyage(this.searchVesselenameVoyage.split("/"));
            return {
                remark: this.searchRemark,
                blno: this.searchBlno,
                vesselsName,
                voyage,
                nppStatus: this.searchNppstatus,
                customFlag: this.searchCustomflag,
                ctnApplyBeginFlag: this.searchCtnapplybeginFlag,
                ctnApplyEndFlag: this.searchCtnapplyendFlag,
                portCloseFlag: this.portcloseFlag,
                portClose: this.portcloseFlag === "1" ? 1 : undefined,
                ctnApplyEnd: this.portcloseFlag === "2" ? 1 : undefined,
                queryFlag: this.timeType,
                clientCompanyId: this.searchClientComanyId,
                start: this.searchtime
                ? this.msToDate(this.searchtime[0]).withoutTime
                : this.searchtime,
                end: this.searchtime
                ? this.msToDate(this.searchtime[1]).withoutTime
                : this.searchtime,
            }
        },
        handleDelete() {
            this.$emit('delete')
        },
        searchList() { 
            if (this.timeType && !this.searchtime) {
                this.$message.error("请输入搜索日期范围");
                return;
            }
            if (!this.timeType && this.searchtime) {
                this.$message.error("请输入搜索日期类别");
                return;
            }
            this.$emit('search')
        },
        //重置popver
        resetPopover() {
            this.popoverVisible = false;
            this.popoverContent = "";
        },
        //从列表中模糊匹配
        selectInfo() {
            if (this.searchRemark || this.searchClientComanyId) {
                // 如果有备注或者客户公司则不需要校验其他筛选项格式
                this.searchList()
                return;
            }
            if (!this.searchBlno && !this.searchVesselenameVoyage) {
                this.popoverVisible = true;
                this.popoverContent = "请输入关键字";
                setTimeout(this.resetPopover, 2000);
                return;
            }
            if (
                this.searchBlno.length >= 4 ||
                this.searchVesselenameVoyage.length >= 4
            ) {
                if (this.searchVesselenameVoyage) {
                    const list = this.searchVesselenameVoyage.split("/");
                    if (list.length >= 2) {
                        const { vesselsName, voyage } =
                            this.formatVesselenameVoyage(list);
                        this.searchVesselename = vesselsName;
                        this.searchVoyage = voyage;
                        this.searchList()
                    } else {
                        this.$message.error("请同时输入船名和航次");
                    }
                } else {
                    this.searchList()
                }
            } else {
                this.popoverVisible = true;
                this.popoverContent = "请输入长度至少为4位的关键字";
                setTimeout(this.resetPopover, 2000);
                return;
            }
        },
        resetSearch() {
            this.searchVesselenameVoyage = '';
            this.searchRemark = '';
            this.searchBlno = '';
            this.searchNppstatus = '';
            this.searchCustomflag = '';
        },
        checkSearchVesselenameVoyage() {
            this.searchVesselenameVoyage = setRightForrmatSpace3(
                this.searchVesselenameVoyage,
            );
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.vesselsName
                        .toUpperCase()
                        .indexOf(queryString.toUpperCase()) != -1 ||
                    restaurant.voyage
                        .toUpperCase()
                        .indexOf(queryString.toUpperCase()) != -1
                );
            };
        },
        //船名航次搜索框带输入建议
        querySearch(queryString, cb) {
            var restaurants = this.totalVoyageList;
            var results = queryString
                ? restaurants.filter(this.createFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        //获取全部的船名航次用于搜索
        getVoyageList() {
            var that = this;
            const reqFunc = logisticsRequest({
                tab: this.showTab,
                type: 2,
            });
            reqFunc({ code: this.showTab })
                .then((response) => {
                    const data = apiCallback(response);
                    let list = data;
                    if (list === null) {
                        // console.log("queryVeVo无数据");
                    } else {
                        list.forEach((item) => {
                            let cacheItem = {};
                            cacheItem.value =
                                item.vesselsName + "/" + item.voyage;
                            cacheItem.vesselsName = item.vesselsName;
                            cacheItem.voyage = item.voyage;
                            that.totalVoyageList.push(cacheItem);
                        });
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        //获取全部的提单号用于搜索
        getBlnoList() {
            var that = this;
            const reqFunc = logisticsRequest({
                type: 3,
                tab: this.showTab,
            });

            reqFunc({ code: this.showTab })
                .then((response) => {
                    const data = apiCallback(response);
                    let list = data;
                    if (list !== null) {
                        list.forEach((item) => {
                            let cacheItem = {};
                            cacheItem.value = item;
                            that.totalBlnoList.push(cacheItem);
                        });
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        createFilterBlno(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toUpperCase()
                        .indexOf(queryString.toUpperCase()) != -1
                );
            };
        },
        //提单号搜索框带输入建议
        querySearchBlno(queryString, cb) {
            var restaurants = this.totalBlnoList;
            var results = queryString
                ? restaurants.filter(this.createFilterBlno(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        //检测搜索关键字格式
        checkSearchBlno() {
            this.searchBlno = setRightForrmat(this.searchBlno);
        },
        //船名航次搜索框选择了输入建议
        handleSelect(item) {
            this.searchVesselename = item.vesselsName;
            this.searchVoyage = item.voyage;
        },
        // 筛选框清空
        handleInputClear(val) {
            if (!val) {
                this.timeType = "";
                this.searchList();
            }
        },
        //提单号搜索框选择了输入建议
        handleSelectBlno(item) {
            this.searchVesselename = item.value;
        },
        // 重置搜索条件，重新载入列表
        handleRefresh() {
            this.resetSearch();
            this.searchList();
        },
        // 微信推送设置
        handleWxPushSetting() {
            this.$emit('wxPushSetting');
        },
        goHistory() {
            this.$router.push("/history");
        },
        handleChangeFixHeader() {
            this.$emit('changeFixHeader');
        },
        //下载Excel模板
        getExcelModel(id) {
            this.downloadPopVisible = false;
            // const needDownId = this.showTab === "QD" ? id + 4 : id;
            let needDownId = id;
            if (this.showTab === "QD") {
                needDownId = id + 4;
            } else if (this.showTab === "XM") {
                needDownId = id + 6;
            }
            window.open(this.$excel_url + "/" + needDownId, "_blank");
        },
    },
}
</script>

<style lang="stylus" scoped>
.data_search_div {
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  /deep/ .el-button{
    height:32px;
    padding: 4px 8px;
    margin-left: 8px;
  }
  /deep/ .el-input-group {
    width: 25rem;
    // margin-left: 1rem;
  }

  /deep/ .el-input__inner {
    // border: 1px solid #DCDFE6;
    border: none;
  }

  /deep/ .el-input--suffix {
    padding: 0;
  }
}
.search_input_div {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #EBEEF5;
  background: #fff;
  height: 32px;
  margin-bottom: 10px;

  .el-divider--vertical{
    margin: 0;
    height: 2rem;
  }

  .el-input {
    width: 15rem;
  }

  /deep/ .el-dropdown{
    font-size: 13px;
  }

  /deep/ .el-input-group__append, .el-input-group__prepend {
    border: none;
  }
}

.refresh_btn {
  height: 32px;
  padding: 4px 12px;
  cursor: pointer;
}
</style>