var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ship-info-cell"},[(
            !_vm.rowData.vesselsName &&
            !_vm.rowData.voyage &&
            !_vm.rowData.vvFlag &&
            _vm.rowData.sonCount
        )?_c('el-link',{staticClass:"addVoyage_link table-cell-link",attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleAdd(_vm.rowData)}}},[_vm._v(" 添加船名/航次 ")]):_vm._e(),(
            _vm.rowData.vesselsName ||
            _vm.rowData.voyage
        )?[_c('el-dropdown',[_c('div',{staticClass:"shipInfo-col-wrap"},[_c('div',{staticClass:"ship-info-text-wrap"},[_c('div',{staticClass:"ship-info-text"},[_c('span',{staticClass:"ship-info-text-name"},[_vm._v(_vm._s(_vm.rowData.vesselsName))]),_c('span',{staticStyle:{"margin":"0 2px"}},[_vm._v("/")]),_c('span',{staticClass:"ship-info-text-voyage"},[_vm._v(_vm._s(_vm.rowData.voyage))])])])]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                    _vm.rowData.vesselsName
                ),expression:"\n                    rowData.vesselsName\n                ",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(
                    _vm.copySucc
                ),expression:"\n                    copySucc\n                ",arg:"success"}]},[_vm._v(" 复制船名 ")]),_c('el-dropdown-item',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                    _vm.rowData.voyage
                ),expression:"\n                    rowData.voyage\n                ",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(
                    _vm.copySucc
                ),expression:"\n                    copySucc\n                ",arg:"success"}]},[_vm._v(" 复制航次 ")]),_c('el-dropdown-item',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                    `${_vm.rowData.vesselsName}/${_vm.rowData.voyage}`
                ),expression:"\n                    `${rowData.vesselsName}/${rowData.voyage}`\n                ",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(
                    _vm.copySucc
                ),expression:"\n                    copySucc\n                ",arg:"success"}]},[_vm._v(" 复制全部 ")])],1)],1),_c('div',{staticClass:"ship-info-right"},[(!['SZ'].includes(_vm.showTab))?_c('i',{staticClass:"el-icon-edit",on:{"click":function($event){return _vm.handleAdd(_vm.rowData)}}}):_vm._e(),_c('el-dropdown',[_c('img',{attrs:{"src":require("@/assets/icon/copy.svg")}}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                            _vm.rowData.vesselsName
                        ),expression:"\n                            rowData.vesselsName\n                        ",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(
                            _vm.copySucc
                        ),expression:"\n                            copySucc\n                        ",arg:"success"}]},[_vm._v(" 复制船名 ")]),_c('el-dropdown-item',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                            _vm.rowData.voyage
                        ),expression:"\n                            rowData.voyage\n                        ",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(
                            _vm.copySucc
                        ),expression:"\n                            copySucc\n                        ",arg:"success"}]},[_vm._v(" 复制航次 ")]),_c('el-dropdown-item',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                            `${_vm.rowData.vesselsName}/${_vm.rowData.voyage}`
                        ),expression:"\n                            `${rowData.vesselsName}/${rowData.voyage}`\n                        ",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(
                            _vm.copySucc
                        ),expression:"\n                            copySucc\n                        ",arg:"success"}]},[_vm._v(" 复制全部 ")])],1)],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }