<template>
    <div class="abroad-move-dialog">
        <ShipDialog
            :showTab="showTab"
            width="1200px"
            :visible.sync="visibleSync"
            title="海外运踪"
            top="5vh"
            size="small"
            @close="handleClose"
        >
            <AbroadMove ref="abroadMoveRef" style="margin-top: 50px" />
            <div style="text-align: center;color: #f56c6c;font-size: 14px;margin: 10px 0;">温馨提示：节点内容的中文翻译仅供参考，请以英文为准</div>
            <DialogFooter
                @confirm="handleClose"
                confirmText="关闭"
                cancelText=""
                :showTab="showTab"
                top="0"
            ></DialogFooter>
        </ShipDialog>
    </div>
</template>
<script>
import ShipDialog from "@/components/layout/ShipDialog.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import AbroadMove from "./AbroadMove.vue";
export default {
    props: ["showTab"],
    components: {
        ShipDialog,
        DialogFooter,
        AbroadMove,
    },
    data() {
        return {
            visibleSync: false,
        };
    },
    methods: {
        open(row) {
            if (!row.lastNode) {
                this.$message.warning("本票暂无海外运踪数据");
                return;
            }
            this.visibleSync = true;
            this.$nextTick(async () => {
                const {blno,ctnOwner,vesselsName,voyage}=row;
                this.$refs.abroadMoveRef.trackAbroadMove({
                    billNo: blno,
                    vesselsName,
                    voyage
                });
            });
        },
        handleClose(){
            this.visibleSync = false;
            this.$refs.abroadMoveRef.reset();
        }
    },
};
</script>
