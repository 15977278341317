<template>
    <el-dialog :visible.sync="visible">
        <div>进港/舱单详情</div>
    </el-dialog>
</template>

<script>
import { apiCallback } from '@/utils';
import { logisticsRequest } from "@/views/Logistics/logistics";
import { detailContentShow } from "./detailContentShow";
import DetailContentBox from './DetailContentBox.vue';
import DetailContentManifest from './DetailContentManifest.vue';

export default {
    name: 'SimpleDetailContent',
    props: {
        showTab:{
            type: String,
            default: 'NB'
        }
    },
    components: {
        DetailContentBox,
        DetailContentManifest
    },
    data(){
        return {
            activePosition: "0",
            errorFlag: 0,
            visible: false,
            boatInfo: {},
            boxInfo: {},
            headers: JSON.parse(
                JSON.stringify(
                    detailContentShow({
                        tab: this.showTab,
                        type: 1,
                    }),
                ),
            ),
            loadingView: null,
        }
    },
    methods:{
        open(row){
            this.visible = true
            this.loadingView = this.$loading({
                lock: true,
                text: "实时查询中...",
            });
        },
        //显示nppinfo的选项卡
        showNppTabs(position) {
            this.activePosition = position;
            this.setBoxInfo(position);
        },
        async showDetail(detail) {
            if (this.showTab === "SZ") {
                this.boxInfo = {
                    ...detail.blno,
                    // wharf:detail
                    vesselsName: detail.blno.vessel,
                };
                this.nppinfoVo = detail.ctn || [];

                this.boatInfo = {
                    ...detail.blno,
                    blno: detail.blno.billNo,
                    vesselEname: detail.blno.vessel,
                    wharf: detail.blno.giName || detail.blno.loadingPort,
                };
                this.shipInfo = [detail.blno];
                this.showNppTabs(this.activePosition);
            } else {
                var that = this;
                that.boxInfo = detail;
                if (this.showTab === "NB") {
                    that.nppInGateEmptyVoList =
                        that.boxInfo?.nppInGateEmptyVoList || [];
                } else {
                    that.ctnEmptyVoList = that.boxInfo?.ctnEmptyVoList || [];
                }
                that.nppinfoVo = [];
                if (this.showTab === "NB") {
                    for (var i = 0; i < that.nppInGateEmptyVoList.length; i++) {
                        var temp = that.nppInGateEmptyVoList[i].ctn;
                        if (temp) {
                            that.nppinfoVo.push(temp);
                        }
                    }
                } else {
                    for (var i = 0; i < that.ctnEmptyVoList.length; i++) {
                        var temp = that.ctnEmptyVoList[i].ctn;
                        if (temp) {
                            that.nppinfoVo.push(temp);
                        }
                    }
                }
                //船期时间,有船期先显示船期信息，如果有进港则显示进港的船期信息
                if (detail.vessels) {
                    that.shipInfo = [detail.vessels];
                    that.boatInfo = {
                        wharf: wharfCodeToStr(detail.vessels.wharf),
                        vesselEname: detail.vessels.vesselsName,
                        voyage: detail.vessels.voyage,
                    };
                }

                that.showNppTabs(that.activePosition);
                that.showTotalNum();
            }
        },
        getBoxInfo(id) {
            var that = this;
            // 获取请求详情接口
            const reqFunc = logisticsRequest({
                tab: this.showTab,
                type: 7,
            });
            reqFunc({ id })
                .then((response) => {
                    const data = apiCallback(response);
                    that.showDetail(data);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    if (that.loadingView) {
                        that.loadingView.close();
                    }
                });
        },
    }
}
</script>