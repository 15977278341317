import { getCheck986Str } from "@/utils";
import { queryBoxList } from "@/api/tracking";
import { queryDetailBoxInfo } from "@/api/boxQuery";
import myNotification from "@/utils/my-notification";
import CheckNodeDetailHelpFn from "@/views/BoxQuery/components/CheckNodeDetailHelpFn.js";
import store from "@/store";

const carNumDeal = (truckLicense) => {
    var list = [];
    if (truckLicense) {
        list = truckLicense.split(",");
    }
    return list;
};

/**
 * 处理列表数据
 * @param {Object} item - 列表数据
 * @param {string} showTab - 显示的tab
 * @returns {Object} 处理后的数据
 */
export const dealListOne_New = (item, showTab) => {
    let data = {
        ...item,
        boxCusmov: getCheck986Str(item.checkType),
        changeLogs: item.changeLogs || [], // 上海,天津码放记录
        boxPosition: carNumDeal(item.truckLicense),
    };
    let computedData = "-";
    if (item.inGateCount || item.nppCtnCount) {
        computedData =
            (item.inGateCount ? `${item.inGateCount}箱 /` : "NO /") +
            (item.nppCtnCount ? ` ${item.nppCtnCount}箱` : " NO"); //箱子进港数量
    }
    if (showTab === "NB") {
        data.boxArrivalCount = computedData;
    } else if (showTab === "SH" && computedData !== "-") {
        const preStr = item.preInGateCount ? `${item.preInGateCount}箱` : "NO";
        data.boxArrivalManifest = `${preStr} / ${computedData}`;
    } else if (["TJ", "SZ"].includes(showTab)) {
        if (item.inGateCount) {
            data.boxArrivalManifest = item.inGateCount + "箱";
        } else {
            data.boxArrivalManifest = "-";
        }
    } else {
        data.boxArrivalManifest = computedData;
    }
    data.inputCtns =
        item.inputCtns && item.inputCtns != "0"
            ? Number(item.inputCtns) + "箱"
            : 0; // 青岛可提箱数
    data.backCtns =
        item.backCtns && item.backCtns != "0"
            ? Number(item.backCtns) + "箱"
            : 0; // 青岛返场箱数
    data.cargoCtns =
        item.cargoCtns && item.cargoCtns != "0"
            ? Number(item.cargoCtns) + "箱"
            : 0; //青岛集港箱数
    /**
     * SZ: 深圳
     */
    if (showTab === "SZ") {
        let computedData = "";
        if (item.dischargePort || item.loadingPort) {
            computedData =
                (item.loadingPort || "-") + " / " + (item.dischargePort || "-");
        }
        data.loadingPort = computedData; //装卸货港
        data.yardName = item.yardName || item.loadingPort; //堆场(兼容老数据)
        data.giName = item.giName || item.loadingPort; //码头（堆场）
        data.bkDatetimeFlag =
            Boolean(item.bkDatetime) || item.tcStatus == 10 ? "Y" : "N"; //订舱
        data.firstPrintDatetimeFlag =
            Boolean(item.firstPrintDatetime) || item.tcStatus == 20 ? "Y" : "N"; //打单
        data.emptyOutFlag =
            Boolean(item.emptyOutTime) || item.tcStatus == 30 ? "Y" : "N"; //提空
        data.giNameFlag =
            Boolean(item.giName) || item.tcStatus == 40 ? "Y" : "N"; //还重
        data.vgmDatetimeFlag =
            Boolean(item.vgmDatetime) || item.tcStatus == 50 ? "Y" : "N"; //VGM
        data.manifestFlag =
            Boolean(item.manifestTime) || item.tcStatus == 60 ? "Y" : "N"; //舱单申报
        data.lsDatetimeFlag =
            Boolean(item.lsDatetime) || item.tcStatus == 80 ? "Y" : "N"; //装船
        data.hasDepartureInfoFlag = Boolean(item.hasDepartureInfo) ? "Y" : "N"; //离港
        data.customPassFlag =
            Boolean(item.customPassTime) || item.tcStatus == 70 ? "Y" : "N"; // 海放
        data.tcStatus = item.tcStatus; // 状态
        data.vesselURL = item.vesselURL; // 船司
    }
    return data;
};
// 处理列表数据（拼单）
export const dealListTwo_New = (curItem, showTab) => {
    const result = [];
    const parentData = dealListOne_New(curItem, showTab); // 拼票
    result.push(parentData);
    if (curItem.sonList) {
        curItem.sonList.forEach((item) => {
            var data = {};
            if (item.blno != curItem.blno) {
                data = {
                    ...item,
                    boxCusmov: getCheck986Str(item.checkType),
                    changeLogs: item.changeLogs || [], // 上海码放记录
                    boxPosition: carNumDeal(item.truckLicense),
                    vesselsName: parentData.vesselsName, // 子票没有船名
                    voyage: parentData.voyage, // 子票没有航次
                    parentBlno: parentData.blno,
                };
                if (item.inGateCount) {
                    const computedData =
                        item.inGateCount +
                        "箱/" +
                        (item.nppCtnCount ? item.nppCtnCount + "箱" : "NO"); //箱子进港数量
                    if (showTab === "NB") {
                        data.boxArrivalCount = computedData;
                    } else if (["XM", "TJ"].includes(showTab)) {
                        data.boxArrivalManifest = item.inGateCount + "箱";
                    } else {
                        data.boxArrivalManifest = computedData;
                    }
                }
                data.inputCtns =
                    item.inputCtns && item.inputCtns != "0"
                        ? Number(item.inputCtns) + "箱"
                        : 0; // 青岛可提箱数
                data.backCtns =
                    item.backCtns && item.backCtns != "0"
                        ? Number(item.backCtns) + "箱"
                        : 0; // 青岛返场箱数
                data.cargoCtns =
                    item.cargoCtns && item.cargoCtns != "0"
                        ? Number(item.cargoCtns) + "箱"
                        : 0; //青岛集港箱数
                result.push(data);
            }
        });
    }
    return result;
};
// 实时查询提单详情
export const realTimeQueryDetail = async (blno, showTab) => {
    try {
        const boxListItem = await queryBoxList({
            blno,
            code: showTab,
            pageNum: 1,
            pageSize: 1,
        });
        const boxListItemData = boxListItem.data.data.list[0];
        if (!boxListItemData) {
            myNotification.warning("提单号不存在");
            return;
        }
        const detailBoxInfoRes = await queryDetailBoxInfo({
            id: boxListItemData?.id,
        });
        const detailBoxInfo = detailBoxInfoRes.data.data;
        const nodeBasicData = [
            {
                label: "提单号",
                value: `${detailBoxInfo.blno}（当前状态：${
                    // this.boxStatus[this.boxStatus.length - 1] ||
                    "boxStatus"
                }）`,
            },
            {
                label: "卸货港",
                value: `${detailBoxInfo.blnoInfo.dlPortCode || ""}`,
                blockNum: 10,

                blockLabel: `船名/航次:${
                    detailBoxInfo.blnoInfo.vesselsName || ""
                }/${detailBoxInfo.blnoInfo.voyage || ""}  ${
                    // this.shipStatus[this.shipStatus.length - 1] ||
                    "shipStatus"
                }`,
            },
        ];
        const gateData = detailBoxInfo.nppInGateEmptyVoList[0];
        const getManifestInfoData = detailBoxInfo.manifest;
        const collpaseData = gateData
            ? [
                  {
                      title: `进港   ${gateData.ctn.inGateTime ? "Y" : "N"} ${
                          detailBoxInfo.nppInGateEmptyVoList.length
                      }/${detailBoxInfo?.blnoInfo.nppCtnCount || "-"}箱   ${
                          gateData.ctn.inGateTime
                      }`,
                      data: [
                          {
                              label: "进门时间",
                              name: "inGateTime",
                              value: gateData.ctn.inGateTime,
                          },
                          {
                              label: "码头",
                              name: "wharf",
                              value: CheckNodeDetailHelpFn.getMatou(
                                  gateData.ctn.wharf,
                              ),
                          },
                          {
                              label: "集装箱号",
                              name: "ctnno",
                              value: gateData.ctn.ctnno,
                          },
                          {
                              label: "铅封号",
                              name: "sealno",
                              value: gateData.ctn.sealno,
                          },
                          {
                              label: "箱型",
                              name: "size",
                              value: gateData.ctn.size + gateData.ctn.type,
                          },
                          {
                              label: "件数",
                              name: "packageNum",
                              value: CheckNodeDetailHelpFn.getString2JsonArr(
                                  gateData.ctn.bills || gateData.npp.bills,
                              )[0].packageNum,
                          },
                          {
                              label: "货毛重",
                              name: "weight",
                              value:
                                  CheckNodeDetailHelpFn.getString2JsonArr(
                                      gateData.ctn.bills || gateData.npp.bills,
                                  )[0].weight || "",
                          },
                          {
                              label: "体积",
                              name: "measure",
                              value:
                                  CheckNodeDetailHelpFn.getString2JsonArr(
                                      gateData.ctn.bills || gateData.npp.bills,
                                  )[0].measure || "",
                          },
                      ],
                  },
                  {
                      title: `预配仓单 ${getManifestInfoData ? `Y` : ""} ${
                          detailBoxInfo?.blnoInfo.nppCtnCount || "-"
                      }箱 ${getManifestInfoData?.saveTime || "-"}`,
                      data: getManifestInfoData
                          ? [
                                {
                                    label: "状态时间",
                                    name: "saveTime",
                                    value: getManifestInfoData.saveTime,
                                },
                                {
                                    label: "关区",
                                    name: "portCodeText",
                                    value: `${getManifestInfoData.portCodeText}(${getManifestInfoData.portCode})`,
                                },
                                {
                                    label: "箱号/箱型",
                                    name: "ctnno",
                                    value: `${gateData.ctn.ctnno}/${
                                        gateData.ctn.size + gateData.ctn.type
                                    }`,
                                },
                                {
                                    label: "件数",
                                    name: "totalPackNum",
                                    value: getManifestInfoData.totalPackNum,
                                },
                                {
                                    label: "毛重",
                                    name: "totalGrossWt",
                                    value: getManifestInfoData.totalGrossWt,
                                },
                                {
                                    label: "预配状态",
                                    name: "signsTypeText",
                                    value: getManifestInfoData
                                        ? `${getManifestInfoData.signsTypeText}(${getManifestInfoData.listStatText})`
                                        : "",
                                },
                            ]
                          : [],
                  },
                  {
                      title: `海关查验 ${CheckNodeDetailHelpFn.checkCusmovIsCheck(
                          gateData.ctn.checkType,
                      )} ${gateData.ctn.checkTime || ""}`,
                      data: gateData.ctn.checkType
                          ? [
                                {
                                    label: "状态时间",
                                    name: "checkTime",
                                    value: gateData.ctn.checkTime || "-",
                                },
                                {
                                    label: "海关查验状态",
                                    name: "checkType",
                                    value: CheckNodeDetailHelpFn.checkCusmovIsCheck(
                                        gateData.ctn.checkType,
                                    ),
                                },
                            ]
                          : [],
                  },
                  {
                      title: `海关放行 ${gateData.ctn.isCustomPass} ${
                          gateData.ctn.customPassTime || ""
                      }`,
                      data: [
                          {
                              label: "状态时间",
                              name: "customPassTime",
                              value: gateData.ctn.customPassTime,
                          },
                          {
                              label: "海关放行状态",
                              name: "isCustomPass",
                              value: gateData.ctn.isCustomPass,
                          },
                      ],
                  },
                  {
                      title: `码头放行 ${gateData.npp.passFlag} ${
                          gateData.ctn.portPassTime || ""
                      }`,
                      data: [
                          {
                              label: "码头",
                              name: "wharf",
                              value: CheckNodeDetailHelpFn.wharfCodeToStr(
                                  gateData.ctn.wharf,
                              ),
                          },
                          {
                              label: "箱号",
                              name: "ctnno",
                              value: gateData.ctn.ctnno,
                          },
                          {
                              label: "清洁舱单",
                              name: "ifcsumFlag",
                              value: gateData.npp.ifcsumFlag,
                          },
                          {
                              label: "码头运抵",
                              name: "cpcodeFlag",
                              value: gateData.npp.cpcodeFlag,
                          },
                          {
                              label: "海放状态",
                              name: "customFlag",
                              value: gateData.npp.customFlag,
                          },
                          {
                              label: "三联单",
                              name: "sldFlag",
                              value: gateData.npp.sldFlag,
                          },
                          {
                              label: "码头放行",
                              name: "passFlag",
                              value: gateData.npp.passFlag,
                          },
                          {
                              label: "码头反馈",
                              name: "remark",
                              value: gateData.npp.remark,
                          },
                      ],
                  },
                  {
                      title: `装船 ${gateData.npp.compareFlag} ${
                          gateData.ctn.loadTime || ""
                      }`,
                      data: [
                          {
                              label: "装船时间",
                              name: "loadTime",
                              value: gateData.ctn.loadTime,
                          },
                          {
                              label: "码头",
                              name: "wharf",
                              value: CheckNodeDetailHelpFn.wharfCodeToStr(
                                  gateData.ctn.wharf,
                              ),
                          },
                          {
                              label: "箱号",
                              name: "ctnno",
                              value: gateData.ctn.ctnno,
                          },
                      ],
                  },
                  {
                      title: `离港 ${gateData.npp.sendFlag} ${
                          gateData.ctn.departureTime || ""
                      }`,
                      data: [
                          {
                              label: "离港时间",
                              name: "departureTime",
                              value: gateData.ctn.departureTime,
                          },
                          {
                              label: "码头",
                              name: "wharf",
                              value: CheckNodeDetailHelpFn.wharfCodeToStr(
                                  gateData.ctn.wharf,
                              ),
                          },
                          {
                              label: "箱号",
                              name: "ctnno",
                              value: gateData.ctn.ctnno,
                          },
                      ],
                  },
              ]
            : [];
        const activeIndex = 7;
        const subscribeData = {
            blno: detailBoxInfo.blno,
            vesselsName: detailBoxInfo.vessels.vesselsName,
            voyage: detailBoxInfo.vessels.voyage,
        };
        return {
            nodeBasicData,
            collpaseData,
            activeIndex,
            subscribeData,
        };
    } catch (err) {
        return false;
    }
};

//解决el-autocomplete 点击clearable后再输入无法显示建议列表的bug
export const blurForBug = () => {
    document.activeElement.blur();
};

//判断时间是否小于设置的时间(之前这个功能有问题，新数据是正常的)
function judgeTimeclose(time, markHour, isDepature) {
    const isDepatureValue = isDepature && isDepature !== "-";
    var date = new Date();
    var enddate = new Date(time);
    if (enddate > date) {
        return (enddate - date) / 1000 / 3600 < markHour;
    } else {
        return !isDepatureValue;
    }
}

// 获取cell的class
export const getCellClass = (
    { row, column, rowIndex, columnIndex },
    showTab,
) => {
    if (column.property == "billNo") {
        // 提单号发生过换船
        if (row.changeVessels === "T") {
            return "changeTag";
        }
    }
    if (column.property) {
        //若有查验底色,若码放显示不能放行，取消放行等信息，若临近截关期或截港期，底色显示粉色
        if (
            (column.property === "nppStatus" &&
                row.boxCusmov &&
                row.nppStatus !== "Y") ||
            (column.property === "nppStatus" &&
                row.nppStatus &&
                row.nppStatus != "Y" &&
                ((["QD", "SH", "TJ"].includes(showTab) &&
                    row.changeLogs.length > 0) ||
                    ["NB", "XM"].includes(showTab))) ||
            (column.property === "closeTime" &&
                row.closeTime &&
                judgeTimeclose(
                    row.closeTime,
                    store.state.ship.portCloseTime,
                    row.departureTime,
                )) ||
            (column.property === "portCloseTime" &&
                row.portCloseTime &&
                judgeTimeclose(
                    row.portCloseTime,
                    store.state.ship.portCloseTime,
                    row.departureTime,
                )) ||
            (column.property === "ctnApplyEndTime" &&
                row.ctnApplyEndTime &&
                judgeTimeclose(
                    row.ctnApplyEndTime,
                    store.state.ship.ctnApplyEndTime,
                    row.departureTime,
                )) ||
            (column.property === "errorValue" &&
                ((showTab === "NB" && row.errorValue >= 5) ||
                    (showTab === "XM" &&
                        row.errorValue &&
                        row.errorValue != "true"))) ||
            (column.property === "jgApplyEndTime" &&
                row.jgApplyEndTime &&
                judgeTimeclose(
                    row.jgApplyEndTime,
                    store.state.ship.portCloseTime,
                    row.departureTime,
                )) ||
            // NB进港舱单不一致标成红色
            (showTab === "NB" &&
                column.property === "boxArrivalCount" &&
                row.boxArrivalCount &&
                row.boxArrivalCount.includes("/") &&
                row.boxArrivalCount.split("/")[0].trim() !==
                    row.boxArrivalCount.split("/")[1].trim())
        ) {
            return "pink_cell";
        }
        if (column.property === "nppStatus") {
            return "no-overflow-hidden";
        }
    }
};
