<template>
    <ShipDialog
        :showTab="showTab"
        width="500px"
        size="small"
        :visible.sync="visibleSync"
        title="异常反馈"
        imgType="feeback"
    >
        <div
            style="
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                margin-top: 20px;
                flex-direction: column;
            "
        >
            <el-timeline style="width:100%">
                <el-timeline-item
                    :timestamp="item.updateTime"
                    placement="top"
                    icon="el-icon-more"
                    color="#ff0000"
                    v-for="item in errorList"
                    :key="item.id"
                >
                    <el-card v-for="item in errorList"
                    :key="item.id">
                        <h4>回复:{{ item.reply||'暂无' }}</h4>
                        <p>异常描述:{{ item.problem }}</p>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
            <el-input
                id="feedback-input"
                v-model="content"
                placeholder="请描述本票异常，我们将尽快处理"
                type="textarea"
                :autosize="{ minRows: 4 }"
            ></el-input>
        </div>
        <DialogFooter
            @confirm="handleTuConfirm"
            @cancel="handleCancel"
            :loading="loading"
            :showTab="showTab"
            confirmText="提交"
            top="50"
        ></DialogFooter>
    </ShipDialog>
</template>
<script>
import ShipDialog from "@/components/layout/ShipDialog.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import { efforFeedback, efforFeedbackResult } from "@/api/ship";
export default {
    name: "ChangeShipDialog",
    props: ["showTab"],
    data() {
        return {
            visibleSync: false,
            id: 0,
            loading: false,
            content: "",
            blno: "",
            isHasFeedback: false, // 是否有提交过问题反馈
            isColloase: true,
            errorList: [],
        };
    },
    components: {
        ShipDialog,
        DialogFooter,
    },
    methods: {
        loadData(id){
            efforFeedbackResult({ blnoId: id }).then((res) => {
                // this.content = res.data;
                // console.log(res,'res')
                const data = (res.data?.data)||{};
                if (data.id) {
                    this.isHasFeedback = true;
                    this.errorList = [data];
                } else {
                    this.isHasFeedback = false;
                    this.errorList = [];
                }
            });
        },
        open(item) {
            const { id, blno } = item;
            this.id = id;
            this.blno = blno;
            this.content = "";
            this.visibleSync = true;
            this.isHasFeedback = false;
            this.errorList = [];
            this.loadData(id);
        },
        handleCancel() {
            this.visibleSync = false;
        },
        handleTuConfirm() {
            this.loading = true;
            if (this.content) { 
                efforFeedback({
                    blnoId: this.id,
                    problem: this.content,
                    blno: this.blno,
                    code:this.showTab
                })
                    .then(() => {
                        this.$message.success("异常反馈成功");
                        this.visibleSync = false;
                        this.$emit("confirm", this.content);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }else{
                this.$message.error("请填写异常描述");
                this.loading = false;
            }
        },
    },
};
</script>
<style></style>
