<template>
    <div class="npp-status-cell-container">
        <!-- 码放已放行则不展示查验 -->
        <template v-if="!rowData.boxCusmov || (rowData.boxCusmov && rowData.nppStatus === 'Y')">
            <el-row
                class="npp-status-cell"
                v-if="['NB', 'XM'].includes(showTab)"
                type="flex"
                align="middle"
                justify="center"
            >
                <el-tooltip
                    effect="dark"
                    :disabled="!rowData.wharfRemark"
                    :content="rowData.wharfRemark"
                    placement="left"
                >
                    <span
                        :class="[
                            {
                                p_green:
                                    rowData.nppStatus ===
                                    'Y',
                            },
                            {
                                p_red:
                                    rowData.nppStatus ===
                                    'N',
                            },
                        ]"
                    >
                        {{
                            passFlag2String(
                                rowData.nppStatus,
                                showTab === "XM",
                            )
                        }}
                    </span>
                </el-tooltip>
                <el-tooltip
                    v-if="
                        showTab === 'NB' &&
                        rowData.nppStatus !== 'Y'
                    "
                    content="手动刷新"
                    placement="right"
                    effect="dark"
                >
                    <MyIcon
                        v-if="!manualRefreshLoading"
                        icon="mingcute:refresh-3-fill"
                        :size="16"
                        :clickable="true"
                        theme="primary"
                        @click="
                            handleManualRefresh(rowData)
                        "
                    ></MyIcon>
                    <div v-else class="loading-placeholder" v-loading="manualRefreshLoading"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="transparent"
                    ></div>
                </el-tooltip>
            </el-row>
            <el-tooltip
                class="item"
                effect="light"
                :disabled="
                    !rowData.changeLogs ||
                    rowData.changeLogs.length <= 1
                "
                v-else-if="['SH'].includes(showTab)"
            >
                <el-badge
                    :hidden="
                        !rowData.changeLogs ||
                        rowData.changeLogs.length <= 1
                    "
                    :value="
                        rowData.changeLogs &&
                        rowData.changeLogs.length > 1
                            ? rowData.changeLogs.length
                            : 0
                    "
                    class="is-dot"
                >
                    <span
                        :class="[
                            {
                                p_green:
                                    rowData.nppStatus ===
                                        'Y' &&
                                    (!rowData.changeLogs ||
                                        rowData.changeLogs
                                            .length <= 1),
                            },
                            {
                                p_blue:
                                    rowData.nppStatus ===
                                        'Y' &&
                                    rowData.changeLogs &&
                                    rowData.changeLogs
                                        .length > 1,
                            },
                        ]"
                    >
                        {{
                            passFlag2String(
                                rowData.nppStatus,
                                true,
                            )
                        }}
                    </span>
                </el-badge>
        
                <template slot="content">
                    <div style="padding: 0 20px">
                        <h3>码放变更记录：</h3>
                        <el-timeline>
                            <el-timeline-item
                                v-for="(
                                    activity, index
                                ) in rowData.changeLogs"
                                :key="index"
                                :timestamp="
                                    activity.createTime
                                "
                            >
                                <div>
                                    <div
                                        class="changeLog-item-value"
                                    >
                                        {{
                                            passFlag2String(
                                                activity.nppStatus,
                                                true,
                                            )
                                        }}
                                    </div>
                                    <div
                                        v-if="
                                            activity.nppStatus &&
                                            activity.nppStatus !==
                                                'Y' &&
                                            activity.descs
                                        "
                                        class="changeLog-item-desc"
                                    >
                                        ({{
                                            activity.descs
                                        }})
                                    </div>
                                </div>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </template>
            </el-tooltip>
        </template>
        <template v-else-if="rowData.boxCusmov">
            <el-row
                type="flex"
                align="middle"
                justify="center"
            >
                <span>
                    {{ rowData.boxCusmov }}
                </span>
                <el-image
                    :src="tootipIcon"
                    class="tIcon_style"
                    @click="
                        openWindow(boxCusmovDoc)
                    "
                ></el-image>
            </el-row>
        </template>
    </div>
</template>

<script>
import MyIcon from "@/components/common/MyIcon.vue";
import { passFlag2String } from "@/utils";
import { manualRefresh } from "@/api/boxQuery";
export default {
    name: 'NppStatusCell',
    components: {
        MyIcon
    },
    props: {
        rowData: {
            type: Object,
            default: () => {}
        },
        showTab: {
            type: String,
            default: 'NB'
        }
    },
    data() {
        return {
            passFlag2String,
            manualRefreshLoading: false, // 手动刷新loading
            tootipIcon: require("@/assets/img/boxCusmovTootipIcon.png"),
            boxCusmovDoc:
                "https://web.nbhuojian.com/static/fangcangwang/%E6%9F%A5%E9%AA%8C%E7%A7%8D%E7%B1%BB%E8%AF%B4%E6%98%8E.doc",
        }
    },
    methods: {
        // 手动刷新
        handleManualRefresh(row) {
            if (this.manualRefreshLoading) {
                this.$message.warning("刷新频率过快，请稍后再试");
                return;
            }
            this.manualRefreshLoading = true;
            manualRefresh({
                blno: row.blno,
            })
                .then(() => {
                    this.$message.success(`提单号：${row.blno}刷新成功`);
                    this.$emit('refresh');
                })
                .finally(() => {
                    this.manualRefreshLoading = false;
                });
        },
    }
}
</script>

<style lang="stylus" scoped>
.p_green {
  color: var(--GREEN-1E9855)
}

.p_blue{
    color:link-text-color-primary
}

.p_red {
  color: #F56C6C;
}

.npp-status-cell {
    height: 100%;
    /deep/ .el-loading-spinner {
        margin-top: 0;
        transform: translateY(-50%);
    }
    .loading-placeholder {
        width: 20px;
        height: 20px;
    }
}
.tIcon_style{
    width:20px;
    height:20px;
    margin-left:10px;
}
</style>