<template>
    <div style="width:100%;flex-grow:1;display: flex;justify-content: center;">
        <div class="container" :class="handleThisClass()">
        <!-- <img :src="placeSvg" alt="" style="width: 14px; opacity: 0.7" /> -->
        <div class="font">{{ handleThisName() }}</div>
    </div>
    </div>
</template>
<script>
import {getNameByCode} from "@/utils"
export default {
    name: "PlaceShow",
    props: {
        name: {
            type: String,
            default: 'NB',
        },
    },
    data() {
        return {
            placeSvg: require("@/assets/img/tab/place.svg"),
        };
    },
    methods: {
        handleThisClass() {
            return this.name.toLowerCase() + "-place";
        },
        handleThisName() {
            return getNameByCode(this.name);
        },
    },
};
</script>
<style scoped>
.container {
    width: 60px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    gap: 3px;
    padding: 2px 0;
    color: #e9f4ff;
}

.nb-place {
}
.sh-place {
}

.qd-place{
}

.tj-place{
}

.xm-place{

}

.sz-place{
}
.font {
    color: #000000;
    font-weight: normal;
    font-size: 13px;
    user-select: none;
}
</style>
