<template>
    <div class="box_div back_div" id="logistics-detail-manifest">
        <p class="info_title" @click="handleTitleClick">预配舱单</p>
        <div>
            <el-table
                border
                :data="manifest"
                style="width: 100%"
                :cell-class-name="cellClass"
                :header-cell-style="{
                    background: '#eef1f6',
                    color: '#606266',
                }"
            >
                <el-table-column prop="blno" label="提运单号" align="center"></el-table-column>
                <el-table-column prop="ctnInfo" label="箱号 / 箱型" align="center">
                    <template slot-scope="scope">
                        <div v-for="ctn in scope.row.ctnInfo" :key="ctn[0]">{{ `${ctn[0]} / ${ctn[1]}` }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="portCode" label="关区" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.portCodeText ? `${scope.row.portCodeText}(${scope.row.portCode})` : '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="listStatText"
                    label="状态"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="totalPackNum"
                    label="件数"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="totalGrossWt"
                    label="毛重"
                    align="center"
                ></el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import { preentrydata1 } from "@/utils/dataList";
export default {
    name: "DetailContentManifest",
    props: {
        boxInfo: {
            type: Object,
            default: () => {},
        },
        activePosition: {
            type: Number,
        },
        showTab: {
            type: String,
        },
        errorFlag: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            manifest: [],
        };
    },
    watch: {
        activePosition: {
            handler(val) {
                this.init();
            },
            deep: true,
            immediate: true,
        },
        boxInfo: {
            handler(val) {
                this.init();
            },
            deep: true,
        },
    },
    methods: {
        handleTitleClick() { 
            document.querySelector('#logistics-detail-manifest').scrollIntoView();
        },
        init() {
            this.manifest = [...preentrydata1];
            if (this.boxInfo.manifest) {
                const { blno, ctnType = '', ctnNo = '', signsTypeText, listStatText, totalGrossWt, totalPackNum, portCode, portCodeText } = this.boxInfo.manifest
                this.manifest[0].blno = blno || "-";
                const ctnTypeArr = ctnType ? ctnType.split(',') : []
                this.manifest[0].ctnInfo = ctnNo ? ctnNo.split(',').reduce((acc, cur, index) => {
                    acc.push([cur, ctnTypeArr[index]])
                    return acc
                }, []) : []
                this.manifest[0].listStatText = this.boxInfo?.manifest
                    ?.signsTypeText
                    ? (signsTypeText || "-") +
                    "(" +
                    (listStatText || "-") +
                    ")"
                    : "-";
                this.manifest[0].totalGrossWt = totalGrossWt || "-";
                this.manifest[0].totalPackNum =
                    totalPackNum || "-";
                this.manifest[0].portCode = portCode || ''
                this.manifest[0].portCodeText = portCodeText || ''
                // this.manifest[0].measure = "-";
            } else { 
                this.manifest = []
            }
        },

        cellClass({ column }) {
            if (column.label && column.label == "毛重") {
                if (this.errorFlag == 1) {
                    return "pink_cell";
                }
            }
        },
    },
};
</script>
<style lang="stylus" scoped>
.detail_content_manifest {
    font-size: 14px;
}
.back_div {
    background: #f1f3f6;
    border-radius: 4px;
    box-shadow: inset 0 0 15px rgba(55, 84, 170, 0),
        inset 0 0 20px rgba(255, 255, 255, 0),
        10px 10px 18px rgba(55, 84, 170, 0.15), -10px -10px 22px white,
        inset 0px 0px 4px rgba(255, 255, 255, 0.2);
    transition: box-shadow 0.3s ease-in-out;
    outline: none;
    border: none;
}
.back_div:hover .draw {
    opacity: 0.4;
}

.passIcon {
    width: 3rem;
    height: 3rem;
    background-size: 100% 100%;
}

.back_div:hover .prints {
    opacity: 1;
    transition: opacity ease-in-out 0.8s;
}

.info_title {
    display: block;
    width: fit-content;
    font-weight: 700;
    margin: 0 0 1rem;
    color: #409EFF;
    cursor: pointer;
    a {
        user-select: none;
    }
}

.info_title:before {
    content: "";
    margin-top: 12px;
    margin-right: 1rem;
    border-left: 2px solid #409EFF;
}
</style>
