<template>
    <div class="blno-main-cell">
        <el-link
            v-if="!rowData.blnoMain"
            @click="addBlnoMain(rowData)"
            type="primary"
            class="table-cell-link"
        >
            添加舱单主单号
        </el-link>
        <div v-else style="padding: 0 4px">
            <span>
                <i
                    @click="
                        addBlnoMain(rowData)
                    "
                    class="el-icon-edit"
                ></i>
                {{ `${rowData.blnoMain}` }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlnoMainCell',
    props: {
        rowData: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        addBlnoMain(rowData) {
            this.$emit('add-blno-main', rowData);
        }
    }
}
</script>

<style lang="stylus" scoped>
.blno-main-cell {
    padding: 0 8px;
}
</style>



