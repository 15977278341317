var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abroad-move-container"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"abroad-move"},[_c('div',{staticClass:"bill flex-align-center"},[_c('div',{staticClass:"flex-align-center bill-line"},[_c('div',{staticClass:"flex-align-center",staticStyle:{"gap":"5px"}},[_c('i',{staticClass:"el-icon-tickets icon-primary",staticStyle:{"margin-left":"5px"}}),_vm._v(" "+_vm._s(_vm.curData.blno)+" ")])])]),_c('div',{staticClass:"flex-align-center detail-header-line"},[_c('div',{staticClass:"flex-align-center",staticStyle:{"gap":"20px"}},[(_vm.curData.shipCode)?_c('img',{staticStyle:{"width":"50px"},attrs:{"src":_vm.getShipComanyLogo(_vm.curData.shipCode)}}):_vm._e(),_c('div',{staticClass:"flex-align-center",staticStyle:{"min-width":"420px","flex":"1"}},[_c('div',[_vm._v(" "+_vm._s(_vm.curData.shippedFrom)+" ")]),(_vm.curData.shippedFrom || _vm.curData.shippedTo)?_c('div',{staticStyle:{"flex":"1","display":"flex","flex-direction":"column","align-items":"center"}},[_c('div',[_vm._v(" "+_vm._s(_vm.curData.vesselsName)+" ")]),_vm._m(0),_c('div',[_vm._v(" "+_vm._s(_vm.curData.voyage)+" ")])]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.curData.shippedTo)+" ")])]),_c('div',{staticClass:"flex-align-center",staticStyle:{"gap":"5px","min-width":"250px","margin-left":"20px"}},[_c('i',{staticClass:"el-icon-location-outline icon-font"}),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","gap":"5px"}},[_c('div',{staticClass:"desp"},[_vm._v("最新节点")]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.latestMove.nodeLocationCn || ( _vm.latestMove.nodeLocation || "" ).toUpperCase() || "-")+" "),(
                                    _vm.latestMove.nodeActionCn ||
                                    _vm.latestMove.nodeAction
                                )?_c('span',[_vm._v(" ("+_vm._s(_vm.latestMove.nodeActionCn || _vm.latestMove.nodeAction)+") ")]):_vm._e()]),_c('div',{staticClass:"desp"},[_vm._v(" "+_vm._s(_vm.latestMove.nodeDate ? _vm.formatTime(_vm.latestMove.nodeDate) : "")+" ")])])]),_c('div',{staticClass:"flex-align-center",staticStyle:{"gap":"5px"}},[_c('i',{staticClass:"el-icon-box",staticStyle:{"width":"50px","height":"50px","font-size":"50px","color":"#4b4b4b"}}),_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"desp"},[_vm._v(" 集装箱信息 "),(
                                    _vm.curData.returnEmptyCtns &&
                                    _vm.curData.returnEmptyCtns == _vm.curData.ctns
                                )?_c('img',{staticStyle:{"width":"12px"},attrs:{"src":_vm.icon_green}}):_vm._e()]),_c('div',{staticClass:"desp content"},[_vm._v(" 已还空:"+_vm._s(_vm.curData.returnEmptyCtns)+" ")]),_c('div',{staticClass:"desp content"},[_vm._v(" 共计:"+_vm._s(_vm.curData.ctns)+" ")])])])])])]),_c('div',{staticStyle:{"margin-top":"20px"}},[(_vm.curData.ctnsList && _vm.curData.ctnsList.length > 0)?_c('el-tabs',{attrs:{"type":"border-card"},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.curData.ctnsList),function(item){return _c('el-tab-pane',{key:item.id,attrs:{"label":`${item.ctnNo}`,"name":item.ctnNo}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"timeline"},[(item.nodes && item.nodes.length > 0)?_c('ul',{staticClass:"el-timeline"},_vm._l(([...item.nodes]),function(citem,index){return _c('li',{key:citem.nodeId,staticClass:"el-timeline-item",attrs:{"id":citem.nodeId}},[_c('div',{staticClass:"port-name-line"},[(_vm.needShowPlacePort[index])?_c('span',{staticClass:"port-name",class:citem.nodeStatus === 1
                                            ? ''
                                            : 'color-gray'},[_vm._v(" "+_vm._s(_vm.showLocation(citem))+" ")]):_vm._e()]),_c('div',{staticClass:"el-timeline-item__tail",class:citem.nodeStatus == 0
                                        ? 'border-dashed'
                                        : 'border-solid'}),(_vm.needShowPlacePort[index])?_c('div',{staticClass:"location-line"},[_c('i',{staticClass:"el-icon-location-outline location-icon",class:citem.nodeStatus == 1
                                            ? 'color-primary'
                                            : ''})]):_c('div',{staticClass:"el-timeline-item__node el-timeline-item__node--normal",class:citem.nodeStatus == 1
                                        ? 'el-timeline-item__node--primary'
                                        : ''}),_c('div',{staticClass:"el-timeline-item__wrapper"},[_c('div',{staticClass:"el-timeline-item__content",class:citem.nodeStatus === 1
                                            ? ''
                                            : 'color-gray',staticStyle:{"font-weight":"bold","font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.showAction(citem))+" ")]),_c('div',{staticClass:"el-timeline-item__timestamp is-bottom",class:citem.nodeStatus === 1
                                            ? 'color-normal'
                                            : 'color-gray'},[_vm._v(" "+_vm._s(_vm.formatTime(citem.nodeDate))+" ")]),_c('div',{staticClass:"el-timeline-item__timestamp is-bottom",class:citem.nodeStatus === 1
                                            ? ''
                                            : 'color-gray'},[_vm._v(" "+_vm._s(_vm.showNodeDesc(citem))+" ")])])])}),0):_c('el-empty',{attrs:{"description":"暂无节点数据"}})],1)])}),1):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"arrow-container",staticStyle:{"width":"100%","padding":"5px 10px"}},[_c('span',{staticClass:"circle"}),_c('span',{staticClass:"line"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/ship/icon-ship.svg"),"alt":""}})]),_c('span',{staticClass:"triangle"})])
}]

export { render, staticRenderFns }