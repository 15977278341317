<template>
    <div style="height: 100%">
        <!-- <div class="ad-wrap">
        <div class="ad-image-bg"></div>
    </div> -->
        <TopBanner :showTab="showTab">
            <div class="search_div_father">
                <common-header :title="`${showTabTitle}港箱货跟踪`">
                    <div
                        v-if="tabLayout === 'vertical'"
                        slot="header"
                        style="margin-bottom: 8px"
                    >
                        <TabBarGroup
                            :value="showTab"
                            @change="showTabClick"
                            type="blno"
                        ></TabBarGroup>
                    </div>
                    <div class="input_div_father" slot="content">
                        <div
                            v-if="tabLayout === 'horizontal'"
                            style="margin-right: 20px"
                        >
                            <TabBarGroup
                                :value="showTab"
                                @change="showTabClick"
                                type="blno"
                            ></TabBarGroup>
                        </div>
                        <div class="input_div" v-if="showTab === 'QD'">
                            <el-select
                                placeholder="请选择场站"
                                v-model="webName"
                                filterable
                            >
                                <el-option
                                    v-for="item in webNameList"
                                    :key="item"
                                    :label="luhaitongFilter(item)"
                                    :value="item"
                                ></el-option>
                            </el-select>
                        </div>
                        <div class="input_div" v-if="showTab === 'XM'">
                            <el-select
                                placeholder="请选择码头"
                                v-model="XMWharf"
                                filterable
                            >
                                <el-option
                                    v-for="item in XMWharfList"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                ></el-option>
                            </el-select>
                        </div>
                        <div class="input_div">
                            <el-autocomplete
                                clearable
                                @clear="blurForBug()"
                                class="input-with-select"
                                v-model="vesselsName"
                                @input="checkInputVesselename()"
                                @change="checkInputVesselename2(vesselsName)"
                                :fetch-suggestions="shipSearch"
                                placeholder="请输入船名"
                                :trigger-on-focus="false"
                                @select="handleShipSelect"
                                style="font-size: 12px"
                                v-if="showTab !== 'SZ'"
                            >
                                <template slot-scope="{ item }">
                                    <!-- <el-tooltip style="font-size:12px" class="item" effect="dark" :content="item.value" placement="bottom" open-delay="600"> -->
                                    <div
                                        style="
                                            font-size: 12px;
                                            display: block;
                                            overflow: hidden;
                                            word-break: keep-all;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                        "
                                        class="name"
                                    >
                                        {{ item.value }}
                                    </div>
                                    <!-- </el-tooltip> -->
                                </template>
                            </el-autocomplete>
                            <el-input
                                clearable
                                placeholder="请输入航次"
                                v-model="voyage"
                                @input="checkInputVoyage()"
                                class="query-input_center"
                                v-if="showTab !== 'SZ'"
                            ></el-input>
                            <el-input
                                placeholder="请输入提单号（必填）"
                                v-model="blno"
                                maxlength="30"
                                v-if="showTab !== 'SZ'"
                                @input="checkInputBlno"
                                @keyup.enter.native="getAllBoxWatch()"
                                class="query-input_right"
                            ></el-input>
                            <el-input
                                placeholder="请输入S/O号（可订阅南沙港）"
                                v-model="blno"
                                maxlength="30"
                                v-if="showTab === 'SZ'"
                                style="width: 450px"
                                class="sz-input"
                                @input="checkInputBlno"
                                @keyup.enter.native="getAllBoxWatch()"
                            ></el-input>
                            <div
                                class="model_opt_flag_wrap"
                                v-if="showTab !== 'SZ'"
                            >
                                <el-radio-group
                                    v-model="modelOptflag"
                                    size="small"
                                >
                                    <el-radio label="0">整柜</el-radio>
                                    <el-radio label="1">拼票</el-radio>
                                </el-radio-group>
                            </div>
                            <div
                                class="model_opt_flag_wrap"
                                style="padding-right: 10px"
                                v-if="isExperience"
                            >
                                <el-checkbox v-model="isAbroad">
                                    海外运踪
                                </el-checkbox>
                            </div>
                            <el-popover
                                placement="top"
                                trigger="manual"
                                v-model="repeatPopVisible"
                            >
                                <div class="repeat_pop_content">
                                    {{ repeatPopTitle }}
                                </div>
                                <template #reference>
                                    <el-tooltip
                                        effect="dark"
                                        placement="top"
                                        v-if="showTab === 'SH'"
                                    >
                                        <template #content>
                                            <div class="watch-tooltip-message">
                                                当前订阅为上海提单，每票消耗1火币
                                            </div>
                                        </template>
                                        <el-button
                                            :loading="single_add_loading"
                                            class="query-input_btn"
                                            @click="getAllBoxWatch()"
                                            size="small"
                                        >
                                            添加跟踪 ({{ showTabTitle }})
                                        </el-button>
                                    </el-tooltip>
                                    <el-button
                                        v-else
                                        :loading="single_add_loading"
                                        class="query-input_btn"
                                        @click="getAllBoxWatch()"
                                        size="small"
                                    >
                                        添加跟踪 ({{ showTabTitle }})
                                    </el-button>
                                </template>
                            </el-popover>
                        </div>
                        <el-tooltip
                            effect="dark"
                            placement="top"
                            v-if="showTab === 'SH'"
                        >
                            <template #content>
                                <div class="watch-tooltip-message">
                                    当前订阅为上海提单，每票消耗1火币
                                </div>
                            </template>
                            <el-button
                                :loading="multi_add_loading"
                                type="primary"
                                class="query-input_btn multi"
                                @click="uploadHandle()"
                            >
                                批量跟踪 ({{ showTabTitle }})
                            </el-button>
                        </el-tooltip>
                        <el-button
                            v-else-if="showTab !== 'SZ'"
                            :loading="multi_add_loading"
                            type="primary"
                            class="query-input_btn multi"
                            @click="uploadHandle()"
                        >
                            批量跟踪 ({{ showTabTitle }})
                        </el-button>
                        <!-- <el-button
                            type="primary"
                            class="query-input_btn multi"
                            @click="showDetail()"
                        >
                            提单详情
                        </el-button> -->
                    </div>
                    <div slot="footer" class="header_footer">
                        <img src="@/assets/icon/warning.svg" />
                        <div style="font-weight: bold">
                            物流跟踪及订阅，数据每半个小时更新一次
                            <span v-if="showTab === 'NB'">
                                ，需要实时查询，请用
                                <span class="go_boxquery" @click="goBoxQuery">
                                    物流可视化
                                </span>
                                页面，谢谢！
                            </span>
                            <span v-else-if="showTab === 'SZ'">
                                (可订阅南沙港)
                            </span>
                        </div>
                        <!-- <div style="font-weight: bold;" v-else>
                            深圳订阅功能升级改造中，暂不支持新增订阅
                        </div> -->
                    </div>
                </common-header>
            </div>
        </TopBanner>

        <div class="data_div">
            <TableSearchHeader ref="tableSearchHeaderRef" :showTab="showTab" :tableList="tableList" :fixTableHeader="fixTableHeader" 
            :isExperience="isExperience"
            @delete="deleteBoxList" @search="searchBoxList" @wxPushSetting="showSettingDialog" @changeFixHeader="handleChangeFixHeader"/>
            <div class="table_div">
                <el-tooltip placement="top" :content="optionColumnVisible ? '收起操作列' : '展开操作列'">
                    <div class="expand-btn" @click="() => handleOptionColumnCollapse(false)">
                        <img :src="!optionColumnVisible ? expandIcon : collapseIcon" alt="expand">
                    </div>
                </el-tooltip>
                <el-table
                    border
                    v-loading="loading"
                    ref="multipleTable"
                    row-key="id"
                    :key="tableKey"
                    :cell-class-name="(option) => getCellClass(option, showTab)"
                    :cell-style="cellCss"
                    :data="boxList"
                    style="width: 100%"
                    :max-height="tableMaxHeight"
                    @selection-change="handleSelectionChange"
                    @header-dragend="handleHeaderChange"
                    :default-sort="{ prop: 'date', order: 'descending' }"
                    :highlight-selection-row="true"
                    :header-cell-style="{
                        background: '#eef1f6',
                        color: '#606266',
                    }"
                    :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
                >
                    <el-table-column
                        type="selection"
                        width="30"
                        fixed="left"
                        align="center"
                    ></el-table-column>
                    <el-table-column type="index" width="30" fixed="left" align="center">
                        <template slot-scope="scope">
                            <p
                                :style="{
                                    minHeight:
                                        boxList.length < 5 ? '40px' : 'auto',
                                    lineHeight:
                                        boxList.length < 5 ? '40px' : 'auto',
                                }"
                            >
                                {{ scope.row.index }}
                            </p>
                        </template>
                    </el-table-column>
                    <template v-for="(item, index) in showTableList">
                        <el-table-column
                            v-if="item.show && isFixedColumn(item.prop)"
                            :prop="item.prop"
                            :label="item.label"
                            :width="item.width"
                            :key="item.prop"
                            :fixed="item.fixed"
                            :align="item.align || 'center'"
                        >
                            <template slot-scope="scope">
                                <PlaceShow v-if="item.prop === 'area'" :name="showTab" />
                                <BlnoCell
                                    v-if="item.prop === 'billNo'"
                                    :row-data="scope.row"
                                    :row-index="scope.$index"
                                    :show-list="showList"
                                    :box-list="boxList"
                                    @toggle-show-list="handleToggleShowList"
                                    @feedback="errorCallback"
                                />
                                <ShipInfoCell v-if="item.prop === 'shipInfo'" :row-data="scope.row" :show-tab="showTab" @add-voyage="addVoyage"/>
                                <BlnoMainCell v-if="item.prop === 'blnoMain'" :row-data="scope.row" @add-blno-main="addBlnoMain"/>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="
                                item.show &&
                                item.prop !== 'nppStatus' &&
                                item.prop !== 'lastestStatus' &&
                                item.prop !== 'changeVessels' &&
                                item.prop !== 'speedType' &&
                                item.prop !== 'nodeGroup' &&
                                !isFixedColumn(item.prop)
                            "
                            show-overflow-tooltip
                            :prop="item.prop"
                            :sortable="item.sortable"
                            :label="item.label"
                            :width="item.width"
                            :key="item.prop"
                            :resizable="item.resizable"
                            :fixed="item.fixed"
                            :align="item.align || 'center'"
                        >
                            <template slot-scope="scope">
                                <span
                                    v-if="
                                        [
                                            'boxArrivalManifest',
                                            'boxArrivalCount',
                                        ].includes(item.prop)
                                    "
                                    class="p_gold"
                                >
                                    {{ scope.row[item.prop] }}
                                </span>

                                <OwnerCtnColumn
                                    :row="scope.row"
                                    v-else-if="item.prop === 'ctnOwner'"
                                    :showTab="showTab"
                                />

                                <span
                                    v-else-if="item.prop === 'wharf'"
                                    id="addWharfGuideCell"
                                >
                                    <span>
                                        {{
                                            wharfCodeToStr(scope.row[item.prop])
                                        }}
                                    </span>
                                </span>
                                <YGTWharfEdit
                                    v-else-if="item.prop === 'wharfCode'"
                                    :row="scope.row"
                                    :data="scope.row[item.prop]"
                                    :nameOptions="{
                                        wharfName: 'wharf',
                                        vesselsName: 'vesselsName',
                                        voyageName: 'voyage',
                                    }"
                                    @confirm="getBoxListByPage"
                                ></YGTWharfEdit>
                                <RemarkCell
                                    v-else-if="item.prop === 'remark'"
                                    :row-data="scope.row"
                                    @edit="addRemark"
                                ></RemarkCell>
                                <empty-takeout
                                    v-else-if="item.prop === 'emptyOutCount'"
                                    :rowData="scope.row"
                                ></empty-takeout>
                                <span
                                    v-else-if="item.prop === 'errorValue'"
                                    class="icon_wrapper"
                                >
                                    <div v-if="showTab === 'NB'">
                                        <i
                                            v-if="scope.row[item.prop] == 0"
                                            class="el-icon-circle-check custom-icon icon-green"
                                        ></i>
                                        <p v-else>
                                            {{
                                                scope.row[item.prop]
                                                    ? `${scope.row[item.prop]}%`
                                                    : ""
                                            }}
                                        </p>
                                    </div>
                                </span>
                                <!-- 上海,厦门进港舱单比对 -->
                                <span
                                    v-else-if="item.prop === 'errorValueCheck'"
                                    class="icon_wrapper"
                                >
                                    <i
                                        v-if="scope.row.errorValue === 'true'"
                                        class="el-icon-circle-check custom-icon icon-green"
                                    ></i>
                                    <el-popover
                                        v-else-if="
                                            scope.row.errorValue !== null
                                        "
                                        trigger="hover"
                                        placement="top"
                                        popper-class="error-popover"
                                        :content="scope.row.errorValue"
                                    >
                                        <i
                                            slot="reference"
                                            class="el-icon-circle-close custom-icon icon-red"
                                        ></i>
                                    </el-popover>
                                </span>
                                <truck-no-add
                                    v-else-if="item.prop === 'boxPosition'"
                                    :rowData="scope.row"
                                    :cellProp="item.prop"
                                    @change="getBoxListByPage"
                                    @login="login"
                                ></truck-no-add>
                                <span v-else-if="item.prop.includes('Time')">
                                    {{ formatTime(scope.row[item.prop]) }}
                                </span>
                                <span
                                    v-else-if="
                                        item.prop === 'preFlag' ||
                                        item.prop === 'cpcodeFlag' ||
                                        item.prop === 'ifcsumFlag' ||
                                        item.prop === 'sldFlag' ||
                                        item.prop === 'customFlag' ||
                                        item.prop === 'bkDatetimeFlag' ||
                                        item.prop ===
                                            'firstPrintDatetimeFlag' ||
                                        item.prop === 'emptyOutFlag' ||
                                        item.prop === 'giNameFlag' ||
                                        item.prop === 'vgmDatetimeFlag' ||
                                        item.prop === 'lsDatetimeFlag' ||
                                        item.prop === 'hasDepartureInfoFlag' ||
                                        item.prop === 'customPassFlag' ||
                                        item.prop === 'manifestFlag'
                                    "
                                    class="icon_wrapper"
                                >
                                    <i
                                        v-if="scope.row[item.prop] === 'Y'"
                                        class="el-icon-circle-check custom-icon icon-green"
                                    ></i>
                                    <i
                                        v-if="scope.row[item.prop] === 'N'"
                                        class="el-icon-circle-close custom-icon icon-red"
                                    ></i>
                                </span>
                                <template v-else-if="item.prop === 'webname'">
                                    <div>
                                        <i
                                            @click="
                                                handleUpdateWebName(scope.row)
                                            "
                                            class="el-icon-edit"
                                        ></i>
                                        {{
                                            luhaitongFilter(
                                                scope.row[item.prop],
                                            )
                                        }}
                                    </div>
                                </template>
                                <ClientCompanyCell  
                                v-else-if="item.prop ==='clientCompanyName'" 
                                :row-data="scope.row" :row-index="scope.$index" @change-client-company="changeClientCompany"/>
                                <span v-else-if="item.prop === 'tcStatus'">
                                    {{ tcStatusMap(scope.row[item.prop]) }}
                                </span>
                                <span v-else>
                                    {{
                                        luhaitongFilter(scope.row[item.prop]) ||
                                        "-"
                                    }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="
                                (item.prop === 'nppStatus' ||
                                    item.prop === 'lastestStatus' ||
                                    item.prop === 'changeVessels' ||
                                    item.prop === 'speedType' || item.prop === 'nodeGroup') &&
                                item.show
                            "
                            show-overflow-tooltip
                            :prop="item.prop"
                            :sortable="item.sortable"
                            :label="item.label"
                            :width="item.width"
                            :key="item.prop"
                            :resizable="item.resizable"
                            :align="item.align || 'center'"
                            :fixed="item.fixed"
                        >
                            <template slot="header" slot-scope="scope">
                                <CustomCellHeader :column="item" ></CustomCellHeader>
                            </template>
                            <template slot-scope="scope">
                                <NppStatusCell
                                    v-if="item.prop === 'nppStatus'"
                                    :row-data="scope.row"
                                    :show-tab="showTab"
                                    @refresh="getBoxListByPage"
                                ></NppStatusCell>
                                <AbroadCell v-else-if="item.prop === 'lastestStatus'" :row-data="scope.row" :row-index="scope.$index" @open-abroad-dialog="handleOpenAbroadDialog" :show-tab="showTab" @update-row-data="handleUpdateRowData"/>
                                <ChangeVesselsCell v-else-if="item.prop === 'changeVessels'" :row-data="scope.row" @markChangeShip="markChangeShip"></ChangeVesselsCell>
                                <SpeedTypeCell v-else-if="item.prop === 'speedType'" :row-data="scope.row" :row-index="scope.$index" @change="handleUpdateRowData"></SpeedTypeCell>
                                <NodeGroupCell v-else-if="item.prop === 'nodeGroup'" :row-data="scope.row" :show-tab="showTab" />
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column
                        fixed="right"
                        prop="address"
                        label="操作"
                        :formatter="formatter"
                        :width="optionColumnWidth"
                        align="center"
                        v-if="optionColumnVisible"
                    >
                        <template slot-scope="scope">
                            <div class="table_control_div">
                                <el-link v-if="scope.row.psType == '1'" :type="scope.row.topType == '0' ? 'primary' : 'warning'" @click="whetherTop(scope.row)">{{ scope.row.topType == "0" ? "置顶" : "取消置顶" }}</el-link>
                                <el-link type="primary" @click="showDetailDialog(scope.row)">详情</el-link>
                                <el-link :type="!scope.row.vesselsName || !scope.row.voyage?'default':'primary'" :disabled="!scope.row.vesselsName || !scope.row.voyage" @click="goShipMap(scope.row)">船舶定位</el-link>
                                <!-- 暂时只开放宁波订阅添加车牌 -->
                                <el-dropdown v-if="showTab === 'NB'" size="small" @command="(command) => handleAddCar(command, scope.row)">
                                    <el-link type="primary">集卡定位<i class="el-icon-arrow-down el-icon--right"></i></el-link>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="addCar">
                                            添加车号
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            command="viewLocation"
                                        >
                                            查看定位
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination_div">
                <el-pagination
                    background
                    :hide-on-single-page="pageTotal < 10"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    layout="sizes,prev, pager, next"
                    :total="pageTotal"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    :page-size.sync="pageSize"
                    :current-page.sync="pagePosition"
                ></el-pagination>
            </div>
        </div>
        <!-- 实时查询提单详情 -->
        <el-drawer
            class="node-detail"
            title="提单详情"
            :visible.sync="detailDialogVisible"
            size="30%"
            append-to-body
        >
            <check-node-detail
                :basicData="nodeBasicData"
                :collpaseData="collpaseData"
                :activeIndex="activeIndex"
                :subscribeData="subscribeData"
            />
        </el-drawer>
        <!-- 宁波拼单弹窗 -->
        <BillAssembleDialog
            ref="BillAssembleDialogRef"
            :showTab="showTab"
            @confirm="getAllBoxWatch"
        />
        <setting-dialog
            v-if="settingDialogVisible"
            ref="SettingDialog"
            :showTab="showTab"
        ></setting-dialog>
        <login-dialog
            v-if="loginDialogVisible"
            ref="LoginDialog"
        ></login-dialog>
        <!-- 弹窗, 删除 -->
        <delete-box-dialog
            :showTab="showTab"
            v-if="deleteDialogVisible"
            ref="DeleteBoxDialog"
            @refreshDataList="getBoxListByPage()"
        ></delete-box-dialog>
        <!-- 弹窗, 详情 -->
        <detail-dialog
            v-if="datailDialogVisible"
            ref="DetailDialog"
            @addRemark="addRemark()"
            :rows="rows"
            :showTab="showTab"
            @refreshDataList="getBoxListByPage()"
        ></detail-dialog>
        <SimpleDetailDialog
            ref="simpleDetailDialogRef"
        ></SimpleDetailDialog>
        <!-- 弹窗, 修改备注 -->
        <remark-box-dialog
            :showTab="showTab"
            v-if="remarkDialogVisible"
            ref="RemarkBoxDialog"
            @refreshDataList="getBoxListByPage()"
            @getData="getData()"
        ></remark-box-dialog>
        <!-- 弹窗, 上传文件 -->
        <upload
            v-if="uploadVisible"
            ref="upload"
            :showTab="showTab"
            @refreshDataList="getBoxListByPage()"
        ></upload>
        <voyage-change-dialog
            :showTab="showTab"
            v-if="voyageDialogVisible"
            ref="VoyageChangeDialog"
            @refreshDataList="getBoxListByPage"
        ></voyage-change-dialog>
        <!-- 上海,青岛，天津分票的弹窗 -->
        <SHSplitOptDialog
            ref="shsplitRef"
            @confirm="handleConfirmSHSplit"
            :showTab="showTab"
        />
        <!-- 青岛,天津拼箱主提单修改弹窗 -->
        <QDSplitBlnoMainDialog
            @confirm="getBoxListByPage"
            ref="BlnoMainDialog"
            :showTab="showTab"
            v-if="blnoMainDialogVisible"
        />
        <!-- 青岛场站修改弹窗 -->
        <QDWebNameDialog
            @confirm="getBoxListByPage"
            ref="WebNameDialog"
            v-if="webNameDialogVisible"
        ></QDWebNameDialog>
        <!-- 拼票提单号选择 -->
        <ChildBlnoDialog
            ref="childBlnoDialogRef"
            @confirm="BoxesWatch"
        ></ChildBlnoDialog>
        <!-- 换船标识弹窗 -->
        <ChangeShipDialog
            ref="changeShipDialogRef"
            @confirm="getBoxListByPage"
            :showTab="showTab"
        />
        <!-- 异常反馈弹窗 -->
        <ErrorCallbackDialog
            ref="errorCallbackDialogRef"
            @confirm="getBoxListByPage"
            :showTab="showTab"
        />
        <!-- 设置客户公司 -->
        <ChangeClientComanyDialog
            ref="changeClientComanyDialogRef"
            @confirm="getBoxListByPage"
            :showTab="showTab"
            @refresh="getClientComayList"
        />
        <!-- 国外节点 -->
        <AbroadMoveDialog ref="abroadMoveDialogRef" :showTab="showTab" />
        <!-- 添加车号弹窗 -->
        <AddCarDialog ref="addCarDialogRef" @refresh="getBoxListByPage" />
    </div>
</template>

<script>
import throttle from "lodash.throttle";
import moment from "moment";
import { takeoutTable } from "@/data";
import CommonHeader from "@/components/common/CommonHeader";
import {
    setRightForrmat,
    setRightForrmatSpace,
    setRightForrmatSpace2,
    setRightForrmatSpace3,
} from "@/utils/validate";
import RemarkCell from "@/components/common/RemarkCell";
import ColumnSet from "@/components/common/ColumnSet";
import TableHeaderTooltip from "@/components/common/TableHeaderTooltip";
import VoyageChangeDialog from "@/components/ship/VoyageChangeDialog";
import Upload from "@/components/common/oss-upload";
import TabBarImage from "@/components/common/TabBarImage";
import YGTWharfEdit from "@/components/common/YGTWharfEdit";
import LoginDialog from "@/components/register/WechatLoginDialog";
import DeleteBoxDialog from "@/components/ship/DeleteBoxDialog";
import DetailDialog from "@/components/ship/DetailDialog";
import SimpleDetailDialog from "@/components/ship/DetailContent/simple.vue"; // 进港舱单详情
import RemarkBoxDialog from "@/components/ship/RemarkDialog";
import SettingDialog from "./components/PushSetting";
import TruckNoAdd from "./components/TruckNoAdd";
import EmptyTakeout from "./components/EmptyTakeout";
import SHSplitOptDialog from "./components/SHSplitOptDialog.vue";
import QDSplitBlnoMainDialog from "./components/QDSplitBlnoMainDialog.vue";
import QDWebNameDialog from "./components/QDWebNameDialog.vue";
import ChildBlnoDialog from "./components/ChildBlnoDialog.vue";
import ChangeShipDialog from "./components/ChangeShipDialog.vue";
import ErrorCallbackDialog from "./components/ErrorCallbackDialog.vue";
import ChangeClientComanyDialog from "./components/ChangeClientComanyDialog.vue";
import AbroadMoveDialog from "./components/AbroadMoveDialog.vue";
import AddCarDialog from "./components/AddCarDialog.vue";
import AbroadCell from "./components/AbroadCell.vue";
import TableSearchHeader from "./components/TableSearchHeader.vue";
import { getToken, getCompanyId, getRole } from "@/utils/auth";
import { mapGetters } from "vuex";
import { orderBoxTicket } from "@/api/tracking";
import { setCompanyCache } from "@/utils";
import bus from "@/utils/bus";
import {
    searchShip,
    updateRemarkAndTruck,
} from "@/api/boxQuery";
import { queryXMWharfList } from "@/api/xiamenLogistics";
import {
    apiCallback,
    objectFilterEmpty,
    wharfCodeToStr,
    msToDate,
    freeBalanceLackTip,
    passFlag2StringXM,
    decimalToHex,
    getUserAccountInfo,
    getWarfList,
    getNameByCode,
    saveColumnConfig
} from "@/utils";
import { getConfigNoUserId } from "@/api/common";
import PlaceShow from "@/components/common/PlaceShow";
import TopBanner from "@/components/layout/TopBanner";
import TabBarGroup from "@/components/common/TabBarGroup.vue";
import {
    logisticsRequest,
    luhaitongFilter,
    tcStatusMap,
    getCarrierByBillNo,
} from "./logistics";
import { SHORT_CUTS } from "@/utils/dataList";
import { queryQDstations } from "@/api/qingdaoLogistics";
import { shipRequest } from "../ShipSubscrib/ship";
import { logisticsTableList } from "./tableList";
import { guideFunc } from "@/utils/guide";
import CheckNodeDetail from "../BoxQuery/components/CheckNodeDetail.vue";
import OwnerCtnColumn from "./components/OwnerCtnColumn.vue";
import BlnoCell from "./components/BlnoCell.vue";
import ShipInfoCell from "./components/ShipInfoCell.vue";
import BlnoMainCell from "./components/BlnoMainCell.vue";
import ClientCompanyCell from "./components/ClientCompanyCell.vue";
import BillAssembleDialog from "./components/BillAssembleDialog.vue";
import NodeGroupCell from "./components/NodeGroupCell.vue";
import NppStatusCell from "./components/NppStatusCell.vue";
import ChangeVesselsCell from "./components/table-cell/ChangeVesselsCell.vue";
import SpeedTypeCell from "./components/table-cell/SpeedTypeCell.vue";
import CustomCellHeader from "./components/table-cell/CustomCellHeader.vue";
import { dealListOne_New, dealListTwo_New, realTimeQueryDetail, blurForBug, getCellClass } from "./utils";
export default {
    data() {
        return {
            getCellClass,
            blurForBug,
            showTab: "NB",
            rows: "",
            wharfCodeToStr,
            luhaitongFilter,
            passFlag2StringXM,
            tcStatusMap,
            takeoutTable,
            msToDate,
            expandIcon: require("@/assets/icon/collapse_expand.svg"),
            collapseIcon: require("@/assets/icon/collapse_close.svg"),
            multi_add_loading: false, //批量添加
            single_add_loading: false, //单独添加
            settingDialogVisible: false,
            voyageDialogVisible: false,
            loginDialogVisible: false,
            deleteDialogVisible: false,
            datailDialogVisible: false,
            remarkDialogVisible: false,
            blnoMainDialogVisible: false, //青岛拼箱主提单号弹窗
            webNameDialogVisible: false, // 青岛场站修改弹窗
            uploadVisible: false,
            abroadMoveVisible: false, // 国外节点弹窗
            loading: false,
            loginInterval: "",
            vesselsName: "",
            shopCompanyValue: "",
            voyage: "",
            blno: "",
            isAbroad: "", // 订阅时是否订阅国外
            totalBoxList: [], //存放原始数组用于筛选
            totalshipList: [], //存放原始船名航次用于筛选
            boxList: [], //展示用的列表数据
            multipleSelection: [], //勾选中的列表
            carList: [], //集卡定位车号
            repeatPopTitle: "",
            repeatPopVisible: false,
            // 表格key
            tableKey: 1,
            pageTotal: 0,
            pagePosition: 1,
            startRow: 1,
            // 上海船司列表
            portcloseFlag: "0", //截关截港筛选，0：全部，1：截关，2：截港
            is_portclose: false, //临近截关期
            is_ctnapplyend: false, //临近截港期
            modelOptflag: "0", // 0:整柜 1：拼单
            showList: [], //需展开的提单号
            timeType: "", //日期筛选类型
            shipCompany: "", //船司
            fixTableHeader: false, // 是否固定表头
            // 青岛场站列表
            webNameList: [],
            // 当前青岛选中的场站
            webName: "",
            // 厦门码头列表
            XMWharfList: [],
            // 当前厦门选中的列表
            XMWharf: "",
            // changeVesselName: false
            updateNotesGuide: "", // 列表加载完毕后需要展示的引导
            curEditId: null, // 当前编辑的id
            detailDialogVisible: false,
            nodeBasicData: [], // 提单详情数据
            collpaseData: [],
            subscribeData: {},
            activeIndex: 0,
            curEditRow: {}, // 当前编辑的行
            optionColumnVisible: true,
            isExperience: false, // 是否是内测用户
        };
    },
    components: {
        SettingDialog,
        LoginDialog,
        DeleteBoxDialog,
        DetailDialog,
        Upload,
        RemarkBoxDialog,
        VoyageChangeDialog,
        CommonHeader,
        TableHeaderTooltip,
        ColumnSet,
        TabBarImage,
        PlaceShow,
        TruckNoAdd,
        EmptyTakeout,
        YGTWharfEdit,
        TopBanner,
        TabBarGroup,
        RemarkCell,
        SHSplitOptDialog,
        QDSplitBlnoMainDialog,
        QDWebNameDialog,
        ChildBlnoDialog,
        ChangeShipDialog,
        ErrorCallbackDialog,
        ChangeClientComanyDialog,
        AbroadMoveDialog,
        CheckNodeDetail,
        AddCarDialog,
        OwnerCtnColumn,
        BlnoCell,
        ShipInfoCell,
        BlnoMainCell,
        ClientCompanyCell,
        AbroadCell,
        BillAssembleDialog,
        TableSearchHeader,
        NodeGroupCell,
        NppStatusCell,
        ChangeVesselsCell,
        SpeedTypeCell,
        CustomCellHeader,
        SimpleDetailDialog
    },
    created() {
        const { tab } = this.$route.query;
        if (tab) {
            this.showTab = tab.toUpperCase(tab);
        } else {
            this.showTab = localStorage.getItem("showTab") || this.showTab;
        }
    },
    mounted() {
        this.Init();
        const storageOptionColumnVisible = localStorage.getItem("fcw_web_logistics_optionColumnVisible");
        this.optionColumnVisible = storageOptionColumnVisible !== undefined ? storageOptionColumnVisible === "true" : true;
        this.handleOptionColumnCollapse(true);
        this.checkExperience();
        bus.$on("showUpdateNotesGuide", (type) => {
            // 客户公司管理更新公告关闭后展示引导（需要在列表存在数据的情况下展示引导）
            if (type === "notes3") {
                if (this.tableList.length) {
                    if (this.showTab !== "NB") {
                        this.showTabClick("NB");
                        this.updateNotesGuide = "allNote3";
                    } else {
                        this.guide("allNote3");
                    }
                }
            }
        });
        // this.showTab = localStorage.getItem("showTab") || this.showTab;
    },
    computed: {
        ...mapGetters({
            pageSizeFromStore: "pageSize",
        }),
        optionColumnWidth() {
            let result = this.showTab === "NB" ? 220 : 140;
            if (!this.optionColumnVisible) { 
                result = 0;
            }
            return result;
        },
        tableMaxHeight() {
            return this.fixTableHeader ? window.innerHeight * 0.6 : "auto";
        },
        tabLayout() {
            return this.$store.state.other.screenWidth < 1610
                ? "vertical"
                : "horizontal";
        },
        pageSize: {
            get() {
                return this.pageSizeFromStore;
            },
            set(val) {
                this.$store.commit("table/editPageSize", val);
            },
        },
        tableList: {
            get() {
                const result = logisticsTableList({
                    tab: this.showTab,
                    type: 1,
                });
                return result
            },
            set(val) {
                return logisticsTableList({
                    tab: this.showTab,
                    type: 2,
                    data: val,
                });
            },
        },

        showTableList() {
            const result = this.isExperience ? this.tableList : this.tableList.filter(item => !item.isExperience);
            return result;
        },

        timeOptions() {
            return logisticsTableList({
                tab: this.showTab,
                type: 4,
            });
        },

        showTabTitle() {
            return getNameByCode(this.showTab);
        },
        curResetTable() {
            return logisticsTableList({
                tab: this.showTab,
                type: 3,
            });
        },
        areaColumn() {
            const column =
                this.tableList.find((item) => item.prop === "area") ||
                this.curResetTable.find((item) => item.prop === "area");
            return column;
        },
        curClientCompanyList() {
            return this.$store.state.user.clientUseComapnyList || [];
        },
        divlineIndex() {
            let result = 0;
            this.boxList.forEach((item, index) => {
                if (item.topType === 1) {
                    result = index;
                }
            });
            return result;
        }
    },
    watch: {
        tableList: {
            handler(val) {
                this.tableKey += 1;
            },
            deep: true,
        },
        modelOptflag(newVal) {
            if (this.showTab === "NB") {
                if (newVal === "1") {
                    this.$refs.BillAssembleDialogRef.open();
                }
            } else if (["QD", "SH", "TJ", "XM"].includes(this.showTab)) {
                // 上海,青岛分票的弹窗
                newVal === "1" && this.$refs.shsplitRef.open();
            }
        },
        loading(val) {
            if (!val && this.updateNotesGuide === "allNote3") {
                // 当从其他tab切换到宁波tab时，需要等数据获取并渲染完毕后再展示引导
                this.$nextTick(() => {
                    this.guide("allNote3");
                    this.updateNotesGuide = "";
                });
            }
        },
        showTab(newVal){
            this.handleOptionColumnCollapse(true);
        },
    },
    methods: {
        // 搜索订单
        searchBoxList(params) {
            this.pagePosition = 1;
            this.getBoxListByPage();
        },
        async showDetail() {
            const res = await realTimeQueryDetail(this.blno, this.showTab); // 实时查询提单详情
            if (res) {
                this.detailDialogVisible = true;
                Object.keys(res).forEach(key => {
                    this[key] = res[key];
                })
            }
        },
        // 直接修改列表数据，不进行整体刷新
        handleUpdateRowData({ rowIndex, data, rowData = {} }) {
            Object.keys(data).forEach(key => {
                this.boxList[rowIndex][key] = data[key];
                if (!rowData.parentBlno) {
                    this.totalBoxList[rowIndex][key] = data[key]
                }
            })
            if (rowData.parentBlno) {
                // 同时更新totalBoxList，因为收起展开拼票列的时候totalBoxList是数据源
                this.totalBoxList.forEach((box,pIndex) => { 
                    if (box.blno === rowData.parentBlno) {
                        // 找到正在编辑的父提单
                        box.sonList.forEach((item, sIndex) => { 
                            // 找到正在编辑的字提单
                            if (item.blno === rowData.blno) {
                                Object.keys(data).forEach(key => { 
                                    // 更新对应数据
                                    this.totalBoxList[pIndex].sonList[sIndex][key] = data[key]
                                })
                            }
                        })
                    }
                })
            }

        },
        handleToggleShowList(blno, type) {
            if (type === 'expand') {
                this.showAllList(blno)
            } else if (type === 'close') { 
                this.showOneList(blno)
            }
        },
        guide(type) {
            // 操作引导处理函数
            switch (type) {
                case "feedback":
                    guideFunc("feedback", {
                        onNextClick: (driver, step) => {
                            if (step.element === "#feedback-input") {
                                this.$refs.errorCallbackDialogRef.handleCancel();
                                driver.destroy();
                            }
                            if (step.element === "#feedback-icon") {
                                this.errorCallback(this.boxList[0]);
                                this.$nextTick(() => {
                                    driver.moveNext();
                                });
                            }
                        },
                        onCloseClick: (driver) => {
                            this.$refs.errorCallbackDialogRef.handleCancel();
                            driver.destroy();
                        },
                        onPrevClick: (driver) => {
                            this.$refs.errorCallbackDialogRef.handleCancel();
                            driver.movePrevious();
                        },
                    });
                    break;
                case "clientCompany":
                    guideFunc("clientCompany", {
                        onNextClick: (driver, step) => {
                            if (step.element === "#clientCompanyGuideCell") {
                                this.$refs.changeClientComanyDialogRef
                                    .open(this.boxList[0])
                                    .then(() => {
                                        driver.moveNext();
                                    });
                            } else if (
                                step.element === "#clientCompanyAddGuide"
                            ) {
                                driver.moveNext();
                            } else if (
                                step.element === "#clientCompanyManageGuide"
                            ) {
                                this.$refs.changeClientComanyDialogRef.handleCancel();
                                driver.moveNext();
                            } else {
                                driver.destroy();
                            }
                        },
                        onCloseClick: (driver) => {
                            this.$refs.changeClientComanyDialogRef.handleCancel();
                            driver.destroy();
                        },
                        onPrevClick: (driver, step) => {
                            if (step.element === "#clientCompanyAddGuide") {
                                this.$refs.changeClientComanyDialogRef.handleCancel();
                                driver.movePrevious();
                            } else if (
                                step.element === "#clientCompany-filter-select"
                            ) {
                                this.$refs.changeClientComanyDialogRef
                                    .open(this.boxList[0])
                                    .then(() => {
                                        driver.movePrevious();
                                    });
                            } else {
                                driver.movePrevious();
                            }
                        },
                    });
                    break;
                case "shipChange":
                    const needAdd = document.querySelector(".addVoyage_link");
                    guideFunc(
                        needAdd ? "shipChangeNeedAdd" : "shipChangeNoAdd",
                    );
                    break;
                case "allNote3":
                    guideFunc("allNote3", {
                        onNextClick: (driver, step) => {
                            if (step.element === "#feedback-icon") {
                                this.errorCallback(this.boxList[0]);
                                this.$nextTick(() => {
                                    driver.moveNext();
                                });
                            } else if (step.element === "#feedback-input") {
                                this.$refs.errorCallbackDialogRef.handleCancel();
                                driver.moveNext();
                            } else if (
                                step.element === "#clientCompanyGuideCell"
                            ) {
                                this.$refs.changeClientComanyDialogRef
                                    .open(this.boxList[0])
                                    .then(() => {
                                        driver.moveNext();
                                    });
                            } else if (
                                step.element === "#clientCompanyAddGuide"
                            ) {
                                driver.moveNext();
                            } else if (
                                step.element === "#clientCompanyManageGuide"
                            ) {
                                this.$refs.changeClientComanyDialogRef.handleCancel();
                                driver.moveNext();
                            } else if (step.element === ".update-notes-icon") {
                                driver.destroy();
                            } else {
                                driver.moveNext();
                            }
                        },
                        onPrevClick: (driver, step) => {
                            if (
                                step.element === "#clientCompany-filter-select"
                            ) {
                                this.$refs.changeClientComanyDialogRef
                                    .open(this.boxList[0])
                                    .then(() => {
                                        driver.movePrevious();
                                    });
                            } else if (
                                step.element === "#clientCompanyAddGuide"
                            ) {
                                this.$refs.changeClientComanyDialogRef.handleCancel();
                                driver.movePrevious();
                            } else if (
                                step.element === "#clientCompanyGuideCell"
                            ) {
                                this.errorCallback(this.boxList[0]);
                                this.$nextTick(() => {
                                    driver.movePrevious();
                                });
                            } else if (step.element === "#feedback-input") {
                                this.$refs.errorCallbackDialogRef.handleCancel();
                                driver.movePrevious();
                            } else {
                                driver.movePrevious();
                            }
                        },
                        onCloseClick: (driver) => {
                            this.$refs.errorCallbackDialogRef.handleCancel();
                            this.$refs.changeClientComanyDialogRef.handleCancel();
                            driver.destroy();
                        },
                    });
                    break;
            }
        },
        handleChangeFixHeader() {
            if (this.fixTableHeader === true) {
                this.$confirm(
                    "关闭固定表头需要刷新页面，请确认您的数据已保存！",
                    "提示",
                    {
                        confirmButtonText: "立即关闭",
                        cancelButtonText: "取消",
                        type: "warning",
                    },
                ).then(() => {
                    this.fixTableHeader = !this.fixTableHeader;
                    localStorage.setItem(
                        "fixTableHeader_logistics",
                        this.fixTableHeader,
                    );
                    window.location.reload();
                });
            } else {
                this.fixTableHeader = !this.fixTableHeader;
                localStorage.setItem(
                    "fixTableHeader_logistics",
                    this.fixTableHeader,
                );
            }
        },
        isFixedColumn(key) {
            const keyArr = ["area", "billNo", "shipInfo", "blnoMain"];
            return keyArr.some((item) => item === key);
        },
        showTabClick(val) {
            if (this.loading) {
                this.$message.error("请等待数据加载完成再重试，请勿频繁切换");
                return;
            }
            if (val === this.showTab) {
                return;
            }
            this.showTab = val;
            this.boxList = [];
            localStorage.setItem("showTab", val);
            this.$refs.tableSearchHeaderRef.resetSearch();
            this.Init();
            this.$store.commit("other/editSystemKey", val);
        },
        Init() {
            this.checkLogin();
            this.getTableListData();
            const storageFixHeader =
                localStorage.getItem("fixTableHeader_logistics") === "true";
            this.fixTableHeader = storageFixHeader;
        },
        // 检查是否是内测用户
        checkExperience() {
            const phone = this.$store.state.user.userphone;
            getConfigNoUserId({
                dictKey: "web_fangcangwang_experience_users",
            }).then((res) => {
                const { dictValue } = apiCallback(res);
                const parseValue = JSON.parse(dictValue);

                this.isExperience = Boolean(parseValue[phone]);
            });
        },
        // 更新当前tableList
        getTableListData() {
            var newlen = 0;
            const curTableList = logisticsTableList({
                tab: this.showTab,
                type: 1,
            });
            for (var i = 0; i < curTableList.length; i++) {
                if (curTableList[i].label) {
                    newlen += curTableList[i].label.length;
                }
            }
            // 判断是否是宁波港决定渲染列表数据
            const restTable = logisticsTableList({
                tab: this.showTab,
                type: 3,
            });
            var oldlen = 0;
            for (var i = 0; i < restTable.length; i++) {
                if (restTable[i].label) {
                    oldlen += restTable[i].label.length;
                }
            }
            if (newlen !== oldlen) {
                this.tableList = curTableList;
            } else {
                this.resetTable();
            }
        },
        resetTable() {
            this.tableKey = 1;
            this.tableList = this.curResetTable;
        },
        //修改分页的pagesize
        handleSizeChange() {
            this.pagePosition = 1;
            this.$nextTick(() => {
                this.getBoxListByPage();
            });
        },
        //分页
        handleCurrentChange(val) {
            this.pagePosition = val;
            this.getBoxListByPage();
        },
        //登录，显示二维码弹窗
        login() {
            this.loginDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.LoginDialog.init();
            });
        },
        //定时执行
        checkLogin() {
            var that = this;
            this.loginInterval = setInterval(function () {
                that.checkToken();
            }, 10);
        },

        // 青岛获取场站
        getWebName() {
            queryQDstations().then((res) => {
                const data = apiCallback(res);
                this.webNameList = data;
            });
        },

        // 厦门获取码头
        getWharfList() {
            queryXMWharfList().then((res) => {
                const data = apiCallback(res);
                this.XMWharfList = data;
            });
        },

        async checkToken() {
            if (getToken()) {
                clearInterval(this.loginInterval);
                // 分页箱单
                this.getBoxListByPage();
                if (this.showTab !== "SZ") {
                    this.$refs.tableSearchHeaderRef.getVoyageList();
                    // 获取提单
                    this.$refs.tableSearchHeaderRef.getBlnoList();
                }

                // 获取客户公司
                this.getClientComayList();
                if (this.showTab === "QD") {
                    // 获取场站
                    this.getWebName();
                }
                if (this.showTab === "XM") {
                    // 获取码头
                    this.getWharfList();
                }
            } else {
                clearInterval(this.loginInterval);
            }
        },
        //选中的列表数据
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //自定义单元格宽度
        handleHeaderChange(newWidth, oldWidth, column, event) {
            this.tableList.forEach((item) => {
                if (item.label == column.label) {
                    item.width = newWidth;
                }
            });
            this.$store.commit(`table/editTableList${this.showTab}`, this.tableList);
            this.handleSaveColumnWidth();
        },
        formatter(row, column) {
            return row.address;
        },
        toggleShowList() {
            let checkShowList = [];
            this.totalBoxList.forEach((item) => {
                this.showList.forEach((item2) => {
                    if (item2 == item.blno) {
                        checkShowList.push(item2);
                    }
                });
            });
            this.showList = checkShowList;
            this.dealList(this.totalBoxList);
        },
        // 拼票展示全部
        showAllList(blno) {
            if (!this.showList.includes((item) => item.blno)) {
                this.showList.push(blno);
                this.toggleShowList();
            }
        },
        // 拼票收起
        showOneList(blno) {
            let index = this.showList.findIndex((item) => item == blno);
            if (~index) {
                this.showList.splice(index, 1);
                this.toggleShowList();
            }
        },
        getAllBoxWatch() {
            if (getToken()) {
                if (!this.blno) {
                    const msg =
                        this.showTab === "SZ" ? "请输入S/O号" : "请输入提单号";
                    this.$message.warning(msg);
                    return;
                }
                if (this.showTab === "QD" && !this.webName) {
                    this.$message.warning("请先选择场站");
                    return;
                }
                if (this.showTab === "XM" && !this.XMWharf) {
                    this.$message.warning("请先选择码头");
                    return;
                }

                if (
                    (this.vesselsName || this.voyage) &&
                    (!this.vesselsName || !this.voyage)
                ) {
                    this.$message.warning("请同时输入船名和航次");
                    return;
                }
                if (
                    ["QD", "SH", "TJ", "XM"].includes(this.showTab) &&
                    this.modelOptflag === "1"
                ) {
                    this.handleConfirmSHSplit(this.$refs.shsplitRef.textarea);
                    return;
                }
                let boxWatchList = [this.blno]
                if (this.modelOptflag === "1") {
                    // 拼票获取全部提单号
                    boxWatchList = this.$refs.BillAssembleDialogRef.getBoxWatchList(this.blno);
                }
                if(boxWatchList && boxWatchList.length > 0){
                    this.BoxesWatch(boxWatchList);
                }
            } else {
                this.login();
            }
        },
        //全部船名航次搜索框带输入建议
        shipSearch(queryString, cb) {
            var restaurants = this.totalshipList;
            var results = queryString
                ? restaurants.filter(this.shipFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        shipFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value.indexOf(queryString.toUpperCase()) != -1
                );
            };
        },
        //订阅框船名航次搜索框选择了输入建议
        handleShipSelect(item) {
            var selectShip = item.value.split("/");
            this.vesselsName = selectShip[0];
            this.voyage = selectShip[1];
        },
        //检测订阅跟踪输入的关键字格式
        checkInputBlno() {
            this.blno = setRightForrmat(this.blno);
        },
        checkInputVesselename() {
            if (this.vesselsName) {
                this.vesselsName = setRightForrmatSpace2(this.vesselsName);
            }
            if (this.vesselsName.length >= 4) {
                var that = this;
                searchShip({ vesselsName: this.vesselsName })
                    .then(({ data }) => {
                        that.totalshipList = [];
                        data.data &&
                            data.data.forEach((item) => {
                                this.totalshipList.push({
                                    value: `${item.s}/${item.v}`,
                                });
                            });
                    })
                    .catch((err) => {
                        this.totalshipList = [];
                        this.$message.error(err);
                    });
            } else if (this.vesselsName.length < 4) {
                this.totalshipList = [];
            }
        },
        checkInputVesselename2(val) {
            if (val) {
                var len = val.length - 1;
                if (val[len] == "/") {
                    this.vesselsName = val.substring(0, len);
                }
            }
        },
        checkInputVoyage() {
            if (this.voyage) {
                this.voyage = setRightForrmat(this.voyage);
            }
        },
        handleOptionColumnCollapse(isInit) {
            if (!isInit) {
                this.optionColumnVisible = !this.optionColumnVisible;
                localStorage.setItem("fcw_web_logistics_optionColumnVisible", this.optionColumnVisible);
            } 
            const imgWrapper = document.querySelector(".expand-btn");
            if(imgWrapper){
                imgWrapper.style.right = this.optionColumnVisible ? `${this.optionColumnWidth}px` : "0px";
            }
            this.tableKey++;
        },
        //分页查箱单
        getBoxListByPage() {
            var that = this;
            const params = this.$refs.tableSearchHeaderRef.getCurSearchParams();
            params.code = this.showTab;
            params.pageNum = this.pagePosition;
            params.pageSize = this.pageSize;
            this.loading = true;
            this.boxList = [];
            const reqFunc = logisticsRequest({
                tab: this.showTab,
                type: 1,
            });
            reqFunc(objectFilterEmpty({ ...params }))
                .then((response) => {
                    that.loading = false;
                    const data = apiCallback(response);

                    let list = data.list;
                    if (list === null) {
                        that.totalBoxList = [];
                        that.boxList = [];
                    } else {
                        that.totalBoxList = list;

                        this.pageTotal = data.total;

                        that.startRow = data.startRow;
                        if (data.size == 0 && that.pagePosition > 1) {
                            //防止最后一页数据删除完后后页面不跳（后端返回的pageNum有问题）
                            that.pagePosition = data.pageNum - 1;
                            that.getBoxListByPage();
                        } else {
                            that.pagePosition = data.pageNum;
                        }
                    }
                    that.toggleShowList();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 处理列表数据
        dealList(list) {
            this.boxList.splice(0, this.boxList.length);
            list.forEach((item) => {
                let marker = this.showList.findIndex(
                    (item2) => item2 == item.blno,
                );
                if (marker != -1) {
                    const data = dealListTwo_New(item, this.showTab).map((item, index) => {
                        item.index = this.tableIndex(this.boxList.length + index); //序号
                        return item;
                    });
                    this.boxList = this.boxList.concat(data);
                } else {
                    const data = dealListOne_New(item, this.showTab);
                    data.index = this.tableIndex(this.boxList.length); //序号
                    this.boxList.push(data);
                }
            });
        },
        // 打开上传文件组件
        uploadHandle() {
            this.uploadVisible = true;
            let p = {};
            p["type"] = "fileUrl";
            if (this.showTab === "NB") {
                this.$nextTick(() => {
                    this.$refs.upload.init();
                });
            } else if (this.showTab === "SH") {
                this.$nextTick(() => {
                    this.$refs.upload.initSH();
                });
            } else if (this.showTab === "QD") {
                this.$nextTick(() => {
                    this.$refs.upload.initQD();
                });
            } else if (this.showTab === "TJ") {
                this.$nextTick(() => {
                    this.$refs.upload.initTJ();
                });
            } else if (this.showTab === "XM") {
                this.$nextTick(() => {
                    this.$refs.upload.initXM();
                });
            } else if (this.showTab === "SZ") {
                this.$nextTick(() => {
                    this.$refs.upload.initSZ();
                });
            }
        },
        //添加备注
        addRemark(item = this.rows) {
            this.remarkDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.RemarkBoxDialog.init({
                    id: item.id,
                    blno: item.blno,
                    vesselename: item.vesselename,
                    voyage: item.voyage,
                    remark: item.remark,
                });
            });
        },

        // 场站修改
        handleUpdateWebName(row) {
            this.webNameDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.WebNameDialog.init(row, this.webNameList);
            });
        },

        getData() {
            if (this.datailDialogVisible) {
                this.$nextTick(() => {
                    this.$refs.DetailDialog.getData(this.remark);
                });
            }
        },
        //添加船名航次
        addVoyage(item) {
            this.voyageDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.VoyageChangeDialog.init([item]);
            });
        },
        // 添加主提单号
        addBlnoMain(item) {
            this.blnoMainDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.BlnoMainDialog.init([item]);
            });
        },
        showCarPosition(list) {
            if (list["departureTime"]) {
                var dischargetime = new Date(list["departureTime"]).getTime();
                var nowtime = new Date().getTime();
                if (nowtime > dischargetime) {
                    this.$message.warning("已经离港不支持货车定位");
                    return;
                }
            }
            this.goTruckMap(list["id"]);
        },
        goTruckMap(id) {
            const newpage = this.$router.resolve({
                path: "/truckmap",
                query: {
                    id,
                },
            });
            window.open(newpage.href, "_blank");
        },
        goBoxQuery() {
            this.$router.push("/boxquery");
        },
        goHistory() {
            this.$router.push("/history");
        },
        goShipMap(shipInfo) {
            const newpage = this.$router.resolve({
                path: "/shipmap",
                query: {
                    blno: shipInfo.blno,
                    vesselEname: shipInfo.vesselsName,
                    voyage: shipInfo.voyage,
                },
                params: { item: shipInfo },
            });
            window.open(newpage.href, "_blank");
        },
        whetherTop(item) {
            var ids = item.id;
            // ids.push(item.id);
            if (item.topType == 0) {
                this.goTotop(ids);
            } else if (item.topType == 1) {
                this.cancelTop(ids);
            }
        },
        //置顶
        goTotop(ids) {
            var that = this;
            const reqFunc = shipRequest({
                tab: this.showTab,
                type: 3,
            });
            reqFunc([ids])
                .then((response) => {
                    that.$message.success("置顶成功");
                    that.getBoxListByPage();
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        //取消置顶
        cancelTop(ids) {
            var that = this;
            const reqFunc = shipRequest({
                tab: this.showTab,
                type: 4,
            });
            reqFunc([ids])
                .then(async (response) => {
                    that.$message.success("置顶已取消");
                    that.getBoxListByPage();
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        // 订阅成功回调
        watchSuccCallback(res) {
            const that = this;
            that.isAbroad = false;
            const data = apiCallback(res);
            if (data && data.blnos) {
                // 输入的为拼票提单号的主提单号，需要提示让用户选择
                this.$refs.childBlnoDialogRef.open(data);
            } else {
                // 正常订阅成功
                that.getBoxListByPage();
                if (
                    !that.vesselsName &&
                    !that.voyage &&
                    that.showTab !== "SZ"
                ) {
                    that.$message({
                        type: "error",
                        duration: 3000,
                        dangerouslyUseHTMLString: true,
                        showTitle: false,
                        message:
                            "<div style='color:#F56C6C;font-weight:bold'><div>请注意:</div><div>尚未输入船名/航次，如只输提单号，7天内未查询<br>到任何数据，将暂停查询，需重新添加/续订才可！</div></div>",
                    });
                } else {
                    that.$message.success("订阅成功");
                }
                that.blno = "";
                that.vesselsName = "";
                that.voyage = "";
                that.modelOptflag = "0";
                that.single_add_loading = false;
                getUserAccountInfo(true);
                that.$refs.BillAssembleDialogRef.reset();
            }
        },
        // 订阅失败回调
        watchFailCallback(err) {
            const that = this;
            console.error(err);
            that.single_add_loading = false;
            const errMsg = err?.message;
            if (!errMsg) {
                return;
            }
            if (errMsg.indexOf("已订阅") > -1) {
                that.repeatPopVisible = true;
                that.repeatPopTitle = errMsg;
            } else if (errMsg !== "SUCCESS") {
                that.$message.error(`${errMsg}，请核对后重新添加跟踪`);
            }
        },
        //订阅箱单
        async BoxesWatch(data, setting = {}) {
            if (this.showTab === "QD") {
                if (!this.webName) {
                    this.$message.warning("请选择场站");
                    return;
                }
            }
            // 暂时注释 TODO
            if (this.showTab === "XM") {
                if (!this.XMWharf) {
                    this.$message.warning("请选择码头");
                    return;
                }
            }
            const isSubsAbroad = Boolean(this.isAbroad) ? 1 : 0;
            let shipCode;
            if (isSubsAbroad) {
                shipCode = await getCarrierByBillNo({
                    billNo: data[0].blno || data[0],
                    portCode: this.showTab,
                });
                if (!shipCode) {
                    this.$message.warning("暂不支持该船公司提单号订阅国外段");
                }
            }
            const boxesWatch = () => {
                this.single_add_loading = true;
                if (data.length) {
                    var dataList = [];
                    for (var i = 0; i < data.length; i++) {
                        var List = {};
                        if (i == 0) {
                            List = {
                                blno:
                                    data[i] instanceof Object
                                        ? data[i].blno
                                        : data[i],
                                vesselsName:
                                    data[i].vesselsName || this.vesselsName,
                                voyage: data[i].voyage || this.voyage,
                                psType: "1",
                                isCheck: false,
                                companyId: getCompanyId(),
                                role: getRole(),
                                isAbroad: isSubsAbroad,
                            };
                            if (this.showTab === "QD") {
                                List.webname = this.webName;
                                // List.psType=setting.blnoMain
                            }
                            if (this.showTab === "XM") {
                                List.wharf = this.XMWharf;
                            }
                            if (shipCode) {
                                List.shipCode = shipCode;
                            }
                        } else {
                            List = {
                                blno:
                                    data[i] instanceof Object
                                        ? data[i].blno
                                        : data[i],
                                vesselsName:
                                    data[i].vesselsName || this.vesselsName,
                                voyage: data[i].voyage || this.voyage,
                                psType:
                                    data[0] instanceof Object
                                        ? data[0].blno
                                        : data[0],
                                isCheck: false,
                                companyId: getCompanyId(),
                                role: getRole(),
                                isAbroad: isSubsAbroad,
                            };
                            if (this.showTab === "QD") {
                                List.webname = this.webName;
                            }
                            if (this.showTab === "XM") {
                                List.wharf = this.XMWharf;
                            }
                            if (shipCode) {
                                List.shipCode = shipCode;
                            }
                        }
                        dataList.push({ ...List, code: this.showTab });
                    }
                }
                var that = this;
                let postUrl = "";
                let postData = {};
                let postOption = {
                    showErrorMessage: false,
                };
                if (data.length === 1) {
                    // 订阅整柜
                    postUrl = logisticsRequest({
                        tab: this.showTab,
                        type: 4,
                    });
                    postData = {
                        ...dataList[0],
                        isCheck: true,
                        companyId: getCompanyId(),
                        role: getRole(),
                    };
                } else {
                    // 订阅拼箱
                    postUrl = logisticsRequest({
                        tab: this.showTab,
                        type: 5,
                    });
                    postData = dataList;
                }
                orderBoxTicket(postUrl, postData, postOption)
                    .then(function (res) {
                        that.watchSuccCallback(res);
                    })
                    .catch((err) => {
                        that.watchFailCallback(err);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            that.repeatPopVisible = false;
                            that.$nextTick(() => {
                                that.repeatPopTitle = "";
                            });
                        }, 1500);
                        this.single_add_loading = false;
                        if (setting.fromChildDialog) {
                            // 从拼票订阅弹窗触发的订阅才需要关闭弹窗
                            this.$refs.childBlnoDialogRef.close();
                        }
                    });
            };
            const errorFun = () => {
                this.single_add_loading = false;
            };
            // 这里添加setting.fromChildDialog是因为免费余额不足弹窗会触发两次订阅
            if (
                !["TJ", "QD", "XM", "SZ"].includes(this.showTab) &&
                !setting.fromChildDialog
            ) {
                freeBalanceLackTip(
                    this.showTab,
                    boxesWatch,
                    "FEE_BLNO",
                    errorFun,
                    errorFun,
                );
            } else {
                boxesWatch();
            }
        },

        // 上海拼票订阅
        handleConfirmSHSplit(data) {
            if (!data) {
                this.$message.error("请输入拼票信息");
                this.$refs.shsplitRef.open();
                return;
            }
            if (!this.blno) {
                this.$message.warning("请输入提单号");
                return;
            } else {
                let arr = data
                    .split("\n")
                    .map((item) => item.trim())
                    .filter((item) => Boolean(item));
                arr.unshift(this.blno.trim());
                this.BoxesWatch(arr);

                // else{
                //     this.BoxesWatch([this.blno.trim()],{
                //         blnoMain:data.trim(),
                //     });
                // }
            }
        },

        // 生成删除订单数据
        generateRecallData(item) {
            const needDeleted = item.sonList?.length
                ? [item, ...item.sonList]
                : [item];
            return needDeleted.map(({ blno, vesselsName, voyage, id }) => ({
                vesselsName,
                blno,
                voyage,
                id,
            }));
        },
        //批量删除订单
        deleteBoxList() {
            if (this.multipleSelection.length == 0) {
                this.$message.error("请选择要删除的订单");
                return;
            }
            var list = [];
            this.multipleSelection.forEach((item) => {
                list.push(...this.generateRecallData(item));
            });
            this.showDeleteDialog(list);
        },
        //显示删除弹窗
        showDeleteDialog(list) {
            this.deleteDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.DeleteBoxDialog.init(list);
            });
        },
        //显示详情弹窗
        showDetailDialog(list) {
            this.datailDialogVisible = true;
            this.rows = list;
            this.$nextTick(() => {
                this.$refs.DetailDialog.Init(list);
            });
        },

        showSettingDialog() {
            this.settingDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.SettingDialog.init();
            });
        },
        // 时间处理切割
        formatTime(time) {
            if(time && time !== "-"){
                return moment(time).format("M-DD HH:mm");
            }
            return "-";
        },
        //表格序号根据分页连续显示
        tableIndex(index) {
            return this.startRow + index;
        },
        cellCss({ row, rowIndex }) {
            if (
                row.topType == 1 &&
                rowIndex != this.boxList.length - 1 &&
                rowIndex == this.divlineIndex
            ) {
                return "border-bottom-color:red";
            }
        },

        openWindow(url) {
            window.open(url, "_blank");
        },

        // 标记换船
        markChangeShip(row) {
            const { id, changeVessels } = row;
            this.$nextTick(() => {
                this.$refs.changeShipDialogRef.open({ id, changeVessels });
            });
        },

        // 异常反馈
        errorCallback(row) {
            const { id, blno } = row;
            this.$refs.errorCallbackDialogRef.open({ id, blno });
        },
        // 获取客户公司列表
        getClientComayList() {
            setCompanyCache();
        },
        // 换客户公司
        changeClientCompany(row) {
            this.$refs.changeClientComanyDialogRef.open(row);
        },
        // 国外节点
        handleOpenAbroadDialog(row) {
            this.$refs.abroadMoveDialogRef.open(row);
        },
        // 添加车号
        handleAddCar(command, row) {
            if (command === "addCar") {
                this.$refs.addCarDialogRef.open(row);
            } else if (command === "viewLocation") {
                if (row.departureTime) {
                    const dischargetime = new Date(row.departureTime).getTime();
                    const nowtime = new Date().getTime();
                    if (nowtime > dischargetime) {
                        this.$message.warning("已经离港不支持货车定位");
                        return;
                    }
                }
                this.$router.push({
                    name: "TruckSubscribe",
                    params: {
                        blno: row.blno,
                        boxPosition: row.boxPosition,
                    },
                });
            }
        },
        // 问题反馈
        handleFeedback(){
            this.$refs.errorCallbackDialogRef.open();
        },
        // 保存列宽
        handleSaveColumnWidth: throttle(function() {
            const dictKey = this.showTab !== 'NB' ? `web_fangcangwang_logistics_columns_${this.showTab}` : `web_fangcangwang_logistics_columns`;
            saveColumnConfig(dictKey, this.tableList) 
            .then(() => {
                this.$message.success("保存列宽成功");
            })
            .catch((err) => {
                this.$message.error(err.message || "保存列宽失败");
            })
        }, 5000, {
            leading: false,
            trailing: true   
        }),
        // 打开进港舱单详情
        openSimpleDetailDialog(row){
            this.$refs.simpleDetailDialogRef.open(row)
        }
    },
};
</script>

<style lang="stylus" scoped>
.expand-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  height: 15%;
  transform: translateY(-50%);
  z-index: 9;
  cursor: pointer;
  padding: 6px 2px;
  background-color: rgba(0,0,0,0.5);
  border-radius: 4px 0 0 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  &:hover{
    background-color: rgba(0,0,0,0.7);
  }
}
.el-table__fixed-right {
  transition: width 0.3s ease; /* 添加过渡效果 */
}
.changeLog-item-value{
    font-weight: bold;
}
.changeLog-item-desc{
    color: #F56C6C;
    font-size: 13px;
    margin-top: 4px;
    font-weight: bold;
}
.tab{
    width:100px;
    height:42px;
    cursor: pointer;
}
#tabber{
    width:100px;
    height:42px;
    margin-left: -7px;
    margin-right: 20px;
    cursor: pointer;
}

link-text-color-primary = #409EFF
link-text-color-primary = #409EFF
/deep/ .input_div>.el-input .el-input__inner {
  width:162px;
}

/deep/ .input_div>.sz-input>.el-input__inner {
  width: 450px;
}

/deep/ .input_div>.el-autocomplete .el-input__inner {
  width:320px;
}
/deep/.cell{
   padding:0;
}

/deep/input::-webkit-input-placeholder {
  color: #778899;
}
.model_opt_flag_wrap {
  border-left:1px solid #ccc;
  padding-left: 10px;
  .el-radio-group{
    width: 130px;
    .el-radio{
      margin-right:10px;
    }
  }
}

.repeat_pop_content {
    text-align: center;
    color: #F56C6C;
    font-size: 14px;
    font-weight: bold;
}

.watch-tooltip-message {
    font-size: 14px;
    color:#E6A23C;
    font-weight: bold;
}

.p_gold{
    color:#b87100;
    font-weight: bold;
}

.el-select {
  width: 12rem;
}

p {
  text-align: center;
  margin: 0;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.input_div_father {
  display: flex;
  flex-direction: row;
  width: 98%;
  justify-content: center;
}

.input_div {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: none;
  background: #fff;
  justify-content: center;
  align-items: center;
}

.title {
  color: #fff;
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  user-select: none;
}

.small_title {
  line-height: 1.5;
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  user-select: none;
}

.search_div_father {

  /deep/ .el-input__inner {
    border: none;
    width: auto;
  }
}

.el-select .el-input {
  width: 13rem;
}

.query-input_center:before, .query-input_right:before {
  content: '';
  height: 2rem;
  margin-top: 12px;
  border-right: 1px solid #ccc;
}

.query-input_btn {
  cursor: pointer;
  height: 4rem;
  font-size: 14px;
  color: #fff;
  text-align: center;
  border-radius: 0 4px 4px 0;
  background: #ff9807;
  border: none;
  padding:12px;
  font-weight: bold;
  &.multi{
    margin-left: 10px;
    border-radius: 4px;
    background: #409EFF;
  }
}

.data_div {
  min-height: 73vh;
  margin: 1rem 1rem 0 1rem;
  background: #fff;
  padding: 0 2rem 1rem 2rem;
}

.table_div {
    position: relative;
  /deep/.el-table__body .el-table__cell{
    &:first-child{
        .cell{
            padding-left:0
        }
    }
  }
}

.icon_wrapper{
    height:100%;
    line-height:12px;
    display: block;
}

// @media screen and (min-width:1800px) {
//     .data_search_div{
//         justify-content: space-between;
//     }
// }

/deep/ .el-table--border th.el-table__cell {
  color: #000 !important;
}

/deep/ .el-descriptions .is-bordered .el-descriptions-item__cell {
  font-weight: 700;
}

/deep/ .el-table .el-table__cell {
  padding: 2px 0;
  &.changeTag{
        background-image: url(../../assets/img/huanicon.svg);
        background-repeat: no-repeat;
        background-size: 200px 47px;
        background-position: right 0 bottom 0;
  }
}

/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #f1f3f6;
}

.el-tree {
  padding: 0 2rem 0 0;
}

.pagination_div {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.table_control_div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.line {
  width: 2px;
  height: 80%;
  background: var(--GRAY-dcdcdc);
}

.top_div {
  .header_footer{
    display: flex;
    align-items: center;
    padding-top:10px;
    height: 20px;
    font-size:12px;
    color:#ED9B50;
    img {
        margin-right: 4px;
        width: 14px;
        height: 14px;
    }
    .go_boxquery{
        cursor: pointer;
        color: #8BC4F9;
    }
  }
}

.TJ_banner{
    .header_footer{
        color:white !important;
    }
}

.item_his_class {
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  align-items: center;
}

/deep/.item_his_class_input .el-input input {
  border-radius: 0px;
  font-size: 15px;
}

/deep/ .is-dot .el-badge__content.is-fixed{
    top:3px;
    right:5px
}
/deep/ .is-dot .el-badge__content{
    font-size:10px;
    line-height: 1;
    padding:2px 5px;
    height:10px;
}

/deep/ .no-overflow-hidden  .cell{
    overflow:visible !important;
}

.multi_btn {
  font-size: 14px;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  border: 5px;
}

.btn_dialog_daoru {
  width: 52px;
  height: 30px;
  font-size: 12px;
}

.el-button--mini, .el-button--mini.is-round {
  padding: 0px 6px;
}

@keyframes btnhover {
  from {
    transform: scale(1);

  }
  to {
    transform: scale(1.1)
  }
}
</style>
