<template>
    <ShipDialog
        width="500px"
        :visible.sync="dialogTableVisible"
        :showTab="showTab"
        :title="`确定要${showTypeMsg}吗？`"
        size="small"
    >
        <div class="ticket_div">
            <p>
                将{{ showTypeMsg }}
                <em>{{ list.length }}</em>
                单
            </p>
            <el-table
                border
                stripe
                :data="list"
                style="width: 100%"
                :max-height="250"
                :default-sort="{ prop: 'date', order: 'descending' }"
                :header-cell-style="{
                    background: '#eef1f6',
                    color: '#606266',
                }"
            >
                <el-table-column
                    prop="blno"
                    label="提单号"
                    v-if="type !== 'ship'"
                ></el-table-column>
                <el-table-column label="船名/航次">
                    <template slot-scope="scope">
                        <p class="cell_p">
                            {{ scope.row.vesselsName || scope.row.vesselename }}
                        </p>
                        <p class="cell_p">{{ scope.row.voyage }}</p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="堆场"
                    prop="yard"
                    v-if="['box-delete', 'box-cancel'].includes(type)"
                ></el-table-column>
            </el-table>
        </div>
        <DialogFooter
            :showTab="showTab"
            @confirm="deleteBox"
            @cancel="closeDialog"
            :loading="loading"
        />
    </ShipDialog>
</template>
<script>
import { shipRequest } from "@/views/ShipSubscrib/ship";
import ShipDialog from "../layout/ShipDialog.vue";
import DialogFooter from "../layout/DialogFooter.vue";

export default {
    components: {
        ShipDialog,
        DialogFooter,
    },
    props: {
        showTab: {
            type: String,
            default: "NB",
        },
        // blno 提单， ship 船期  box-delete 指定箱删除 box-cancel 指定箱取消
        type: {
            type: String,
            default: "blno",
        },
        reqFunc: {
            type: Function,
            default: null,
        },
        notParamArray: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dialogTableVisible: false,
            list: [],
            loading: false,
        };
    },

    computed: {
        showTypeMsg() {
            return this.type === "box-cancel" ? "取消" : "删除";
        },
    },

    methods: {
        init(list) {
            this.dialogTableVisible = true;
            this.list = [...list];
        },
        deleteBox() {
            var that = this;
            const ids = this.list.map((item) => item.id);
            const reqFunc =
                this.reqFunc ||
                shipRequest({
                    tab: this.showTab,
                    type: 5,
                });
            let params = ids;
            if (this.notParamArray) {
                params = { id: ids[0] };
            }
            if(this.showTab==='SZ'){
                params = { ids:ids.toString() };
            }
            console.log(params,'params');
            this.loading = true;
            reqFunc(params)
                .then(() => {
                    that.$message.success(`${this.showTypeMsg}成功`);
                    that.$emit("refreshDataList");
                    that.closeDialog();
                })
                .catch((err) => {
                    that.closeDialog();
                })
                .finally(() => {
                    that.loading = false;
                });
        },
        closeDialog() {
            this.dialogTableVisible = false;
        },
    },
};
</script>
<style lang="stylus" scoped>
.ticket_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-image {
  width: 30rem;
  height: 30rem;
}

em {
  color: var(--RED-F21137);
  font-size: 2rem;
}

.cell_p {
  text-align: center;
  margin: 0;
}
</style>
