<template>
    <div class="ship-info-cell">
        <el-link
            v-if="
                !rowData.vesselsName &&
                !rowData.voyage &&
                !rowData.vvFlag &&
                rowData.sonCount
            "
            type="primary"
            @click="handleAdd(rowData)"
            class="addVoyage_link table-cell-link"
        >
            添加船名/航次
        </el-link>
        <template
            v-if="
                rowData.vesselsName ||
                rowData.voyage
            "
        >

        <el-dropdown>
            <div class="shipInfo-col-wrap">
                <div class="ship-info-text-wrap">
                    <div class="ship-info-text">
                        <span class="ship-info-text-name">{{ rowData.vesselsName }}</span>
                        <span style="margin: 0 2px">/</span>
                        <span class="ship-info-text-voyage">{{ rowData.voyage }}</span>
                    </div>
                </div>
            </div>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    v-clipboard:copy="
                        rowData.vesselsName
                    "
                    v-clipboard:success="
                        copySucc
                    "
                >
                    复制船名
                </el-dropdown-item>
                <el-dropdown-item
                    v-clipboard:copy="
                        rowData.voyage
                    "
                    v-clipboard:success="
                        copySucc
                    "
                >
                    复制航次
                </el-dropdown-item>
                <el-dropdown-item
                    v-clipboard:copy="
                        `${rowData.vesselsName}/${rowData.voyage}`
                    "
                    v-clipboard:success="
                        copySucc
                    "
                >
                    复制全部
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
            <div class="ship-info-right">
                <i
                    @click="handleAdd(rowData)"
                    class="el-icon-edit"
                    v-if="!['SZ'].includes(showTab)"
                ></i>
                <el-dropdown>
                    <img src="@/assets/icon/copy.svg" />
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-clipboard:copy="
                                rowData.vesselsName
                            "
                            v-clipboard:success="
                                copySucc
                            "
                        >
                            复制船名
                        </el-dropdown-item>
                        <el-dropdown-item
                            v-clipboard:copy="
                                rowData.voyage
                            "
                            v-clipboard:success="
                                copySucc
                            "
                        >
                            复制航次
                        </el-dropdown-item>
                        <el-dropdown-item
                            v-clipboard:copy="
                                `${rowData.vesselsName}/${rowData.voyage}`
                            "
                            v-clipboard:success="
                                copySucc
                            "
                        >
                            复制全部
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ShipInfoCell',
    props: {
        rowData: {
            type: Object,
            default: () => {}
        },
        showTab: {
            type: String,
            default: 'NB'
        }
    },
    methods: {
        handleAdd(rowData) {
            this.$emit('add-voyage',rowData)
        },
        copySucc() {
            this.$message.success("复制成功");
        }
    }
}
</script>

<style lang="stylus" scoped>
.ship-info-cell{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;
    box-sizing: border-box;
    /deep/ .el-dropdown{
        font-size: 13px;
        color: #333333;
        width: 80%;
    }
    /deep/ .el-tooltip{
        padding: 0 !important;
    }
    .addVoyage_link{
        padding-left: 2px;
    }
    .shipInfo-col-wrap{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img{
            margin-left:4px;
            margin-top: 5px;
            cursor: pointer;
            &:hover{
                opacity: 0.6;
            }
        }
    }
    .ship-info-text-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .ship-info-text{
            padding-left: 6px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            .ship-info-text-name,
            .ship-info-text-voyage{
                white-space: nowrap;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                width:fit-content;
                max-width: 90%;
            }
        }
    }
    .ship-info-right{
        display: flex;
        align-items: center;
        justify-content: center;
        i{
            font-size: 16px;
            cursor: pointer;
            margin-right: 4px;
            color: #409EFF;
        }
        /deep/ .el-dropdown{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }   
}
</style>
