import { wharfCodeToStr } from "@/utils";
import {getMatou,checkCusmovIsCheck} from "@/utils/validate";


export default {
    getMatou,
    wharfCodeToStr,
    getString2JsonArr(data) {
        if (!data) {
            return []
        }
        var json = eval(data);
        return json;
    },
    checkCusmovIsCheck
}
