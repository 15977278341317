<template>
    <div class="speed-type-cell" v-if="!rowData.nppStatus">
        <el-tooltip v-if="rowData.speedType === 1" content="已开启极速跟踪" placement="right-end">
            <el-image :src="superSpeedIcon" class="super-speed-icon" />
        </el-tooltip>
        <el-button
            v-else
            type="text"
            @click="
                markSpeedType(
                    rowData,
                    rowIndex,
                )
            "
            size="small"
            class="table-cell-link"
            :loading="
                speedTypeLoading &&
                curEditRow.id === rowData.id
            "
        >
            开启
        </el-button>
    </div>
</template>

<script>
import { markSpeedTypeFunc } from "@/api/tracking";

export default {
    name: 'SpeedTypeCell',
    props: {
        rowData: {
            type: Object,
            default: () => {}
        },
        rowIndex:{
            type: Number,
        }
    },
    data(){
        return {
            speedTypeLoading: false, // 极速模式loading
            superSpeedIcon: require("@/assets/icon/super_speed.svg"),
        }
    },
    methods: {
        // 开启极速模式
        markSpeedType(row, rowIndex) {
            this.speedTypeLoading = true;
            markSpeedTypeFunc({
                id: row.id,
            })
                .then(() => {
                    this.$message.success("开启极速模式成功");
                    this.$emit('change',{
                        rowIndex,
                        rowData: row,
                        data: {
                            speedType: 1
                        }
                    })
                })
                .finally(() => {
                    this.speedTypeLoading = false;
                });
        },
    }
}
</script>

<style lang="stylus" scoped>
.super-speed-icon{
    width: 20px;
    height: 20px;
}
</style>