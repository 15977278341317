<template>
    <div class="abroad-cell">
        <el-tooltip  v-if="rowData['lastNode'] && rowData.isAbroad === 1" effect="dark" content="查看详情" placement="left-end">
            <span
                type="primary"
                :underline="false"
                style="
                    cursor: pointer;
                    display: inline-block;
                    max-width: 65%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                "
                @click="
                    handleOpenAbroadDialog(rowData)
                "
            >
                {{ rowData["lastNode"] }}
            </span>
        </el-tooltip>
        <span v-else-if="rowData.isAbroad == 1">-</span>
        <div class="abroad-cell-right" v-if="rowData.psType == '1'">
            <el-tooltip
                effect="dark"
                content="查看详情"
                placement="left-end"
            >
                <i
                    v-if="rowData.lastNode && rowData.isAbroad === 1"
                    class="custom-icon el-icon-link"
                    style="
                        color: #409eff;
                        margin: 0 4px;
                    "
                    @click="
                        handleOpenAbroadDialog(
                            rowData,
                        )
                    "
                ></i>
            </el-tooltip>
            <el-tooltip
                effect="dark"
                :content="
                    rowData.isAbroad == 1
                        ? '刷新国外运踪'
                        : '订阅国外运踪'
                "
                placement="right-end"
            >
                <el-button v-if="rowData.isAbroad !== 1" :loading="refreshAbroadLoading" type="text" class="table-cell-link" size="small" @click="handleReSubAbroad(rowData, rowIndex)">添加订阅</el-button>
                <el-button
                    v-else
                    class="icon-btn"
                    :loading="
                        refreshAbroadLoading &&
                        rowData.id ===
                            rowData.id
                    "
                    type="text"
                    :icon="
                        rowData.isAbroad == 1 ||
                        rowData.lastNode
                            ? 'el-icon-refresh'
                            : 'el-icon-circle-plus-outline'
                    "
                    @click="
                        handleReSubAbroad(
                            rowData,
                            rowIndex,
                        )
                    "
                ></el-button>
            </el-tooltip>
        </div>
    </div>
</template>

<script>
import { subAbroadById, refreshAbroad } from "@/api/abroad";
import { apiCallback } from '@/utils';
import { getCarrierByBillNo } from '../logistics';

export default {
    name: 'AbroadCell',
    props: {
        rowData: {
            type: Object,
            default: () => {}
        },
        rowIndex: {
            type: Number,
            default: 0
        },
        showTab: {
            type: String,
            default: 'NB'
        }
    },
    data() {
        return {
            refreshAbroadLoading: false,
        }
    },
    methods: {
        handleOpenAbroadDialog(rowData) {
            this.$emit('open-abroad-dialog', rowData);
        },
        // 重新订阅外国节点
        async handleReSubAbroad(row, rowIndex) {
            const { id, blno, isAbroad } = row;
            if (isAbroad != 1) {
                let shipCode;
                try {
                    this.refreshAbroadLoading = true;
                    let shipCode = row.ctnOwner;
                    if (!shipCode) {
                        const res = await getCarrierByBillNo({
                            billNo: blno,
                            portCode: this.showTab,
                        });
                        shipCode = res;
                    }
                    if (shipCode) {
                        subAbroadById({
                            id,
                            shipCode,
                            port: this.showTab,
                        })
                            .then((res) => {
                                const data = apiCallback(res) || {};
                                this.$message.success(`国外运踪订阅成功`);
                                this.$emit('update-row-data',{
                                    rowIndex,
                                    data:{
                                        isAbroad: 1,
                                        lastNode: data.nodeAction,
                                    }
                                })
                            }).catch(err => {
                                if(err.message === '暂无数据') {
                                    this.$emit('update-row-data', {
                                        rowIndex,
                                        data:{
                                            isAbroad: 1,
                                        }
                                    })
                                }
                            })
                            .finally(() => {
                                this.refreshAbroadLoading = false;
                            });
                    } else {
                        this.refreshAbroadLoading = false;
                        this.$message.warning(
                            "暂不支持该船公司提单号订阅国外段",
                        );
                        return;
                    }
                } catch (error) {
                    this.$message.error(error.message);
                }
            } else {
                this.refreshAbroadLoading = true;
                refreshAbroad({
                    billNo: blno,
                    portCode: this.showTab,
                })
                    .then((res) => {
                        const data = apiCallback(res);
                        this.$message.success(`国外运踪刷新成功`);
                        if (data && data.nodeAction) {
                            // 更新列表数据
                            this.$emit('update-row-data', {
                                rowIndex,
                                data:{
                                    lastNode: data.nodeAction,
                                }
                            })
                        }
                    })
                    .finally(() => {
                        this.refreshAbroadLoading = false;
                    });
            }
        },
    }
}
</script>

<style lang="stylus" scoped>
.abroad-cell {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;
    .abroad-cell-left {
        flex-grow: 1;
    }
    .abroad-cell-right {
        display: flex;
        align-items: center;
    }
}
</style>



