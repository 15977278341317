<!-- 船司列 -->
<template>
    <div>
        <div
            style="word-break: keep-all"
            @click="handleOpenCtnOwnerSelectDialog"
        >
            <i class="el-icon-edit" v-if="!row.ctnOwner"></i>
            {{ row.ctnOwner || row.shipCode || "-" }}
        </div>
        <ShipDialog
            :showTab="showTab"
            width="500px"
            size="small"
            v-if="visibleSync"
            :visible.sync="visibleSync"
            title="船公司修改"
        >
            <span style="margin-right: 10px">船公司修改选择:</span>
            <el-select
                placeholder="请选择船公司"
                v-model="content"
                filterable
                style="width: 320px"
            >
                <el-option
                    v-for="item in companyList"
                    :key="item.companyCode"
                    :label="item.companyCode + '(' + item.companyNameCHN + ')'"
                    :value="item.companyCode"
                ></el-option>
            </el-select>
            <DialogFooter
                @confirm="handleTuConfirm"
                @cancel="handleCancel"
                :loading="loading"
                :showTab="showTab"
                top="30"
            ></DialogFooter>
        </ShipDialog>
    </div>
</template>
<script>
import ShipDialog from "@/components/layout/ShipDialog.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import { updateShipCompany } from "@/api/abroad";
export default {
    name: "OwnerCtnColumn",
    props: {
        row: {
            type: Object,
            default: () => {},
        },
        showTab: {
            type: String,
            default: "",
        },
    },
    components: {
        ShipDialog,
        DialogFooter,
    },
    data() {
        return {
            visibleSync: false,
            loading: false,
            companyList: [],
            content: "",
        };
    },
    methods: {
        async getCompanyList() {
            const supportShipCompany =
                this.$store.state.txtdata.supportShipCompany;
            if (supportShipCompany.length) {
                this.companyList = supportShipCompany;
                return;
            }
            await this.$store.dispatch("txtdata/updateSupportShipCompany");
            this.companyList = this.$store.state.txtdata.supportShipCompany;
        },
        handleOpenCtnOwnerSelectDialog() {
            if (this.row.ctnOwner) {
                return;
            }
            this.getCompanyList();
            this.content = this.row.shipCode || "";
            this.visibleSync = true;
        },
        handleCancel() {
            this.visibleSync = false;
        },
        handleTuConfirm() {
            this.loading = true;
            const param = {
                id: this.row.id,
                shipCode: this.content,
                portCode: this.showTab,
            };
            updateShipCompany(param).then(() => {
                this.row.shipCode = this.content;
                this.loading = false;
                this.visibleSync = false;
                this.$message({
                    message: "修改船公司成功",
                    type: "success",
                });
            });
        },
    },
};
</script>
