import store from "@/store";
import table from "@/data/table";
import {
    orderTimeTypeOptions,
    orderTimeSH,
    orderTimeQD,
    orderTimeTJ,
    orderTimeXM,
    orderTimeSZ,
} from "@/utils/dataList";
/**
 * @description 物流记录的table，根据不同tab来区分
 * @param {String} tab NB SH QD
 * @param {Number} type 1.当前的store中的历史记录列表的值 2. 修改当前的store中的历史记录列表的值 3. 原始列表 4.时间筛选列
 * @param {Object|Array} data 修改store中的历史记录列表的值
 */
export const logisticsTableList = ({ tab, type, data }) => {
    switch (tab) {
        case "NB":
            switch (type) {
                case 1:
                    return [...store.state.table.tableList];
                case 2:
                    return store.commit("table/resetTableListNB", data);
                case 3:
                    return [...table.resetTableNB];
                case 4:
                    return orderTimeTypeOptions;
            }
        case "SH":
            switch (type) {
                case 1:
                    return [...store.state.table.tableListSH];
                case 2:
                    return store.commit("table/resetTableListSH", data);
                case 3:
                    return [...table.sResetTable];
                case 4:
                    return orderTimeSH;
            }
        case "QD":
            switch (type) {
                case 1:
                    return [...store.state.table.tableListQD];
                case 2:
                    return store.commit("table/resetTableListQD", data);
                case 3:
                    return [...table.qdResetTable];
                case 4:
                    return orderTimeQD;
            }
        case "TJ":
            switch (type) {
                case 1:
                    return [...store.state.table.tableListTJ];
                case 2:
                    return store.commit("table/resetTableListTJ", data);
                case 3:
                    return [...table.tjResetTable];
                case 4:
                    return orderTimeTJ;
            }
        case "XM":
            switch (type) {
                case 1:
                    return [...store.state.table.tableListXM];
                case 2:
                    return store.commit("table/resetTableListXM", data);
                case 3:
                    return [...table.xmResetTable];
                case 4:
                    return orderTimeXM;
            }
        case "SZ":
            switch (type) {
                case 1:
                    return [...store.state.table.tableListSZ];
                case 2:
                    return store.commit("table/resetTableListSZ", data);
                case 3:
                    return [...table.szResetTable];
                case 4:
                    return orderTimeSZ;
            }
    }
};
